import { ButtonV2 } from 'commons/components'
import { Modal } from 'antd'
import React from 'react'
import { ButtonCancel, ButtonContent, ModalContent } from './styles'

interface SimpleModalProps {
    isVisible: boolean
    okText?: string
    okAction?: React.MouseEventHandler<HTMLElement>
    cancelText?: string
    cancelAction?: React.MouseEventHandler<HTMLElement>
    showCloseButton?: boolean
}

const SimpleModal: React.FC<SimpleModalProps> = ({
    isVisible,
    children,
    okText,
    okAction,
    cancelText,
    cancelAction,
    showCloseButton = false
}) => {
    return (
        <>
            <Modal
                visible={isVisible}
                footer={null}
                closable={showCloseButton}
                onCancel={cancelAction}
            >
                <ModalContent>
                    {children}
                    <ButtonContent>
                        <ButtonV2
                            onClick={okAction}
                            type="primary"
                            style={{ borderRadius: '58px' }}
                        >
                            {okText}
                        </ButtonV2>
                        <ButtonCancel
                            type="ghost"
                            onClick={cancelAction}
                            style={{ borderRadius: '58px' }}
                        >
                            {cancelText}
                        </ButtonCancel>
                    </ButtonContent>
                </ModalContent>
            </Modal>
        </>
    )
}

export default SimpleModal
