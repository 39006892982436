import styled from 'styled-components'

type DropdownButtonProps = {
    padding?: string
}

export const Wrapper = styled.div`
    display: contents;
`

export const StyledButton = styled.div<DropdownButtonProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 100px;
    transition: 0.2s;

    font-size: 15px;

    height: 40px;

    & > span:first-child {
        margin-right: 12px;

        font-size: 14px;
    }
`
