import React, { FC } from 'react'
import AllTalks from './All'
import NextTalks from './Next'
import PreviousTalks from './Previous'
import SponsorTalks from './Sponsor'
import OnDemandTalks from './OnDemand'
import TrailsTalks from './Trails'
import SeriesTalks from './Series'
import RecommendedTalks from './Recommended'
import Stage from './Stage'
import Edition from './Edition'

import { TalksProps } from './types'

const Talks: FC<TalksProps> = props => {
    const { type } = props

    switch (type) {
        case 'next':
            return <NextTalks />
        case 'previous':
            return <PreviousTalks />
        case 'sponsor':
            return <SponsorTalks />
        case 'ondemand':
            return <OnDemandTalks />
        case 'trails':
            return <TrailsTalks />
        case 'series':
            return <SeriesTalks />
        case 'recommended':
            return <RecommendedTalks />
        case 'stage':
            return <Stage />
        case 'edition':
            return <Edition {...props} />
        default:
            return <AllTalks />
    }
}

export default Talks
