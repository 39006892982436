import React from 'react'
import { usePlayerPiP } from 'screens/Streaming/Players/VideoPlayerV2/context'
import VideoPlayerV2 from 'screens/Streaming/Players/VideoPlayerV2'
import ResizablePiP from './Resizable'

const VideoPlayerPiP = () => {
    const { miniPlayer, videoInfo } = usePlayerPiP()

    return miniPlayer ? (
        <ResizablePiP
            widthInPx={320}
            heightInPx={180}
            minConstraints={[100, 100]}
            maxConstraints={[800, 800]}
        >
            <VideoPlayerV2
                url={videoInfo.url}
                fallbackUrl={videoInfo.fallbackUrl}
                poster={videoInfo.poster}
                currentTime={videoInfo.currentTime}
                status={videoInfo.status ?? 'WAITING'}
                isPiP
                isLive={videoInfo.isLive}
            />
        </ResizablePiP>
    ) : (
        <div />
    )
}

export default VideoPlayerPiP
