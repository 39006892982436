import { css } from 'styled-components'
import { CustomButtonsStyledProps } from '../types'

export const OldButtonBaseStyle = css`
    padding: 10px 12px;
    border-radius: 50px;
    background: ${({ theme }) => theme.main.whiteOpacity};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border: 0;
`

export const baseStyle = (props: CustomButtonsStyledProps) => {
    return css<CustomButtonsStyledProps>`
        ${() => props.$marginRight && `margin-right: ${props.$marginRight}px;`}
        ${() => props.$marginBottom && `margin-bottom: ${props.$marginBottom}px;`}
        ${() => props.$borderRadius && `border-radius: ${props.$borderRadius}px;`}

        &,
        &.ant-btn {
            display: flex;
            flex-direction: ${({ icon }) => (icon ? 'row-reverse' : 'row')};
            justify-content: center;
            align-items: center;
            padding: 0px 24px;
            background: ${({ theme: { v2 } }) => v2.getBgColor('button.bgColor')};
            color: ${({ theme: { v2 } }) => v2.button.textColor};
            border-radius: ${({ theme: { v2 } }) => v2.button.borderRadius}px;
            border: none;
            height: 38px;
            transform: scale(1);
            transition: all ${({ theme: { v2 } }) => v2.button.hover.transition}s ease-in-out;

            span,
            div {
                color: ${({ theme: { v2 } }) => v2.button.textColor};
                line-height: 17px;
            }
            svg * {
                fill: ${({ theme: { v2 } }) => v2.button.textColor};
                margin-left: 7px;
            }
        }

        &.ant-btn-icon-only {
            padding: 0;
        }

        &:hover {
            background: ${({ theme: { v2 } }) => v2.getBgColor('button.hover.bgColor')};
            color: ${({ theme: { v2 } }) => v2.button.hover.textColor};
            // @todo: Adicionar cores do tema do botão
            //box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
            transform: scale(1.05);
            border: none;
            span,
            div {
                color: ${({ theme: { v2 } }) => v2.button.hover.textColor};
                line-height: 17px;
            }
            svg * {
                fill: ${({ theme: { v2 } }) => v2.button.hover.textColor};
            }
        }

        ${props.$outline &&
        css`
            background: transparent;
            color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            border-color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            border-width: 1px;
            border-style: solid;
            > span {
                color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            }

            svg * {
                fill: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            }

            &:hover {
                color: ${({ theme }) => theme.v2.button.textColor};
                border-color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
                background: ${({ theme }) => theme.v2.getBgColor('button.bgColor')} !important;

                > span {
                    color: ${({ theme }) => theme.v2.button.textColor};
                }

                svg * {
                    fill: ${({ theme }) => theme.v2.button.textColor};
                }
            }
        `}
        &.ant-btn-link {
            line-height: 38px;
            height: 40px;
        }

        &.btn-sponsorcard {
            height: 50px;
        }

        .anticon {
            line-height: 1 !important;
            + span {
                float: left;
                margin-right: 8px;
            }
        }

        &.ant-btn-ghost,
        &.ant-btn-background-ghost {
            background: transparent;
            color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            border-color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            border-width: 1px;
            border-style: solid;
            > span {
                color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            }

            svg {
                margin-left: 7px;
            }
            svg * {
                fill: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
            }

            &:hover {
                color: ${({ theme }) => theme.v2.button.textColor};
                border-color: ${({ theme }) => theme.v2.getTextColor('button.bgColor')};
                background: ${({ theme }) => theme.v2.getBgColor('button.bgColor')} !important;

                > span {
                    color: ${({ theme }) => theme.v2.button.textColor};
                }

                svg * {
                    fill: ${({ theme }) => theme.v2.button.textColor};
                }
            }
        }
    `
}
