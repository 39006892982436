import styled from 'styled-components'
import Button from 'commons/components/Button'

export const WrapperIcon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .chevron {
        min-width: 8px;
        margin-left: 8px;
    }
`

export const StyledContainer = styled(Button)`
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.4s;
    position: relative;
    padding: 0;

    .ant-menu {
        background: transparent;
        border: 0;

        .ant-menu-submenu-title {
            padding: 10px;
            width: 80px;
            height: 62px;
            text-overflow: unset;
            position: relative;
            display: flex;

            > div {
                > .chevron {
                    right: 4px;
                    height: 12px;
                    width: 8px;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }

            .ant-menu-submenu-arrow {
                display: none;
            }
        }
    }
`
