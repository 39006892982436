import styled, { css, DefaultTheme, keyframes } from 'styled-components'
import Button from 'commons/components/Button'
import { ReactComponent as Gift } from 'commons/assets/icons/outline/gift-bold.svg'
import { rgba } from 'polished'

const PulseFrame = (color: string) => {
    const pulse = keyframes`
        0% {
            box-shadow: 0 0 0 0 ${rgba(color, 0.5)};
        }
        70% {
            box-shadow: 0 0 0 25px ${rgba(color, 0)};
        }
        100% {
            box-shadow: 0 0 0 0 ${rgba(color, 0)};
        }
  `
    return pulse
}

export const Wrapper = styled.div`
    position: fixed;
    bottom: 50px;
    z-index: 20;
    right: 50px;

    @media (max-width: 767px) {
        width: 100%;
        right: initial;
        top: initial;
        bottom: 50px;

        a {
            width: 235px;
        }
    }
`

export const StyledLink = css`
    position: relative;
    text-decoration: none;
    width: 300px;
    min-width: 185px;
    min-height: 50px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: ${({ theme: { v2 } }) => PulseFrame(v2.getTextColor('button.bgColor'))} 1s infinite;
    margin: 0 auto;

    &:hover {
        animation: unset;
    }

    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.md}) {
            width: 185px;
        }
    `}
`

export const StyledGift = styled(Gift)<{ color?: keyof DefaultTheme['main'] }>`
    .gift {
    }
    height: 18px;
    width: 18px;
    fill: ${({ theme: { main } }) => main.white};
    margin-right: 8px;
`

export const StyledText = styled.div<{ bold?: boolean }>`
    font-size: 18px;
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    margin-right: 8px;
    text-align: center;

    @media (max-width: 1333px) {
        font-size: 14px;
    }
`

export const StyledRoundedButton = styled(Button)`
    width: 60px;
    height: 60px;
    background: transparent;
`

export const StyledContainerFit = styled.div`
    flex: 1;
`
