import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ChevronSvg } from 'commons/assets/icons/chevron-right.svg'
import { Avatar, Text } from 'commons/components/'

import { GET_CURRENT_USER } from 'commons/gql/queries/user'
import { Media } from 'commons/gql/models/media'
import { useQuery } from '@apollo/client'
import { Row, Col } from 'antd'
import { MenuAction } from '../styles'

interface UserAvatarData {
    me: {
        name: string
        avatar: Media
    }
}

interface AvatarUserProps {
    handleClose: () => void
}

const AvatarUser: React.FC<AvatarUserProps> = ({ handleClose }) => {
    const { push } = useHistory()

    const { data } = useQuery<UserAvatarData>(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache'
    })

    if (!data) return null

    const defaultProfile = 'https://tdstorage.s3-sa-east-1.amazonaws.com/avatar/default.jpg'
    const { name, avatar } = data.me

    const handleClick = () => {
        push('/home/profile')
        handleClose()
    }

    return (
        <MenuAction onClick={handleClick}>
            <Row align="middle" gutter={16}>
                <Col span={4}>
                    <Avatar size={35} src={avatar?.url || defaultProfile} />
                </Col>
                <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={16} bold marginBottom={5}>
                        {name}
                    </Text>
                    <Text className="opacity-5">Ver perfil</Text>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                    <ChevronSvg height="14" />
                </Col>
            </Row>
        </MenuAction>
    )
}

export default AvatarUser
