import React from 'react'
import { Col, Row } from 'antd'

const TalksWrapper: React.FC<{ className?: string }> = ({ children, className = '' }) => {
    return (
        <Row className={`mb-50 mr-40 mb-mr-0 mb-pdr-20 mb-pdl-20 ${className}`}>
            <Col span={24} className="title-pdl-20">
                {children}
            </Col>
        </Row>
    )
}

export default TalksWrapper
