import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from 'commons/assets/icons/logo.svg'
import { Text } from '../Typography'

import { StyledFooter, Spacer } from './styles'

const Footer: React.FC = () => {
    const { t } = useTranslation('commons')

    return (
        <>
            {/* <StyledFooter>
                <Text marginRight={30} fontSize={14}>
                    {t('footer.slogan')}
                </Text>
                <Logo />
            </StyledFooter> */}
            <Spacer />
        </>
    )
}

export default Footer
