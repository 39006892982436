import styled from 'styled-components'
import { GapCss } from 'commons/css/GapCss'
import { lighten } from 'polished'

export const StyledContainer = styled.div<{ hiddenScroll?: boolean; gap?: number }>`
    width: 100%;
    display: flex;
    margin: 16px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    ${({ gap }) => gap && GapCss(gap)}
    ${({ theme: { v2 }, hiddenScroll }) => {
        if (hiddenScroll) {
            return `
        overflow-x: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      `
        }
        return `
      overflow-x: auto;
      padding-bottom: 12px;
      ::-webkit-scrollbar {
        height: 10px;
      }

      ::-webkit-scrollbar-track {
        background: ${lighten(0.5, v2.highlight)};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${v2.highlight};
        border-radius: 10px;
    }
    `
    }}
`
