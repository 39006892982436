import styled from 'styled-components'
import { Typography } from 'antd'

import { StyledToolTip } from 'commons/components/Tooltip/styles'

import { StyledTitleTypes } from '../types'
import { stylesBase, styleFontDynamic } from '../styles'

const { Title } = Typography

export const StyledTitle = styled(Title)<StyledTitleTypes>`
    &.ant-typography {
        ${stylesBase}
        ${styleFontDynamic}

        &:hover > ${StyledToolTip} {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s;
        }
    }
`
