import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        me {
            id
            name
            email
            avatar {
                url
                _id
            }
        }
    }
`

export const GET_CURRENT_USER_COMPLETE = gql`
    query GetCurrentUser {
        me {
            id
            name
            email
            bio
            phone
            company
            jobTitle
            department
            avatar {
                _id
                url
            }
            cover {
                _id
                url
            }
            social {
                facebook
                instagram
                linkedin
                twitter
            }
        }
    }
`
