import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
    query GetBrandPlans($event: ObjectID) {
        brandPlans(filter: { event: $event }) {
            edges {
                node {
                    name
                    type
                    id
                    sponsorPage
                    sponsorLink
                }
            }
        }
    }
`

export const GET_SPONSORS_BY_CATEGORIES = gql`
    query GetSponsorsByCategories($event: ObjectID, $plan: ObjectID) {
        brands(filter: { event: $event, plan: $plan }, orderBy: { priority: DESC, name: ASC }) {
            edges {
                node {
                    name
                    id
                    contact
                    social {
                        url
                        type
                    }
                    image {
                        url
                    }
                    resources {
                        link
                        title
                    }
                    logo {
                        url
                    }
                    actions {
                        name
                        url
                        size
                    }
                    social {
                        type
                        url
                    }
                    email
                    site
                    aboutUs
                    description
                }
            }
        }
    }
`

export const GET_BRAND_DETAIL = gql`
    query GetBrandDetail($id: ObjectID) {
        brand(id: $id) {
            name
            logo {
                title
                url
            }
            description
            contact
            aboutUs
            email
            site
            image {
                url
            }
            actions {
                name
                url
                size
            }
            social {
                type
                url
            }
            resources {
                title
                link
            }
            talkStand {
                url
                type
            }
            sponsor {
                id
                name
            }
            institutionalVideos {
                url
            }
        }
    }
`

export const GET_BRAND_CHIEF = gql`
    query GetBrandChiefs($id: ObjectID) {
        brand(id: $id) {
            chiefs {
                id
                name
                avatar {
                    url
                }
                jobTitle
                company
            }
        }
    }
`

export const GET_BRAND_TITLE = gql`
    query GetBrandTitle($id: ObjectID) {
        brand(id: $id) {
            name
        }
    }
`
