import { useQuery } from '@apollo/client'
import { GET_TALK_CATEGORIES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { Fragment } from 'react'

import { LoadingState } from '../Skeleton'
import { TalkTitle } from '../styles'
import TalksWrapper from '../TalksWrapper'
import { useTalksText } from '../texts'
import { ICategories } from '../types'
import Serie from './Serie'

interface Series {
    id: string
    name: string
    description: string
    type: string
    talksTotalCount: number
}

const Series = () => {
    const { idEvent } = useAuth()
    const { seriesTalks } = useTalksText()

    const { data } = useQuery<any>(GET_TALK_CATEGORIES, {
        variables: {
            event: idEvent as string,
            type: 'SERIE'
        },
        fetchPolicy: 'no-cache'
    })

    const filterCategories = data?.categories?.edges
    const categories: Series[] = filterCategories?.reduce(
        (arr: any, currentValue: any) => arr.concat(currentValue?.node),
        []
    )

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {seriesTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    return (
        <TalksWrapper className="series-talks">
            {categories &&
                categories.map((category: ICategories, index) => (
                    <Fragment key={index}>
                        <Serie
                            key={category.id}
                            idTalk={category.id}
                            name={category.name}
                            description={category.description}
                        />
                        {categories.length - 1 !== index && <br />}
                    </Fragment>
                ))}
        </TalksWrapper>
    )
}

export default Series
