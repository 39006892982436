import React, { useState } from 'react'
import Modal from 'react-modal'

import GlobalStyles from 'commons/styles/global'
import StyledToast from 'commons/components/StyledToast'
import { ModalContext } from 'commons/components/modal/modalContext'
import { IModal } from 'commons/components/modal/types'
import AuthProvider from './AuthProvider'
import ThemeProvider from './ThemeProvider'
import ApolloProvider from './ApolloProvider'
import 'react-toastify/dist/ReactToastify.css'

Modal.setAppElement('#root')

interface Props {
    children: React.ReactNode
}

const AppProvider = (props: Props) => {
    const [modal, setModal] = useState<IModal>({ isVisible: false, modalData: null })
    const { children } = props

    return (
        <ModalContext.Provider value={{ modal, setModal }}>
            <ApolloProvider>
                <AuthProvider>
                    <ThemeProvider>
                        <GlobalStyles />
                        <StyledToast />
                        {children}
                    </ThemeProvider>
                </AuthProvider>
            </ApolloProvider>
        </ModalContext.Provider>
    )
}

export default AppProvider
