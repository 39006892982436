/* eslint-disable no-param-reassign */
import { DefaultTheme } from 'styled-components'
import { transparentize, darken, rgba, lighten, invert } from 'polished'
import { merge, get } from 'lodash'
import { getBgColor, getTextColor } from 'commons/utils'

export interface BtnTheme {
    bgColor: string
    textColor: string
    borderRadius: number
    hover: {
        bgColor: string
        textColor: string
        borderRadius: number
        transition: number
    }
}

export type LogoTheme = 'white' | 'black' | 'default'

export interface BoxTheme {
    bgColor: string
    logoColor: LogoTheme
    textColor: string
    button: BtnTheme
    borderRadius: number
}

export interface AppThemeV2 {
    bgColor: string
    font: string
    primaryColor: string
    highlight: string
    textColor: string
    menu: {
        bgColor: string
        textColor: string
        hover: {
            bgColor: string
            textColor: string
        }
        active: {
            bgColor: string
            textColor: string
            highlight: string
        }
    }
    box: {
        main: BoxTheme
        highlight: BoxTheme
        sponsor: BoxTheme
    }
    button: BtnTheme
    getBgColor: (path: string) => string
    getTextColor: (path: string) => string
    getValue: (path: string) => string
    getLogoColor: (path: string) => string
}

export interface AppTheme extends Partial<AppThemeV2> {
    primaryColor: string
    dark: string
    highlight: string
    nearWhite: string
    invertLogoColors: boolean
}

export default function createTheme(data?: Partial<AppTheme>): DefaultTheme {
    // remover versão antiga dos temas
    data = {}
    /// {"primaryColor":"#3d3d3d","highlight":"#ff9d00","nearWhite":"#ebebeb","dark":"#1a1a1a","type":"dark"}
    const v2 = merge(
        {
            bgColor: '#121212',
            font: 'Times New Roman',
            primaryColor: '#414141',
            highlight: '#FEBE10',
            textColor: '#fff',
            menu: {
                bgColor: '#121212',
                textColor: '#888',
                hover: {
                    bgColor: '#353535',
                    textColor: '#fff'
                },
                active: {
                    bgColor: '#353535',
                    textColor: '#fff',
                    highlight: '#FEBE10'
                }
            },

            box: {
                main: {
                    bgColor: '#414141',
                    textColor: '#fff',
                    borderRadius: 50,
                    logoColor: 'white' as LogoTheme,
                    button: {
                        bgColor: '#FEBE10',
                        textColor: '#000',
                        borderRadius: 50,
                        hover: {
                            transition: 0.2,
                            bgColor: darken(0.14, '#FEBE10'),
                            textColor: '#000',
                            borderRadius: 50
                        }
                    }
                },
                highlight: {
                    bgColor: '#FEBE10',
                    textColor: '#000',
                    borderRadius: 50,
                    logoColor: 'black' as LogoTheme,
                    button: {
                        bgColor: '#414141',
                        textColor: '#fff',
                        borderRadius: 50,
                        hover: {
                            transition: 0.2,
                            bgColor: '#fff',
                            textColor: '#414141',
                            borderRadius: 50
                        }
                    }
                },
                sponsor: {
                    bgColor: '#FFF',
                    textColor: '#FFF',
                    borderRadius: 50,
                    logoColor: 'defautl' as LogoTheme,
                    button: {
                        bgColor: '#414141',
                        textColor: '#fff',
                        borderRadius: 50,
                        hover: {
                            transition: 0.2,
                            bgColor: darken(0.14, '#414141'),
                            textColor: '#fff',
                            borderRadius: 50
                        }
                    }
                }
            },
            button: {
                bgColor: '#FEBE10',
                textColor: '#000',
                borderRadius: 50,
                hover: {
                    transition: 0.2,
                    bgColor: darken(0.14, '#FEBE10'),
                    textColor: '#000',
                    borderRadius: 50
                }
            }
        },
        {
            // ...data
        }
    )

    return {
        v2: {
            ...v2,
            getBgColor: (path: string): string => {
                return getBgColor(get(v2, path))
            },
            getTextColor: (path: string): string => {
                return getTextColor(get(v2, path))
            },
            getValue: (path: string): string => {
                return get(v2, path)
            },
            getLogoColor: (path: string): string => {
                const colorType = get(v2, path) as LogoTheme

                switch (colorType) {
                    case 'white':
                        return 'brightness(0) invert(1)'
                    case 'black':
                        return 'brightness(0)'
                    default:
                        return 'none'
                }
            }
        },
        main: {
            primary: data?.primaryColor || '#414141',
            dark: data?.dark || '#130F18',
            darkOpacity: transparentize(0.4, data?.dark || '#121212'),
            white: '#FBFCFF',
            nearWhite: data?.nearWhite || '#D1DEFF',
            highlight: data?.highlight || '#FEBE10',
            primaryBlur: transparentize(0.15, data?.primaryColor || '#414141'),
            nearWhiteOpacity: rgba(data?.nearWhite || '#D1DEFF', 0.1),
            nearWhiteOpacityMedium: rgba(data?.nearWhite || '#D1DEFF', 0.5),
            highlightInvert: invert(data?.highlight || '#FEBE10'),
            highlightSecond: darken(0.15, data?.highlight || '#FEBE10'),
            highlightLite: lighten(0.05, data?.highlight || '#FEBE10'),
            highlightOpacity: rgba(data?.highlight || '#FEBE10', 0.1),
            highlightGradient: `linear-gradient(270deg, ${darken(
                0.15,
                data?.highlight || '#FEBE10'
            )} 0%, ${data?.highlight || '#FEBE10'} 100%)`,
            secondary: '#6b9a83',
            whiteOpacity: 'rgba(255, 255, 255, 0.2)',
            accent: '#7F44FF',
            darkblue: '#071537',
            lightpurple: '#7F44FF',
            lightblue: '#00b7ff',
            danger: 'red',
            linkedinblue: '#0270AD',
            whatsapp: '#2AAC00',
            facebook: '#375691',
            twitter: '#29A4D9',
            greenhighlight: '#00D876',
            darkGrey: '#e2d1ff1a',
            mediumGrey: '#313740f7',
            invertLogoColors: data?.invertLogoColors || true
        },
        skeleton: {
            bg: 'rgba(255, 255, 255, 0.1)',
            highlight: 'rgba(255, 255, 255, 0.2)'
        },
        sizes: {
            xs: '480px',
            sm: '576px',
            md: '768px',
            lg: '992px',
            xl: '1024px',
            xxl: '1200px',
            xxxl: '1600px'
        }
    }
}
