import { Affix } from 'antd'
import useViewport from 'commons/hooks/useViewport'
import React from 'react'
import Draggable from 'react-draggable'
import { CustomPiP } from './styles'

const PiP: React.FC = ({ children }) => {
    const { vw } = useViewport()
    return (
        <>
            {vw > 567 ? (
                <div>
                    <Draggable>
                        <CustomPiP>{children}</CustomPiP>
                    </Draggable>
                </div>
            ) : (
                <Affix offsetBottom={0}>
                    <CustomPiP>{children}</CustomPiP>
                </Affix>
            )}
        </>
    )
}

export default PiP
