import styled from 'styled-components'
import { Empty as AntEmpty } from 'antd'

export const StyledEmpty = styled(AntEmpty)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg,
    path {
        fill: ${({ theme: { main } }) => main.white};
        opacity: 1;
        width: 30px;
    }

    .ant-empty-description {
        color: ${({ theme: { main } }) => main.white};
    }
`
