import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, FormInstance, Progress, Row } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

import { ButtonV2, Title } from 'commons/components'
import { useTheme } from 'styled-components'
import { ArrowFullRight } from 'commons/assets/icons'
import { StyledProgressContainer } from '../styles'

interface ProgressStepProps {
    form: FormInstance
    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    sectionsLength: number
    lastStep?: boolean
}

const ProgressStep = ({
    form,
    step,
    setStep,
    sectionsLength,
    lastStep = false
}: ProgressStepProps) => {
    const { t } = useTranslation('forms')
    const { main } = useTheme()

    return (
        <StyledProgressContainer>
            {!lastStep && (
                <Row style={{ marginBottom: '24px' }}>
                    <ButtonV2
                        className="btn-next-step"
                        type="primary"
                        shape="round"
                        onClick={async () => {
                            await form.validateFields()
                            setStep(step + 1)
                        }}
                    >
                        {t('forms.next')}
                        <ArrowFullRight fontSize={16} />
                    </ButtonV2>
                </Row>
            )}
            <Row>
                {lastStep && <Title fontSize={14}>100% {t('forms.completed')}</Title>}
                <Progress strokeColor={main.highlight} percent={(step / sectionsLength) * 90} />

                <Button
                    className="btn-step"
                    icon={<CaretLeftOutlined />}
                    onClick={() => setStep(step - 1)}
                />
                <Button
                    className="btn-step"
                    icon={<CaretRightOutlined />}
                    onClick={async () => {
                        await form.validateFields()
                        setStep(step + 1)
                    }}
                    disabled={step === sectionsLength + 1}
                />
            </Row>
        </StyledProgressContainer>
    )
}

export default ProgressStep
