import styled, { css } from 'styled-components'
import { lighten, transparentize } from 'polished'

export const StyledContainer = styled.div<{ gutter?: number }>`
    overflow: hidden;
    position: relative;

    .virtual-btn-step {
        position: absolute;
        top: 50%;
        z-index: 20;
        transform: translate(0%, -50%);
        &.virtual-btn-step--next {
            right: 0;
        }
    }

    .virtualized-autosize {
        max-width: 100%;
    }

    .virtualized-list {
        overflow: hidden !important;
        > div {
            position: relative;

            > div {
                @media (max-width: 767px) {
                    max-width: 320px;
                }
            }
        }
        ${({ theme }) => {
            return css`
                overflow-x: hidden;
                ::-webkit-scrollbar {
                    height: 10px;
                    margin: 0 10px;
                }

                ::-webkit-scrollbar-track {
                    background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb {
                    background: ${({ theme: { v2 } }) => v2.highlight};
                    border-radius: 10px;
                }
            `
        }}

        ${({ theme }) => css`
            @media (max-width: ${theme.sizes.md}) {
                overflow-x: auto !important;
                padding-bottom: 15px;
                padding-left: 8px;
            }
            @media (max-width: ${theme.sizes.sm}) {
                overflow-x: auto !important;
                padding-bottom: 15px;
                padding-left: 12px;
            }
        `}
    }
`

export const ArrowStep = styled.div<{ next?: boolean; innerArrow?: boolean; outside?: boolean }>`
    position: absolute;
    top: 50%;
    z-index: 20;
    transform: translate(0%, -50%);
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 0;

    svg {
        font-size: 20px;
        height: 20px;
        width: 20px;
    }

    ${({ next }) =>
        next &&
        css`
            right: 0;
        `}
    ${({ outside }) =>
        outside &&
        css`
            left: -30px;
        `}

    svg {
        transform: rotate(180deg);
        ${({ next }) =>
            next &&
            css`
                transform: unset;
            `}
    }

    @media (max-width: 767px) {
        display: none;
    }
`

export const LastItemOverlay = styled.div<{ width?: string | number; borderRadius?: number }>`
    width: 6%;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0.5;
    z-index: 5;
    pointer-events: none;
    background: ${({ theme: { main } }) =>
        `linear-gradient(90deg, ${transparentize(0.9, main.dark)} 0%, ${main.dark} 78%)`};
    ${({ borderRadius }) => {
        return (
            borderRadius &&
            css`
                border-top-left-radius: ${borderRadius}px;
                border-bottom-left-radius: ${borderRadius}px;
            `
        )
    }};

    @media (max-width: 767px) {
        display: none;
    }
`
