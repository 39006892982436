import styled from 'styled-components'

export const IconWrapper = styled.div`
    /* position: absolute; */
    display: flex;
    /* left: 35px; */
    /* top: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 70px;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        height: 16px;
    }
`
