import React from 'react'
import { Form, Input } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

const TextAreaInput: React.FC<IInputType> = ({ name, label, config }) => {
    return (
        <Wrapper>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <Input.TextArea rows={4} placeholder={label} />
            </Form.Item>
        </Wrapper>
    )
}

export default TextAreaInput
