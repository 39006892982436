import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'antd'
import { Text } from 'commons/components'

import { StyledAvatar } from './styles'

interface AvatarProps {
    size?: number
    src: string | null | undefined
    style?: any
    onClick?: () => void
    className?: string
}

const Avatar: React.FC<AvatarProps> = props => {
    const { src, size = 60, style, onClick, className } = props
    const { t } = useTranslation('commons')
    const defaultProfile = 'https://tdstorage.s3-sa-east-1.amazonaws.com/avatar/default.jpg'

    return (
        <Tooltip className={className} title={onClick && t('avatar.tooltip')}>
            <Text>
                <StyledAvatar
                    className={onClick && 'avatar-click'}
                    src={src || defaultProfile}
                    size={size}
                    style={style}
                    onClick={onClick}
                />
            </Text>
        </Tooltip>
    )
}

export default Avatar
