import React from 'react'
import { Col } from 'antd'
import { ButtonV2, Title } from 'commons/components'

import { ArrowFullRight } from 'commons/assets/icons'

interface GenericStepProps {
    title: string
    buttonLabel: string
    onClick: () => void
}

const GenericStep = ({ title, buttonLabel, onClick }: GenericStepProps) => (
    <div>
        <Col lg={16} md={18} xs={24}>
            <Title fontSize={36}>{title}</Title>
        </Col>

        <ButtonV2 type="primary" shape="round" onClick={onClick}>
            {buttonLabel}
            &nbsp;&nbsp;
            <ArrowFullRight fontSize={16} />
        </ButtonV2>
    </div>
)

export default GenericStep
