import styled from 'styled-components'
import Button from 'commons/components/Button'
import { rgba } from 'polished'


export const UserContainer = styled(Button)`
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.4s;
    position: relative;
    padding: 0;

    .ant-menu {
        background: transparent;
        border: 0;
        .ant-menu-submenu-title {
            padding: 0 16px;
            height: 40px;
            &:active {
                background: ${({ theme: { main } }) => rgba(main.highlight, 0.2)};
            }
            .ant-menu-submenu-arrow {
                display: none;
            }
        }
    }
    .language-selector-wrapper {
        width: 100%;
    }
`

export const StyledText = styled.div`
    color: white;
    font-size: 19px;
    text-align: right;
`
