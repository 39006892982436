import React, { ButtonHTMLAttributes } from 'react'
import { StyledButton } from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    children?: React.ReactNode
}

const Button = (props: ButtonProps) => {
    const { onClick, className, children, disabled, type } = props

    return (
        <StyledButton
            as="button"
            type={type}
            className={className}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </StyledButton>
    )
}

export default Button
