import styled, { css } from 'styled-components'
import { Layout } from 'antd'

const { Header } = Layout

export const StyledMobileHeader = styled(Header)<{ $isHome: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    background: ${({ theme }) => theme.main.darkOpacity} !important;
    position: fixed;
    padding: 20px;
    z-index: 99;
    height: 60px;
    ${({ $isHome, theme: { main, v2 } }) =>
        !$isHome &&
        css`
            background: ${v2.getBgColor('box.main.bgColor')} !important;
            border-bottom: 1px solid ${main.nearWhiteOpacity};
            backdrop-filter: blur(30px);
        `};

    > .inner-header-mobile {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        display: none;
    }

    img.logo {
        min-width: 30px;
        max-width: 300px;
        min-height: 10px;
        max-height: 30px;
        fill: ${({ theme: { main } }) => main.nearWhite};
    }
`
