import { gql } from '@apollo/client'

export const READ_NOTIFICATIONS = gql`
    mutation ReadNotifications($event: ObjectID!, $id: ObjectID) {
        readNotifications(event: $event, id: $id)
    }
`

export const VIEW_NOTIFICATIONS = gql`
    mutation ViewNotifications($event: ObjectID!) {
        viewNotifications(event: $event)
    }
`
