import React from 'react'
import { Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { Text, Title } from 'commons/components'
import { TitleType } from '../types'

interface Props {
    level?: TitleType | undefined
    type?: string | undefined
    title?: string | undefined
    partners?: boolean | undefined
}

const Header = (props: Props) => {
    const { level, type, title } = props
    const { t } = useTranslation('sponsors')
    const _title = title || (type ? t(`sponsors.${type}`) : t('sponsors.owner'))

    return (
        <Row className="row-text-center">
            {level !== 'normal' ? (
                <Title level={4} marginBottom={20}>
                    {_title}
                </Title>
            ) : (
                <Text fontSize={16} marginBottom={20}>
                    {_title}
                </Text>
            )}
        </Row>
    )
}

export default Header
