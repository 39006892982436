import styled from 'styled-components'
import { rgba } from 'polished'

interface Props {
    $borderRadius?: number | null | undefined
    $light: boolean | undefined
}

export const StyledOverlay = styled.div<Props>`
    position: absolute;
    width: 101%;
    height: 101%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : 0)};
    background-color: ${({
        theme: {
            main: { primaryBlur, nearWhite }
        },
        $light
    }) => ($light ? rgba(nearWhite, 0.1) : primaryBlur)};
    z-index: 2;

    & ~ * {
        z-index: 3;
        position: relative;
    }
`
