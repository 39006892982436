import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { GET_ONDEMAND_TALKS, GET_PREVIOUS_INFO } from 'commons/gql/queries/talks'
import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'

import { EventQueryData } from '../../types'
import { LoadingState, NotFoundRow } from '../Skeleton'
import Carousel from '../Carousel'
import { useTalksText } from '../texts'
import TalksWrapper from '../TalksWrapper'
import { TalkTitle } from '../styles'

const OnDemandCarrousel = () => {
    const { idEvent } = useAuth()
    const { onDemandTalks } = useTalksText()
    const [talksItems, setTalksItems] = useState<any>([])

    const { data: dataInfo } = useQuery<EventQueryData, ById>(GET_PREVIOUS_INFO, {
        variables: {
            id: idEvent as string
        },
        fetchPolicy: 'network-only'
    })

    const { data, fetchMore } = useQuery<EventQueryData, ById>(GET_ONDEMAND_TALKS, {
        variables: {
            id: idEvent as string,
            first: 10
        },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            const talkInfos = talks?.map(({ node: { id, title, image } }) => {
                return {
                    id,
                    thumbnail: image ? image.url : '',
                    title
                }
            })

            if (talks) setTalksItems([...talkInfos])
        }
    }, [data])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {onDemandTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = dataInfo?.event?.logo?.url
    const { pageInfo } = data.talks

    const getMoreItems = async () => {
        if (pageInfo?.hasNextPage) {
            await fetchMore({
                variables: { after: pageInfo.endCursor }
            })
        }
    }

    if (!talksItems || !talksItems.length) {
        return null
    }

    return (
        <TalksWrapper className="ondemand-talks">
            <TalkTitle fontSize={20} level={4}>
                {onDemandTalks}
            </TalkTitle>
            {talksItems && talksItems.length > 0 ? (
                <Carousel
                    items={talksItems}
                    defaultThumbnail={defaultThumbnail}
                    hasNext={pageInfo?.hasNextPage}
                    getMoreItems={getMoreItems}
                />
            ) : (
                <NotFoundRow />
            )}
        </TalksWrapper>
    )
}

export default OnDemandCarrousel
