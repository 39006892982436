import React, { useState, useCallback } from 'react'
import { useQuery } from '@apollo/client'

import { ReactComponent as Logo } from 'commons/assets/icons/logo.svg'
import { GET_CURRENT_CUSTOMER } from 'commons/gql/queries/customer'
import { Customer } from 'commons/gql/models/customer'
import { MenuData } from 'commons/gql/models/menuData'

import { Row, Space } from 'antd'

import MobileMenu from '../MobileMenu'
import HamburguerMenu from '../HamburguerMenu'

import { StyledMobileHeader } from './styles'

interface Props {
    isHome: boolean
    menuData?: MenuData
}

interface QueryData {
    currentCustomer: Customer
}

const MobileHeader = (props: Props) => {
    const { isHome, menuData } = props
    const [open, setOpen] = useState(false)
    const { data } = useQuery<QueryData>(GET_CURRENT_CUSTOMER)

    const handleOpenMenu = useCallback(() => {
        setOpen(true)
    }, [])

    const handleCloseMenu = useCallback(() => {
        setOpen(false)
    }, [])

    const defaultImage = data ? <Logo /> : <span />

    return (
        <>
            <StyledMobileHeader className="box-main" $isHome={isHome}>
                <Row className="inner-header-mobile" justify="space-between" align="middle">
                    {data?.currentCustomer?.logo ? (
                        <img
                            className="logo"
                            alt="customer logo"
                            src={data?.currentCustomer?.logo?.url}
                        />
                    ) : (
                        defaultImage
                    )}
                    <Space>
                        <HamburguerMenu handleOpenMenu={handleOpenMenu} />
                    </Space>
                </Row>
            </StyledMobileHeader>

            <MobileMenu
                open={open}
                handleCloseMenu={handleCloseMenu}
                logo={data?.currentCustomer?.logo?.url}
                menuData={menuData}
            />
        </>
    )
}

export default MobileHeader
