import styled from 'styled-components'
import { Layout, Divider } from 'antd'
import { ButtonV2 } from 'commons/components'

const { Content } = Layout

export const Wrapper = styled(Content)`
    width: 100%;

    &.mt-50 {
        margin-top: 50px;
    }

    &.mt-24 {
        margin-top: 24px;
    }

    &.mb-24 {
        margin-bottom: 24px;
    }

    &.flex {
        display: flex;
    }
    &.flex-column {
        flex-direction: column;
    }

    &.flex-row {
        flex-direction: row;
    }

    .space-bottom--lg {
        margin-bottom: 50px;
    }
`
export const Container = styled.div`
    width: 100%;
    padding: 20px 0;
    position: relative;
`
export const SquareImage = styled.img`
    width: 190px;
    height: 190px;
    position: absolute;
    left: 20px;
    top: 278px;
    background: ${({ theme }) => theme.main.white};
    padding: 20px;
    border-radius: 20px;
`

export const BrandImg = styled.img`
    width: 148px;
    height: 80px;
    padding: 20px;
    border-radius: 10px;
    /* background: ${({ theme }) => theme.main.white}; */
    margin-bottom: 30px;
`

export const Separator = styled(Divider)`
    background-color: #d1e6ff33;
`
export const SiteButton = styled(ButtonV2)`
    background-color: #3a8fd5;
    border-color: #3a8fd5;
    color: ${({ theme }) => theme.main.white};
`
