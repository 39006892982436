import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { SpeakerCardProps } from '.'
import { Paragraph } from '../Typography'

const more = require('commons/assets/icons/outline/more.svg')

type WrapperProps = Pick<
    SpeakerCardProps,
    'isHorizontal' | 'fullContent' | 'showSocial' | 'clickAble' | 'boxType'
>

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    padding-bottom: 8px;
    pointer-events: auto;

    @media (max-width: 767px) {
        padding: 16px 8px;
    }
`

export const Name = styled.h4`
    ${({ theme }) => css`
        font-size: 14px;
        font-weight: bold;
        line-height: 120%;
        color: ${theme.main.white};
    `}
`

export const Office = styled.p`
    ${({ theme }) => css`
        font-size: 10px;
        font-weight: normal;
        line-height: 120%;
        color: ${theme.main.white};
    `}
`

export const Company = styled(Paragraph)`
    height: 50px;
    ${({ theme }) => css`
        font-size: 10px;
        font-weight: bold;
        line-height: 120%;
        color: ${theme.main.white};
    `}
`

export const SpeakerInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 16px;

    .speaker-office {
        height: 30px;
    }

    ${Name}, ${Office}, ${Company} {
        margin-bottom: 5px;
    }
`

const WrapperModifiers = {
    isHorizontal: () => css`
        background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
        width: 258px;
        min-height: 100px;

        ${Content} {
            padding: 10px 8px;
            flex-direction: row;

            .ant-typography {
                margin-bottom: 0;
            }

            .speaker-company-logo {
                display: none;
            }
        }

        ${Company} {
            height: auto;
            align-items: left;
        }

        ${Company} {
            height: auto;
        }

        ${SpeakerInfos} {
            align-items: flex-start;
            padding-left: 8px;
            padding-top: 0;
        }
    `,
    fullContent: (content: boolean | string) => css`
        ${Content} {
        }
        ${Name} {
            font-size: 18px;
        }
        ${Office} {
            font-size: 13px;
        }
        ${Company} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            max-width: 100%;
            padding: 10px;
            margin: 0 auto;
            border-radius: 20px;
            min-width: 100px;
            /* background-color: ${({ theme: { v2 } }) => rgba(v2.primaryColor, 0.2)}; */
        }
        .ant-avatar-group {
            border-top: 1px solid ${({ theme: { main } }) => main.nearWhiteOpacity};
            border-bottom: ${({ theme: { main } }) =>
                content === 'withBio' ? `1px solid ${main.nearWhiteOpacity}` : '0px'};
        }
    `
}

export const Wrapper = styled.div<WrapperProps>`
    cursor: ${({ clickAble }) => clickAble && 'pointer'};
    /* background-color: ${({ theme: { main }, boxType }) => !boxType && main.nearWhiteOpacity}; */
    overflow: hidden;
    .ant-avatar-group {
        display: flex;
        justify-content: center;
        padding: 8px 16px;

        > span.ant-avatar {
            display: block;
            margin-left: 2px;
            margin-right: 2px;
            height: 40px;
            width: 40px;
            background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
            border: none;
            color: transparent;
            margin-left: 0px;
            position: relative;

            &::after {
                content: '';
                mask: url('${more}');
                mask-size: cover;
                background-color: ${({ theme: { main } }) => main.white};
                color: #fff;
                display: block;
                font-weight: 900;
                width: 20px;
                height: 20px;
                position: absolute;
                left: 10px;
                top: 9px;
            }

            &:hover {
                &::after {
                    background-color: ${({ theme: { main } }) => main.highlight};
                }
            }
        }

        svg {
            width: 20px;
            fill: ${({ theme: { main } }) => main.white};
            path {
                fill: ${({ theme: { main } }) => main.white};
            }
        }

        @media (max-width: 767px) {
            padding: 8px;
        }
    }

    ${({ isHorizontal, fullContent, showSocial, theme: { main } }) => css`
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        min-height: 180px;
        width: 100%;
        height: 100%;
        justify-content: ${showSocial ? 'space-between' : 'center'};

        ${isHorizontal && WrapperModifiers.isHorizontal()}
        ${fullContent && WrapperModifiers.fullContent(fullContent)}

        .speaker-company-logo {
            max-height: 25px;
            max-width: 80px;
            margin-top: 5px;
        }
    `}
`

export const SocialNetworks = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    pointer-events: none;
`

export const LinkedinButton = styled.a`
    ${({ theme }) => css`
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        margin: 0 10px 13px;
        border-radius: 20px;
        background-color: #2868b2;
        color: ${theme.main.white};
    `}
`

export const WrapperIcon = styled.a`
    background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    pointer-events: auto;
    width: 40px;
    margin-left: 2px;
    margin-right: 2px;

    .ant-avatar {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
        }

        :hover {
            svg {
                width: 20px;

                path {
                    fill: ${({ theme: { v2 } }) => v2.highlight};
                }
            }
        }
    }
`

export const Bio = styled.div`
    padding: 20px;

    @media (max-width: 767px) {
        .ant-typography {
            max-height: 80px;
            overflow: auto;
        }
    }
`
