import React from 'react'
import { Row, Col } from 'antd'

import { ButtonV2, ActionModal } from 'commons/components'
import { usePermissions } from 'commons/hooks/usePermissions'
import { ActionsType } from 'commons/gql/models/brand'
import { useHistory } from 'react-router-dom'

interface ActionProps {
    actions: Array<ActionsType>
    userId?: string | undefined
    sponsorId?: string
}

const Actions: React.FC<ActionProps> = props => {
    const { actions, userId, sponsorId } = props
    const history = useHistory()
    const permissions = usePermissions()

    const handleChatClick = () => {
        window.TDA.track('brandContact', {
            id: userId
        })
        history.push(`/home/networking/${userId}`)
    }

    return (
        <Row gutter={[6, 12]}>
            {userId && permissions.includes('NETWORKING') ? (
                <Col>
                    <ButtonV2
                        onClick={handleChatClick}
                        style={{ minWidth: '80px' }}
                        ghost
                        size="large"
                    >
                        Chat
                    </ButtonV2>
                </Col>
            ) : null}
            {actions.map(action => {
                return (
                    <Col key={action.name}>
                        <ActionModal sponsorId={sponsorId} {...action} />
                    </Col>
                )
            })}
        </Row>
    )
}

export default Actions
