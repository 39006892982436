import React, { createContext, useContext, useState } from 'react'
import { StatusType } from '../../types'

interface VideoPlayerProps {
    id?: string
    url: string | undefined
    fallbackUrl?: string
    poster?: string
    currentTime?: number
    status: StatusType
    autoplay?: boolean
    isSponsor?: boolean
    isLive?: boolean

    talkPath?: string
}

interface PlayerPiPContextProps {
    videoInfo: VideoPlayerProps
    setVideoInfo: React.Dispatch<React.SetStateAction<VideoPlayerProps>>
    miniPlayer: boolean
    setMiniPlayer: React.Dispatch<React.SetStateAction<boolean>>
}

export const PlayerPiPContext = createContext<PlayerPiPContextProps>({
    videoInfo: {
        url: '',
        status: 'WAITING'
    },
    setVideoInfo: value => null,
    miniPlayer: false,
    setMiniPlayer: value => null
})

export const PlayerPiPProvider: React.FC = ({ children }) => {
    const [miniPlayer, setMiniPlayer] = useState(false)

    const [videoInfo, setVideoInfo] = useState<VideoPlayerProps>({
        url: '',
        status: 'WAITING'
    })

    return (
        <PlayerPiPContext.Provider
            value={{
                miniPlayer,
                setMiniPlayer,
                videoInfo,
                setVideoInfo
            }}
        >
            {children}
        </PlayerPiPContext.Provider>
    )
}

export const usePlayerPiP = (): PlayerPiPContextProps => {
    const context = useContext(PlayerPiPContext)
    if (context) {
        const { miniPlayer, setMiniPlayer, videoInfo, setVideoInfo } = context
        return { miniPlayer, setMiniPlayer, videoInfo, setVideoInfo }
    }
    throw new Error('usePlayerPiP must be used within a PlayerPiPProvider')
}
