import { gql } from '@apollo/client'

export const GET_CURRENT_CUSTOMER = gql`
    query GetCurrentCustomer {
        currentCustomer {
            name
            logo {
                url
            }
        }
    }
`
