import styled from 'styled-components'
import { rgba } from 'polished'
import { Layout } from 'antd'

const { Sider: AntSider } = Layout

export const Sider = styled(AntSider)`
    background: ${({ theme: { v2 } }) => v2.bgColor};
    /* backdrop-filter: blur(30px); */
    border-right-width: 1px;
    border-right-color: ${({ theme: { v2 } }) => v2.primaryColor};
    border-right-style: solid;

    .ant-menu {
        background: transparent;

        .ant-menu-item {
            position: relative;
            box-sizing: border-box;
            margin-top: 5px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 10px;
            line-height: 12px;

            &:hover {
                box-shadow: inset 3px 0px 0 0px ${({ theme: { v2 } }) => v2.menu.active.highlight};
                background-color: ${({ theme: { v2 } }) => v2.menu.hover.bgColor};

                span {
                    font-weight: normal;

                    a {
                        color: ${({ theme: { v2 } }) => v2.menu.hover.textColor};
                    }
                }

                svg {
                    * {
                        opacity: 1;
                        fill: ${({ theme: { v2 } }) => v2.menu.hover.textColor};
                    }
                }
            }

            span {
                a {
                    color: ${({ theme: { v2 } }) => v2.menu.textColor};
                }
            }

            &:active {
                background: ${({ theme: { v2 } }) => v2.menu.active.bgColor};
            }

            .ant-menu-item-icon {
                margin-right: 0;
                margin-bottom: 8px;
            }

            svg {
                vertical-align: middle;
                width: 47px;
                height: 17px;
                * {
                    opacity: 1;
                    fill: ${({ theme: { v2 } }) => v2.menu.textColor};
                }
            }
        }
        .menu-item-side-selected {
            background: ${({ theme: { v2 } }) => v2.menu.active.bgColor};
            box-shadow: inset 3px 0px 0 0px ${({ theme: { v2 } }) => v2.menu.active.highlight};
            span {
                font-weight: normal;

                a {
                    color: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                }
            }

            svg {
                * {
                    opacity: 1;
                    fill: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                }
            }
        }
    }
    .ant-menu-vertical {
        border-right: none;
        overflow: auto;
        height: calc(100% - 260px);
    }

    @media (max-width: 767px) {
        display: none;
    }
`

export const SideHeader = styled.div`
    position: relative;
    padding: 24px 0 0 0;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg,
    img {
        max-width: 50px;
        min-height: 18px;
        max-height: 37px;
        fill: ${({ theme: { main } }) => main.nearWhite};
    }
`
export const SideFooter = styled.div`
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
        width: 100%;
        ul {
            width: 100%;
        }
    }
`
