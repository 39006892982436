import { Collection } from 'commons/gql/types/collection'
import { Brand } from 'commons/gql/models/brand'

const sponsors = {
    XG: {
        desktop: {
            columns: 8,
            minHeight: 464,
            minWidth: 475,
            maxWidth: 600
        },
        mobile: {
            columns: 24,
            minHeight: 417,
            minWidth: 314
        },
        card: 'XG'
    },
    G: {
        desktop: {
            columns: 8,
            minHeight: 200,
            minWidth: 366
        },
        mobile: {
            columns: 24,
            minHeight: 200,
            minWidth: 314
        },
        card: 'G'
    },
    M: {
        desktop: {
            columns: 6,
            minHeight: 178,
            minWidth: 269
        },
        mobile: {
            columns: 24,
            minHeight: 178,
            minWidth: 314
        },
        card: 'M'
    },
    P: {
        desktop: {
            columns: 4,
            minHeight: 113,
            minWidth: 191
        },
        mobile: {
            columns: 12,
            minHeight: 76 /* 0,54437 */,
            minWidth: 140
        },
        card: 'P'
    },
    PP: {
        desktop: {
            columns: 3,
            minHeight: 80,
            minWidth: 147
        },
        mobile: {
            columns: 8,
            minHeight: 45,
            minWidth: 90
        },
        card: 'PP'
    },
    Sidebar: {
        desktop: {
            columns: 12,
            minHeight: 76,
            minWidth: 140
        },
        mobile: {
            columns: 12,
            minHeight: 60,
            minWidth: 110
        },
        card: 'PP'
    },
    Carrousel: {
        desktop: {
            columns: 3,
            minHeight: 50,
            minWidth: 100
        },
        mobile: {
            columns: 24,
            minHeight: 50,
            minWidth: 100
        },
        card: 'Carrousel'
    }
}

export const useGrid = (type: keyof typeof sponsors) => {
    const _grid = sponsors[type]
    return _grid
}

export default function formatListSponsors(brands: Collection<Brand>) {
    if (!brands) return []
    const { edges } = brands

    return edges.map(({ node: { name, id, logo, description } }) => ({
        name,
        id,
        logo,
        description,
        url: logo?.url
    }))
}
