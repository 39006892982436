import { gql } from '@apollo/client'

export const GET_SPEAKERS = gql`
    query GetSpeakers(
        $event: ObjectID
        $talk: ObjectID
        $featured: Filter
        $search: String
        $category: ObjectID
        $after: Cursor
        $first: Int
        $orderBy: SpeakerOrderBy
    ) {
        speakers(
            filter: { event: $event, talk: $talk, category: $category, featured: $featured }
            orderBy: $orderBy
            search: $search
            after: $after
            first: $first
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    name
                    bio
                    company {
                        name
                        image {
                            url
                        }
                    }
                    office
                    image {
                        id
                        url
                    }
                    user {
                        id
                    }
                    links {
                        url
                        type
                    }
                }
            }
        }
    }
`

export const GET_CHIEFS = gql`
    query GetSpeakers(
        $event: ObjectID
        $talk: ObjectID
        $featured: Filter
        $search: String
        $category: ObjectID
    ) {
        speakers(
            filter: {
                event: $event
                talk: $talk
                category: $category
                featured: $featured
                role: "chief"
            }
            search: $search
        ) {
            edges {
                node {
                    id
                    name
                    bio
                    company {
                        name
                        image {
                            url
                        }
                    }
                    office
                    image {
                        id
                        url
                    }
                    links {
                        url
                        type
                    }
                }
            }
        }
    }
`
