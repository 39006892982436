import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Button } from 'antd'

import { ButtonProps } from 'antd/lib/button'

interface BtnProps extends ButtonProps {
    isSelected?: boolean
}

export const MenuOption = styled(Button)<BtnProps>`
    &.ant-btn {
        background-color: ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};
        color: ${({ theme }) => theme.main.nearWhite};
        border: 0;
        border-radius: 10px;
        font-size: 12px;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: left;
        align-items: center;

        span {
            color: ${({ theme }) => theme.main.nearWhite};
            opacity: 0.5;
        }

        svg {
            max-width: 20px;
            opacity: 0.5;
            fill: ${({ theme }) => theme.main.nearWhite};
            g {
                fill: ${({ theme }) => theme.main.nearWhite};
            }
            margin-right: 10px;
        }
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            &.ant-btn {
                //background-image: ${({ theme: { main } }) => main.highlightGradient};
                color: ${({ theme }) => theme.v2.menu.active.textColor};
                background-color: transparent;
                > span {
                    color: ${({ theme }) => theme.v2.menu.active.textColor};
                    opacity: 1;
                }

                > svg {
                    opacity: 1;
                    fill: ${({ theme }) => theme.v2.menu.active.textColor};
                    path {
                        opacity: 1;
                    }
                }
            }
        `}

    &:hover {
        &.ant-btn {
            color: ${({ theme }) => theme.v2.menu.hover.textColor};
            background-color: transparent;
            //background-image: ${({ theme: { main } }) => main.highlightGradient};
            > span {
                color: ${({ theme }) => theme.v2.menu.hover.textColor};
                opacity: 1;
            }

            > svg {
                * {
                    fill: ${({ theme }) => theme.v2.menu.hover.textColor};
                    opacity: 1;
                }
            }
        }
    }
`
