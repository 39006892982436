import styled, { css } from 'styled-components'
import Button from 'commons/components/Button'

import { lighten, transparentize } from 'polished'

const ActivatedCss = css`
    background-color: rgba(255, 255, 255, 0.2);
`

export const StyledButton = styled(Button)<{ activated?: boolean }>`
    background: transparent;
    padding: 0;
    ${({ activated }) => activated && ActivatedCss};

    > svg {
        path {
            fill: ${({ theme: { main } }) => main.highlight};
        }
    }

    @media (max-width: 767px) {
        > svg {
            width: 20px;
            height: 20px;

            path {
                fill: ${({ theme: { main } }) => main.highlight};
            }
        }
    }
`

export const StyledContainer = styled.div`
    position: relative;

    .picker-teste {
        position: absolute;
        z-index: 1001;
        width: 100%;
        height: 100%;
        transform: translateZ(0);
    }
`

export const StyledPickerContainer = styled.div`
    bottom: 54px;
    width: 353px;
    left: -270px;
    position: absolute;
    background: ${({ theme: { v2 } }) => v2.menu.bgColor};
    z-index: 100;
    backdrop-filter: blur(30px);

    .emoji-mart {
        border: none;
        font-family: inherit;
        backdrop-filter: blur(30px);
        background: ${({ theme: { v2 } }) => v2.menu.bgColor};
        .emoji-mart-search {
            border: none;
            border-bottom: 1px solid ${({ theme: { main } }) => main.nearWhiteOpacity};
            margin: 6px;
            #emoji-mart-search-1 {
                background: ${({ theme: { main } }) => main.nearWhiteOpacity};
                border: none;
                margin-bottom: 6px;
                &::placeholder {
                    color: ${({ theme: { v2 } }) => v2.menu.textColor};
                }
            }
        }
        .emoji-mart-anchor-icon {
            svg {
                fill: ${({ theme: { main } }) => main.nearWhiteOpacity};
                &:hover {
                    fill: ${({ theme: { main } }) => main.nearWhite};
                }
            }
        }
        .emoji-mart-preview-emoji {
            display: none;
        }
        .emoji-mart-preview-data {
            left: 145px;
        }
        .emoji-mart-category-label span {
            background: transparent;
            color: ${({ theme: { main } }) => main.white};
        }
        .emoji-mart-title-label {
            color: ${({ theme: { main } }) => main.white};
            font-size: 16px;
        }
        .emoji-mart-scroll {
            ::-webkit-scrollbar {
                width: 5px;
            }

            ::-webkit-scrollbar-track {
                background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb {
                background: ${({ theme: { v2 } }) => v2.highlight};
                border-radius: 10px;
            }
        }
        .emoji-mart-bar {
            border: none;
            &:first-child {
                border-bottom-width: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        .emoji-mart-skin-swatch {
            cursor: pointer;
        }
        nav {
            button {
                &.emoji-mart-anchor-selected {
                    .emoji-mart-anchor-icon {
                        > svg {
                            fill: ${({ theme: { main } }) => main.highlight};
                        }
                    }
                    .emoji-mart-anchor-bar {
                        background-color: ${({ theme: { main } }) => main.highlight} !important;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        width: auto;
        bottom: 28px;
        right: 100px;
        z-index: 1001;

        .emoji-mart {
            width: 310px;
        }
    }

    @media screen and (max-width: 767px) {
        width: auto;
        bottom: 28px;
        left: 0;
        z-index: 1001;

        .emoji-mart {
            width: 310px;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.sizes.xs}) {
        left: -277px;
    }
`
