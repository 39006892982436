import { useQuery } from '@apollo/client'
import { ReactComponent as Logo } from 'commons/assets/icons/logo.svg'
import { Menu, UserMenu } from 'commons/components'
import { Customer } from 'commons/gql/models/customer'
import { GET_CURRENT_CUSTOMER } from 'commons/gql/queries/customer'
import { GET_EVENT_EDITIONS } from 'commons/gql/queries/eventEdition'
import { GET_CURRENT_USER } from 'commons/gql/queries/user'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { FC, useEffect, useState } from 'react'
import { UserData } from 'commons/gql/models/userData'
import { MenuData } from 'commons/gql/models/menuData'
import { REACT_APP_GRAPHQL_API } from 'commons/providers/ApolloProvider'
import EventEditionMenu from '../EventEditionsMenu'

import Notifications from '../Notifications'
import { SideFooter, SideHeader, Sider } from './styles'

interface QueryData {
    currentCustomer: Customer
}

interface EventInfo {
    id: string
    name: string
    authUrl: string
}

const URL_AUTH = REACT_APP_GRAPHQL_API?.replace('/graphql', '/api/auth/event')

const Sidebar: FC<{ menuData?: MenuData }> = ({ menuData }) => {
    const { idEvent, token } = useAuth()
    const [events, setEvents] = useState<EventInfo[]>([])

    const { data } = useQuery<QueryData>(GET_CURRENT_CUSTOMER)

    const { data: userData } = useQuery<UserData>(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache'
    })

    const { data: eventsData, loading } = useQuery(GET_EVENT_EDITIONS, {
        variables: {
            eventId: idEvent as string
        }
    })

    useEffect(() => {
        if (!loading && eventsData?.event) {
            const editions = eventsData.event.editions.map(({ name, id }: any) => ({
                name,
                id,
                authUrl: `${URL_AUTH}/${id}/?code=${token}`
            }))

            editions.push({
                id: idEvent,
                name: eventsData.event.name,
                authUrl: `${URL_AUTH}/${idEvent}/?code=${token}`
            })

            setEvents(editions)
        }
    }, [loading])

    const CustomerLogo = () => {
        return data?.currentCustomer?.logo ? (
            <img
                alt="customer logo"
                src={data.currentCustomer.logo.url}
                className="customer-logo"
            />
        ) : (
            <Logo />
        )
    }

    return (
        <Sider width={80}>
            <SideHeader>
                {events && events.length > 1 ? (
                    <EventEditionMenu
                        events={events}
                        eventId={idEvent as string}
                        user={userData!}
                        icon={CustomerLogo}
                    />
                ) : (
                    CustomerLogo()
                )}
            </SideHeader>
            <Menu menuData={menuData} />
            <SideFooter>
                <Notifications />
                <UserMenu
                    data={userData}
                    logo={data?.currentCustomer?.logo?.url}
                    userMenuData={menuData?.user}
                />
            </SideFooter>
        </Sider>
    )
}

export default Sidebar
