import React, { useState, useEffect } from 'react'
import { Upload, message, Space } from 'antd'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { UPLOAD_MEDIA } from 'commons/gql/mutations/media'
import { Image } from 'commons/assets/icons'
import { Paragraph, Loader } from 'commons/components'
import { UploaderContainer, IconWrapper } from './style'

const { Dragger } = Upload

interface Props {
    value?: any
    onChange?: any
    options?: any
}

const MediaUploader: React.FC<Props> = ({ value, onChange, options }) => {
    const { t } = useTranslation('commons')
    const [showUploader, setShowUploader] = useState(true)
    const [fileList, setFileList] = useState<any[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadMedia] = useMutation(UPLOAD_MEDIA)

    useEffect(() => {
        if (!value) return
        const { _id, filename, url } = value

        setFileList([
            {
                uid: _id,
                response: value,
                name: filename,
                status: 'done',
                url
            }
        ])
        onChange(value)
        setShowUploader(false)
    }, [value])

    const handleRemove = (item: any) => {
        onChange(null)
        setShowUploader(true)
    }

    const handleUpload = async ({ onSuccess, onError, file, onProgress }: any) => {
        setLoading(true)
        const result = await uploadMedia({
            variables: { file, options },
            context: {
                fetchOptions: {
                    useUpload: true,
                    onProgress: (e: any) => {
                        const percent = Math.floor((e.loaded / e.total) * 100)

                        if (onProgress) onProgress({ percent })
                    }
                }
            }
        })

        if (result.data && result.data.uploadMedia) {
            const { _id, filename, url } = result.data.uploadMedia
            setFileList([
                {
                    uid: _id,
                    response: result.data.uploadMedia,
                    name: filename,
                    status: 'done',
                    url
                }
            ])
            onChange(result.data.uploadMedia)
            setShowUploader(false)
            setLoading(false)
            message.success(`${filename} carregado com sucesso`)

            if (onSuccess) {
                onSuccess(result.data.uploadMedia)
            }
        }
    }

    const props = {
        name: 'file',
        multiple: false,
        showUploadList: {
            showPreviewIcon: false,
            showRemoveIcon: true
        },
        onChange(info: any) {
            const { status } = info.file
            if (status !== 'uploading') {
                console.log('not upload')
            }
            if (status === 'done') {
                // if(info.fileList.length > 0){
                //     let _fileList = [ ...info.fileList]
                //     _fileList = _fileList.slice(-1)
                //     _fileList = _fileList.map(formatFile)
                //     message.success(`${info.file.name} carregado com sucesso`)
                //     onChange(info.fileList[0].response.id)
                //     setShowUploader(false)
                // }
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`)
            }
        },
        onDrop(e: any) {
            console.log('Dropped files', e.dataTransfer.files)
        }
    }

    const beforeUpload = (file: any) => {
        const isImage = file.type.indexOf('image/') === 0
        if (!isImage) {
            message.error('Você só pode carregar imagens')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('A imagem deve ter no máximo 2MB')
        }
        return isImage && isLt2M
    }

    const deleteText = t('upload.delete')
    return (
        <UploaderContainer showUploader={showUploader} deleteText={deleteText}>
            {!loading ? (
                <Dragger
                    {...props}
                    listType="picture-card"
                    onRemove={handleRemove}
                    customRequest={handleUpload}
                    fileList={fileList}
                    beforeUpload={beforeUpload}
                >
                    <Space>
                        <IconWrapper>
                            <Image />
                        </IconWrapper>
                        <Paragraph fontSize={14}>{t('upload.text')}</Paragraph>
                    </Space>
                </Dragger>
            ) : (
                <Loader isPage />
            )}
        </UploaderContainer>
    )
}

export default MediaUploader
