/* eslint-disable jsx-a11y/media-has-caption */
import React, {
    HTMLAttributes,
    MouseEvent,
    MutableRefObject,
    useCallback,
    useEffect,
    useRef
} from 'react'
import Hls from 'hls.js'
import PlyrJS from 'plyr'

import { HTMLPlyrVideoElement, PlyrProps, PlyrOptions, PlyrInstance } from './types'
import { PlyrContext } from './context'

const controls = ['play', 'mute', 'volume', 'progress', 'settings', 'fullscreen']

const defaultOptions: PlyrOptions = {
    debug: false,
    autoplay: true,
    resetOnEnd: true,
    speed: {
        selected: 1,
        options: [0.75, 1, 1.25, 1.5, 2]
    },
    disableContextMenu: true,
    // URLs
    urls: {
        download: null,
        vimeo: {
            sdk: 'https://player.vimeo.com/api/player.js',
            iframe: '{0}?{1}',
            api: 'https://vimeo.com/api/oembed.json?url={0}'
        },
        youtube: {
            sdk: 'https://www.youtube.com/iframe_api',
            api: 'https://noembed.com/embed?url=https://www.youtube.com/watch?v={0}'
        },
        googleIMA: {
            sdk: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js'
        }
    },
    controls,
    i18n: {
        restart: 'Restart',
        rewind: 'Rewind {seektime}s',
        play: 'Play',
        pause: 'Pause',
        fastForward: 'Forward {seektime}s',
        seek: 'Seek',
        seekLabel: '{currentTime} of {duration}',
        played: 'Played',
        buffered: 'Buffered',
        currentTime: 'Current time',
        duration: 'Duration',
        volume: 'Volume',
        mute: 'Mute',
        unmute: 'Unmute',
        enableCaptions: 'Enable captions',
        disableCaptions: 'Disable captions',
        download: 'Download',
        enterFullscreen: 'Enter fullscreen',
        exitFullscreen: 'Exit fullscreen',
        frameTitle: 'Player for {title}',
        captions: 'Captions',
        settings: 'Settings',
        menuBack: 'Go back to previous menu',
        speed: 'Speed',
        normal: 'Normal',
        quality: 'Quality',
        loop: 'Loop'
    }
}

const Plyr = React.forwardRef<HTMLPlyrVideoElement, PlyrProps>((props, ref) => {
    const { autoplay, source, fullscreen = true, ...rest } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const playerRef = useRef<HTMLPlyrVideoElement | undefined>()

    const setRef = useCallback(
        (instance: HTMLPlyrVideoElement) => {
            if (typeof ref === 'function') {
                ref(instance)
            } else if (ref && instance) {
                // eslint-disable-next-line no-param-reassign
                ref.current = instance
            }
        },
        [ref]
    )

    useEffect(() => {
        if (!playerRef.current) return
        if (!containerRef.current) return

        const _options: PlyrOptions = {
            ...defaultOptions,
            autoplay,
            fullscreen: { enabled: fullscreen },
            controls: fullscreen ? controls : controls.filter(c => c !== 'settings')
        }

        if (!playerRef.current?.plyr) {
            playerRef.current.plyr = new PlyrJS(playerRef.current, _options) as PlyrInstance
            setRef(playerRef.current)
        }

        if (playerRef.current?.plyr && source) {
            const item = source.sources[0]
            playerRef.current.plyr.destroy()

            const el = document.createElement('video')
            containerRef.current.innerHTML = ''
            containerRef.current.appendChild(el)

            if (item.provider === 'vimeo') {
                _options.urls.vimeo.iframe =
                    item.src.indexOf('event') > -1
                        ? '{0}?{1}'
                        : 'https://player.vimeo.com/video/{0}?{1}'
            }

            playerRef.current = el
            playerRef.current.plyr = new PlyrJS(playerRef.current, _options) as PlyrInstance
            setRef(playerRef.current)

            if (item.src.indexOf('.m3u8') > -1) {
                const plyr = playerRef.current?.plyr

                const hls = new Hls()
                hls.loadSource(item.src)
                hls.attachMedia(el as HTMLMediaElement)
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    plyr.play()
                })
            } else {
                const plyr = playerRef.current?.plyr
                plyr.source = source
                plyr.play()
            }
        }
    }, [ref, source, autoplay, setRef])

    useEffect(() => {
        return () => {
            if (playerRef.current?.plyr) {
                playerRef.current.plyr.destroy()
            }
        }
    }, [])

    return (
        <PlyrContext.Provider value={playerRef.current}>
            <div className="plyr-react" ref={containerRef}>
                <video
                    ref={(playerRef as unknown) as MutableRefObject<HTMLVideoElement>}
                    className="plyr"
                    {...rest}
                />
            </div>
        </PlyrContext.Provider>
    )
})

export { PlyrContext }

export default Plyr
