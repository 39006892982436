import React, { FC, useEffect, useState } from 'react'

import { Popover, Avatar, notification } from 'antd'
import { ReactComponent as NotificationIcon } from 'commons/assets/icons/outline/notification.svg'
import { Title, ButtonV2 } from 'commons/components'
import useNotifications from 'commons/hooks/useNotification'
import { useTranslation } from 'react-i18next'
import { Close } from 'commons/assets/icons'
import {
    NotificationWrapper,
    NotificationCounter,
    NotificationContainer,
    NotificationTitle
} from './styles'
import NotificationList from './NotificationList'

const audio = new Audio('notifcation.mp3')
let animationTitle = 0

const AlertAnimation = (enabled: boolean) => {
    window.clearInterval(animationTitle)

    document.title = document.title.replace(/^\*\*\*/i, '')
    if (enabled) {
        audio.play()
        let t = true
        animationTitle = window.setInterval(() => {
            if (t) {
                document.title = `*** ${document.title}`
            } else {
                document.title = document.title.replace(/^\*\*\*/i, '')
            }
            t = !t
        }, 1000)
    }
}

window.addEventListener('focus', () => AlertAnimation(false))

export const NotificationsAlert: FC = () => {
    const { newNotification, readNotifications } = useNotifications()

    useEffect(() => {
        if (!newNotification) return

        AlertAnimation(true)

        notification.open({
            className: 'new-notification',
            placement: 'bottomLeft',
            message: newNotification.title,
            onClick: () => {
                readNotifications(newNotification.id)
                window.open(newNotification.link)
            },
            description: newNotification.message,
            icon: <Avatar size={35} src={newNotification.icon?.url} />
        })
    }, [newNotification])

    return null
}

const Notifications: FC = () => {
    const { t } = useTranslation('commons')
    const [visible, setVisible] = useState(false)

    const { notifications, readNotifications, viewNotifications, unreadCount } = useNotifications()

    const handleVisible = (newVisible: boolean) => {
        AlertAnimation(false)
        viewNotifications()

        setVisible(newVisible)
    }

    const NotificationsPopup = (
        <NotificationContainer className="box-main">
            <NotificationTitle>
                <Title fontSize={14} regular marginBottom={0}>
                    {t('notifications')}
                </Title>
                <ButtonV2 type="text" onClick={() => setVisible(false)}>
                    <Close />
                </ButtonV2>
            </NotificationTitle>

            <NotificationList notifications={notifications} onClick={readNotifications} />
        </NotificationContainer>
    )

    return (
        <NotificationWrapper>
            <Popover
                overlayClassName="notifications"
                placement="rightBottom"
                content={NotificationsPopup}
                trigger="click"
                align={{ offset: [50, 0] }}
                onVisibleChange={handleVisible}
                visible={visible}
            >
                {unreadCount ? (
                    <NotificationCounter>
                        {unreadCount > 9 ? '9+' : unreadCount}
                    </NotificationCounter>
                ) : null}
                <NotificationIcon />
            </Popover>
        </NotificationWrapper>
    )
}

export default Notifications
