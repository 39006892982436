import { transparentize } from 'polished'
import styled from 'styled-components'

import { ButtonV2 } from 'commons/components'

export const NotificationWrapper = styled.div`
    position: relative;
    width: 20px;
    height: 40px;
    display: flex;
    align-items: center;

    span {
        line-height: 0;
    }
    svg {
        cursor: pointer;
    }
`

export const NotificationCounter = styled.div`
    position: absolute;
    width: 20px;
    height: 18px;
    background: #ff003d;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    right: -8px;
    top: -5px;
`

export const NotificationContainer = styled.div`
    width: 350px;
    max-height: 630px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
`

export const NotificationContainerScroll = styled.div`
    overflow: auto;
    height: 100%;
    padding-right: 5px;
`

interface NotificationItemProps {
    active?: boolean
}

export const NotificationItem = styled.div<NotificationItemProps>`
    background-color: ${({ theme: { v2 }, active }) =>
        active && transparentize(0.9, v2.primaryColor)};
    padding: 12px;
    margin: 10px 0;
    display: flex;
    column-gap: 13px;

    &:hover {
        background-color: ${({ theme: { main } }) => transparentize(0.9, main.nearWhite)};
    }
`

export const NotificationItemContent = styled.div`
    margin: 4px 0 0 10px;
    flex: 1;

    .ant-btn-ghost {
        border-color: ${({ theme }) => theme.main.white};
    }
`

export const NotificationTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .ant-btn-text {
        width: 30px;
        padding: 0;
        border-radius: 100%;
        height: 30px;
    }
`
