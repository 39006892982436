import { useQuery } from '@apollo/client'
import { GET_STAGES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { useEffect, useState } from 'react'

import { TalksData } from '../../types'
import Carousel from '../Carousel'
import { LoadingState, NotFoundRow } from '../Skeleton'
import { TalkTitle } from '../styles'
import TalksWrapper from '../TalksWrapper'
import { useTalksText } from '../texts'
import { TalksProps } from '../types'

const StageCarrousel: React.FC<TalksProps> = () => {
    const { idEvent } = useAuth()
    const { stages: stagesText } = useTalksText()

    const [stageItems, setStageItems] = useState<any>([])

    const { data, fetchMore } = useQuery<TalksData>(GET_STAGES, {
        variables: {
            event: idEvent as string
        },
        pollInterval: 90000,
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            const stageInfo = talks?.map(
                ({ node: { id, title, featureImage, video, status, description } }) => {
                    const _video = video ? video.url : ''
                    const url = status === 'WAITING' ? `home/schedule/${id}` : null

                    return {
                        id,
                        thumbnail: featureImage?.url || '',
                        video: _video,
                        title: description,
                        url,
                        status
                    }
                }
            )

            if (talks) setStageItems([...stageInfo])
        }
    }, [data])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {stagesText}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const { pageInfo } = data.talks
    const getMoreItems = async () => {
        if (pageInfo?.hasNextPage) {
            await fetchMore({
                variables: { after: pageInfo.endCursor }
            })
        }
    }

    if (!stageItems || !stageItems.length) {
        return null
    }

    return (
        <TalksWrapper className="stage-talks">
            <TalkTitle fontSize={20} level={4}>
                {stagesText}
            </TalkTitle>
            {stageItems && stageItems.length > 0 ? (
                <Carousel
                    items={stageItems}
                    hasNext={pageInfo?.hasNextPage}
                    getMoreItems={getMoreItems}
                    type="stage"
                />
            ) : (
                <NotFoundRow />
            )}
        </TalksWrapper>
    )
}

export default StageCarrousel
