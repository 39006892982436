import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Arrow } from 'commons/assets/icons/chevron-left.svg'

import { Text } from 'commons/components'

import { Notification } from 'commons/gql/models/notification'
import NotificationList from 'routing/Main/Notifications/NotificationList'
import { IconWrapper, MenuHeader, Drawer } from '../styles'

interface NotificationDrawerProps {
    open: boolean
    handleClose: () => void
    notifications: Notification[]
    readNotifications: (id?: string) => void
}

const NotificationDrawer = (props: NotificationDrawerProps) => {
    const { open, handleClose, notifications, readNotifications } = props

    return (
        <Drawer visible={open} title="" placement="left" className="mobile-menu">
            <MenuHeader>
                <IconWrapper onClick={handleClose}>
                    <Arrow />
                </IconWrapper>
                <Text fontSize={18} bold>
                    Notificações
                </Text>
            </MenuHeader>
            <div className="mobile-menu-body" style={{ paddingTop: 20 }}>
                <NotificationList notifications={notifications} onClick={readNotifications} />
            </div>
        </Drawer>
    )
}

export default NotificationDrawer
