import { useQuery } from '@apollo/client'
import { GET_ITEMS_VALIDATIONS } from 'commons/gql/queries/menuValidations'
import { useAuth } from 'commons/providers/AuthProvider'

interface MenuValidationData {
    hasQuestions: boolean
    event: {
        viralCampaign?: {
            id: string
        }
    }
    talkTags: string[]
}

export const useMenuValidations = () => {
    const { idEvent } = useAuth()

    const { data } = useQuery<MenuValidationData>(GET_ITEMS_VALIDATIONS, {
        fetchPolicy: 'no-cache',
        variables: {
            event: idEvent
        }
    })

    if (!data) return null

    return {
        showFaq: data.hasQuestions,
        showViralCampaigns: !!data?.event?.viralCampaign,
        showTalkTags: data?.talkTags.length > 0
    }
}
