import { gql } from '@apollo/client'
import {
    EVENT_SPONSOR_LOGO,
    EVENT_SPONSOR_LINK,
    EVENT_SPONSOR_DETAIL
} from 'commons/gql/fragments/brand'
import { EVENT_SPEAKERS, LIVE_SPEAKERS } from 'commons/gql/fragments/speaker'
import { TALKS } from 'commons/gql/fragments/talks'

export const GET_TALKS_SIMPLE = gql`
    query GetTalks(
        $id: ObjectID
        $stage: ObjectID
        $speaker: ObjectID
        $category: ObjectID
        $user: ObjectID
        $favorites: Boolean
        $recommended: Boolean
        $first: Int
        $timezoneOffset: Int
        $after: Cursor
        $startDate: Filter
        $hostType: TalkHostType
    ) {
        talks(
            filter: {
                type: LIVE
                event: $id
                favorites: $favorites
                category: $category
                startDate: $startDate
                user: $user
                recommended: $recommended
                hostType: $hostType
                stage: $stage
                speaker: $speaker
            }
            first: $first
            after: $after
        ) {
            metadata {
                days(timezoneOffset: $timezoneOffset)
            }
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    title
                    startDate
                    endDate
                    status
                    description
                    hiddenInHome
                    # @todo separar
                    isFavorite
                    video {
                        url
                    }
                    categories {
                        edges {
                            node {
                                id
                                name
                                type
                            }
                        }
                    }
                    brands(orderBy: { priority: DESC, name: ASC }) {
                        edges {
                            node {
                                logo {
                                    url
                                }
                                id
                            }
                        }
                    }
                    stage {
                        title
                    }
                    hostType
                    location {
                        name
                    }
                    speakers(orderBy: { priority: DESC, name: ASC }) {
                        edges {
                            node {
                                id
                                image {
                                    url
                                }
                                name
                                company {
                                    name
                                }
                                office
                            }
                        }
                    }
                    sponsorTmp {
                        logo {
                            url
                        }
                        id
                    }
                }
            }
        }
    }
`

export const GET_TALKS_LAST = gql`
    query GetTalksLast($id: ObjectID) {
        talks(filter: { type: LIVE, event: $id }, orderBy: { startDate: DESC }, first: 1) {
            edges {
                node {
                    id
                    startDate
                }
            }
        }
    }
`

export const GET_TALKS = gql`
    query GetTalks($id: ObjectID) {
        talks(filter: { type: LIVE, event: $id }) {
            edges {
                node {
                    id
                    title
                    startDate
                    image {
                        id
                        url
                    }
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_ONDEMAND = gql`
    query GetOnDemand($event: ObjectID, $favorites: Boolean, $category: ObjectID) {
        talks(
            filter: { type: ONDEMAND, event: $event, favorites: $favorites, category: $category }
            orderBy: { priority: DESC, title: ASC }
        ) {
            edges {
                node {
                    id
                    title
                    startDate
                    image {
                        id
                        url
                    }
                    isFavorite
                    categories {
                        edges {
                            node {
                                id
                                name
                                type
                            }
                        }
                    }
                }
            }
        }
    }
`
export const GET_STAGES = gql`
    query GetStages($event: ObjectID, $first: Int, $after: Cursor) {
        talks(
            filter: { type: STAGE, event: $event }
            orderBy: { priority: DESC, title: ASC }
            first: $first
            after: $after
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    title
                    description
                    featureImage {
                        id
                        url
                    }
                    image {
                        id
                        url
                    }
                    video {
                        url
                    }
                    status
                }
            }
        }
    }
`

export const GET_EDITIONS_TALKS = gql`
    query GetEditionsTalks($event: ObjectID, $first: Int) {
        talks(
            filter: { type: ONDEMAND, event: $event }
            first: $first
            orderBy: { reports: { views: DESC } }
        ) {
            edges {
                node {
                    id
                    title
                    description
                    featureImage {
                        id
                        url
                    }
                    image {
                        id
                        url
                    }
                    video {
                        url
                    }
                    status
                }
            }
        }
    }
`

export const GET_SPONSORS_AND_SPEAKERS = gql`
    query GetSponsorsAndSpeakers($id: ObjectID) {
        event(id: $id) {
            id
            name
            logo {
                url
            }
            endDate
            startDate
            description
            countRegisteredUsers
            showNumberOfRegistrations
            ...EventSponsorsLogo
            ...EventSponsorsLink
            speakers {
                totalCount
                edges {
                    node {
                        id
                        ...EventSpeakers
                    }
                }
            }
            nextTalks {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        endDate
                        liveStartDate
                        liveEndDate
                        currentLiveTime
                        status
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        video {
                            url
                        }
                        speakers {
                            totalCount
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
            previousTalks {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        liveStartDate
                        liveEndDate
                        status
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        video {
                            url
                        }
                        speakers {
                            totalCount
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
            liveTalks(filter: { hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        endDate
                        liveStartDate
                        liveEndDate
                        currentLiveTime
                        status
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        video {
                            url
                        }
                        speakers {
                            totalCount
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
            ...Talks
        }
    }
    ${EVENT_SPONSOR_LOGO}
    ${EVENT_SPONSOR_LINK}
    ${EVENT_SPEAKERS}
    ${TALKS}
`

export const GET_EVENT_TALKS = gql`
    query GetEventTalks($id: ObjectID, $hostType: TalkHostType) {
        event(id: $id) {
            logo {
                url
            }
            liveTalks(filter: { type: LIVE, hiddenInHome: false, hostType: $hostType }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        endDate
                        liveStartDate
                        liveEndDate
                        currentLiveTime
                        status
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        video {
                            url
                        }
                    }
                }
            }
            nextTalks(filter: { type: LIVE, hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        liveStartDate
                        liveEndDate
                        status
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        video {
                            url
                        }
                    }
                }
            }
            previousTalks(filter: { hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        liveStartDate
                        liveEndDate
                        status
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        video {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const GET_LIVE_AND_NEXT_TALKS = gql`
    query GetLiveAndNextTalks($id: ObjectID) {
        event(id: $id) {
            logo {
                url
            }
            liveTalks(filter: { type: LIVE, hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        endDate
                        liveStartDate
                        liveEndDate
                        currentLiveTime
                        status
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        video {
                            url
                        }
                    }
                }
            }
            nextTalks(filter: { type: LIVE, hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        liveStartDate
                        liveEndDate
                        status
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        video {
                            url
                        }
                    }
                }
            }
        }
    }
`
export const GET_LIVE_TALKS = gql`
    query GetLiveTalks($id: ObjectID) {
        event(id: $id) {
            logo {
                url
            }
            liveTalks(filter: { type: LIVE, hiddenInHome: false }) {
                edges {
                    node {
                        id
                        title
                        duration
                        isLive
                        viewers
                        startDate
                        endDate
                        liveStartDate
                        liveEndDate
                        currentLiveTime
                        status
                        image {
                            url
                        }
                        imageLive {
                            url
                        }
                        categories {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                        video {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const GET_NEXT_INFO = gql`
    query GetNextInfo($id: ObjectID) {
        event(id: $id) {
            logo {
                url
            }
            liveTalks(filter: { type: LIVE, hiddenInHome: false }) {
                totalCount
            }
        }
    }
`

export const GET_NEXT_TALKS = gql`
    query GetNextTalks($id: ObjectID, $first: Int, $after: Cursor, $hostType: TalkHostType) {
        talks(
            filter: {
                event: $id
                type: LIVE
                hiddenInHome: false
                status: WAITING
                hostType: $hostType
            }
            orderBy: { startDate: ASC }
            first: $first
            after: $after
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    title
                    duration
                    isLive
                    viewers
                    startDate
                    liveStartDate
                    liveEndDate
                    status
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_PREVIOUS_INFO = gql`
    query GetNextInfo($id: ObjectID) {
        event(id: $id) {
            logo {
                url
            }
        }
    }
`

export const GET_PREVIOUS_TALKS = gql`
    query GetPreviousTalks($id: ObjectID, $first: Int, $after: Cursor, $hostType: TalkHostType) {
        talks(
            filter: {
                event: $id
                type: LIVE
                hiddenInHome: false
                status: FINISHED
                hostType: $hostType
            }
            orderBy: { startDate: DESC }
            first: $first
            after: $after
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    title
                    duration
                    isLive
                    viewers
                    startDate
                    liveStartDate
                    liveEndDate
                    status
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_RECOMMENDED_TALKS = gql`
    query GetRecommendedTalks($id: ObjectID, $first: Int, $after: Cursor) {
        talks(
            filter: { event: $id, hiddenInHome: false, recommended: true }
            orderBy: { startDate: ASC }
            first: $first
            after: $after
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    title
                    duration
                    isLive
                    viewers
                    startDate
                    liveStartDate
                    liveEndDate
                    status
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_ONDEMAND_TALKS = gql`
    query GetOnDemandTalks($id: ObjectID, $first: Int, $after: Cursor) {
        talks(
            filter: { event: $id, type: ONDEMAND, hiddenInHome: false }
            orderBy: { priority: DESC, title: ASC }
            first: $first
            after: $after
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    title
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_SPONSOR_TALKS = gql`
    query GetSponsorsTalks($event: ObjectID, $brand: ObjectID) {
        talks(filter: { type: LIVE, event: $event, brand: $brand }) {
            edges {
                node {
                    id
                    title
                    duration
                    isLive
                    viewers
                    startDate
                    liveStartDate
                    liveEndDate
                    status
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_TALK_CATEGORIES = gql`
    query GetTalkCategories($event: ObjectID, $type: CategoryType) {
        categories(filter: { type: $type, event: $event }) {
            edges {
                node {
                    id
                    name
                    description
                    type
                    talksTotalCount
                }
            }
        }
    }
`

export const GET_TALK_BY_CATEGORIES = gql`
    query GetTalkByCategories($event: ObjectID, $category: ObjectID) {
        talks(filter: { event: $event, category: $category }) {
            edges {
                node {
                    id
                    title
                    duration
                    isLive
                    viewers
                    startDate
                    liveStartDate
                    liveEndDate
                    status
                    categories {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                    }
                }
            }
        }
    }
`

export const GET_TALK_BRANDS = gql`
    query GetTalkBrands($id: ObjectID) {
        talk(id: $id) {
            brands {
                edges {
                    node {
                        id
                        name
                        description
                        talkStand {
                            url
                            type
                        }
                        logo {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const GET_TALK = gql`
    query GetTalk($id: ObjectID) {
        talk(id: $id) {
            isLive
            iframe {
                name
                url
            }
            title
            startDate
            liveStartDate
            liveEndDate
            currentLiveTime
            status
            duration
            description
            image {
                url
            }
            event {
                showNumberOfRegistrations
                ...Talks
                ...EventSponsorsDetail
            }
            brands {
                edges {
                    node {
                        id
                        name
                        description
                        talkStand {
                            url
                            type
                        }
                        logo {
                            url
                        }
                    }
                }
            }
            video {
                source
            }
            speakers {
                edges {
                    node {
                        ...LiveSpeakers
                    }
                }
            }
        }
    }
    ${LIVE_SPEAKERS}
    ${TALKS}
    ${EVENT_SPONSOR_DETAIL}
`

export const GET_TALK_TITLE = gql`
    query GetTalkTitle($id: ObjectID) {
        talk(id: $id) {
            title
        }
    }
`
