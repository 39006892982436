import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'
import { ROUTES } from 'commons/constants'
import { ButtonV2 } from 'commons/components'

import { GET_EVENT_VIRAL_CAMPAIGN } from '../gql/queries'
import { StyledGift, StyledText, StyledLink, Wrapper } from './styles'
import { EventViralCampaignData } from '../types'

interface ReferAndWinProps {
    selected?: boolean
}

const ReferAndWinButton = (props: ReferAndWinProps) => {
    const { idEvent } = useAuth()

    const { data, loading } = useQuery<EventViralCampaignData, ById>(GET_EVENT_VIRAL_CAMPAIGN, {
        variables: {
            id: idEvent!
        }
    })

    const { selected = false } = props
    const { t } = useTranslation('commons')

    const color = selected ? 'highlight' : 'accent'

    if (!data || !data.event?.viralCampaign || loading) return null
    return (
        <Wrapper>
            <ButtonV2
                isLink
                href={ROUTES.referral}
                id="btn-refer-and-win"
                className="referral-btn"
                preDefinedcss={StyledLink}
            >
                <StyledText>{t('commons:topbar.refer-and-win')}</StyledText>
                <StyledGift width="15" height="15" color={color} />
            </ButtonV2>
        </Wrapper>
    )
}

export default ReferAndWinButton
