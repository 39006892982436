import styled from 'styled-components'
import Button from 'commons/components/Button'

export const StyledChevronRight = styled.div`
    color: white;
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    border-right: 1px solid white;
    border-top: 1px solid white;
    transform: rotate(45deg);
`

export const StyledTitle = styled.h3`
    font-size: 20px;
`

export const StyledContainer = styled.div`
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
        margin-top: 20px;
    }
`

export const StyledTopContainer = styled.div`
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    margin-bottom: 20px;
`

export const StyledButton = styled(Button)`
    border-radius: 79px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    flex-basis: 245px;
    & > span {
        margin-right: 20px;
    }
    @media screen and (max-width: 767px) {
        flex-basis: 150px;
    }
`

export const StyledButtonText = styled.span`
    color: white;
`

export const StyledListContainer = styled.div<{ justify?: string }>`
    border-radius: 20px;
    justify-content: ${({ justify }) => justify || 'flex-start'};
    display: flex;
    flex-wrap: wrap;
    margin: calc(20px / -2) calc(20px / -2);
    & > * {
        margin: calc(20px / 2) calc(20px / 2);
    }
`
