import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    Dispatch,
    SetStateAction,
    useCallback
} from 'react'
import {
    getToken,
    localSetToken,
    clear,
    localSetIdEvent,
    getIdEvent,
    getIdUser,
    localSetIdUser
} from 'commons/services/auth'

interface Props {
    children: React.ReactNode
}

interface IAuthContext {
    token?: string | null
    setToken: Dispatch<SetStateAction<string | null>>
    idEvent?: string | null
    idUser?: string | null
    setIdUser: Dispatch<SetStateAction<string | null>>
    setIdEvent: Dispatch<SetStateAction<string | null>>
    logout?: () => void
}

const AuthContext = createContext<IAuthContext>({
    setToken: () => null,
    setIdEvent: () => null,
    setIdUser: () => null
})

const AuthProvider = (props: Props) => {
    const { children } = props
    const [idUser, setIdUser] = useState(() => {
        const localIdUser = getIdUser()

        if (localIdUser) {
            return localIdUser
        }
        return null
    })
    const [token, setToken] = useState(() => {
        const localToken = getToken()

        if (localToken) {
            return localToken
        }

        return null
    })
    const [idEvent, setIdEvent] = useState(() => {
        const localIdEvent = getIdEvent()

        if (localIdEvent) {
            return localIdEvent
        }

        return null
    })

    const logout = useCallback(() => {
        clear()
        setToken(null)
        setIdEvent(null)
        setIdUser(null)
    }, [])

    useEffect(() => {
        localSetToken(token)
        localSetIdEvent(idEvent)
        localSetIdUser(idUser)
    }, [token, idEvent, idUser])

    return (
        <AuthContext.Provider
            value={{ token, setToken, logout, idEvent, setIdEvent, idUser, setIdUser }}
        >
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('There is no context in auth hook.')
    }

    return context
}

export { useAuth }

export default AuthProvider
