import styled from 'styled-components'
import { Typography } from 'antd'

import { StyledToolTip } from 'commons/components/Tooltip/styles'
import { StyledTypographyTypes } from '../types'

import { stylesBase, styleFont } from '../styles'

const { Paragraph } = Typography

export const StyledParagraphWrapper = styled.div<StyledTypographyTypes>`
    display: contents;

    > * {
        ${stylesBase}
        ${styleFont}

        &:hover > ${StyledToolTip} {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s;
        }
    }
    span {
        ${stylesBase}
        ${styleFont}
    }
`
export const StyledParagraph = styled(Paragraph)<StyledTypographyTypes>`
    &.ant-typography {
        ${stylesBase}
        ${styleFont}
    }
    span {
        ${stylesBase}
        ${styleFont}
    }
`
