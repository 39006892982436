import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col } from 'antd'

import { SponsorCard } from 'commons/components'
import { useModal } from 'commons/components/modal/modalContext'
import useViewport from 'commons/hooks/useViewport'

import { ListBrandsProps } from '../types'
import { useGrid } from '../utils'
import Modal from '../Modal'
import Card from '../Card'

const BrandGrid: FC<ListBrandsProps> = props => {
    const { data, type, light, children, sponsorLink, sponsorPage } = props
    const { modal, setModal } = useModal()
    const history = useHistory()
    const [visible, setVisible] = useState<boolean>(false)
    const { vw } = useViewport()

    function navigateTo(node: any) {
        const { url, type, standUrl } = node
        const { location } = history

        if (url) {
            if (location.pathname === '/home/sponsors') {
                history.push(url)
            }
        } else {
            if (type === 'email') {
                window.open(`mailto:${standUrl}`)
            }
            if (type === 'link') {
                window.open(standUrl)
            }
        }
    }

    const handleClick = (brand: any) => {
        const { node } = brand
        if (type === 'PP') {
            setVisible(!visible)
            return
        }

        navigateTo(node)
    }

    const info = useGrid(type)

    return (
        <div style={{ width: '100%' }}>
            {children}
            <Row
                className="mobile-gutter"
                gutter={vw > 767 ? [32, 32] : [16, 16]}
                style={{ width: '100%' }}
            >
                {data.map((item: any) => (
                    <Card
                        key={item.node.id}
                        type={type}
                        {...item.node}
                        sponsorPage={sponsorPage}
                        sponsorLink={sponsorLink}
                    />
                ))}
            </Row>
        </div>
    )
}

export default BrandGrid
