import React from 'react'
import { useQuery } from '@apollo/client'

import { useAuth } from 'commons/providers/AuthProvider'
import { ById } from 'commons/gql/types/variables'
import { GET_BANNERS } from 'commons/gql/queries/banners'
import { pages } from 'commons/gql/models/banners'
import Talk from 'commons/gql/models/talk'
import { QueryBannersData } from '../types'

import Carousel from './Carousel'

interface BannersProps {
    page: pages
    talk?: string
    bordered?: boolean
}

interface Variables extends ById {
    event: string
    page: Array<string>
    talks?: string[] | undefined
}

const Banners: React.FC<BannersProps> = props => {
    const { idEvent } = useAuth()
    const { page, talk, children, bordered } = props

    const { data: bannersData, loading: bannersLoading } = useQuery<QueryBannersData, Variables>(
        GET_BANNERS,
        {
            variables: {
                event: idEvent as string,
                talks: talk ? [talk] : undefined,
                page: [page]
            },
            fetchPolicy: 'no-cache'
        }
    )

    if (bannersLoading) return null

    const banners = bannersData && bannersData.banners.edges.map(({ node }) => node)

    return banners?.length ? (
        <Carousel bordered={bordered} banners={banners}>
            {children}
        </Carousel>
    ) : null
}

export default Banners
