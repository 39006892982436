import React from 'react'
import { StyledEmpty } from './styles'

interface Props {
    image?: React.ReactNode
    description?: string
}

const Empty = (props: Props) => {
    const { description, image } = props
    return (
        <StyledEmpty
            image={image || StyledEmpty.PRESENTED_IMAGE_SIMPLE}
            description={description || 'Nenhum resultado'}
        />
    )
}

export default Empty
