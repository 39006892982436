import styled, { css } from 'styled-components'

const ResponsiveCss = css`
    @media (max-width: 1023px) {
        font-size: 12px;

        & > span {
            font-size: 10px;
        }
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
        font-size: 14px;

        & > span {
            font-size: 14px;
        }
    }
`

export const StyledIsLive = styled.div<{ initial?: boolean; behind?: boolean }>`
    position: ${({ initial }) => (initial ? 'initial' : 'absolute')};
    transition: background-color 0.2s;
    top: 40px;
    transform: translate(0, -50%);
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
    font-size: 14px;
    font-weight: 400;
    background-color: ${({ behind }) => (behind ? 'black' : '#FF003D')};
    border-radius: 6px;
    border-radius: 66px;
    color: ${({ theme }) => theme.main.white};
    height: 30px;
    display: flex;
    justify-content: center;

    & > span {
        font-size: 10px;
        margin-right: 8px;
    }

    ${({ initial }) => !initial && ResponsiveCss}

    @media screen and (max-width: 767px) {
        font-size: 14px;
        left: 10px;
        top: 25px;
    }
`
