import { usePermissions } from 'commons/hooks/usePermissions'
import React, { FC, JSXElementConstructor } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import CustomWrapper from './customWrapper'

interface IRoute extends RouteProps {
    title: string
    dynamic?: string
    permission: string | null
    component: JSXElementConstructor<any>
}

const CustomRoute: FC<IRoute> = ({ component: Component, ...rest }) => {
    const permissions = usePermissions()
    // const permissions = ['NETWORKING']
    const isVisible = rest.permission ? permissions.includes(rest.permission as string) : true

    const RenderComponent = (props: any) => (
        <CustomWrapper {...rest}>
            {isVisible ? <Component {...props} /> : <>Não autorizado</>}
        </CustomWrapper>
    )

    return <Route {...rest} render={props => <RenderComponent {...props} />} />
}

export default CustomRoute
