import { gql } from '@apollo/client'

export const ON_NEWOTK_CHANGE = gql`
    subscription onNetworkChange {
        onNetworkChange {
            id
            user {
                id
                name
                avatar {
                    url
                }
            }
            unread
            lastMessage {
                message
            }
        }
    }
`

export const SUBSCRIPTION_GET_CONTACTS = gql`
    subscription onNetworkChange {
        onNetworkChange {
            id
            lastMessage {
                user {
                    id
                    name
                }
                message
                isMe
            }
            user {
                name
                id
            }
        }
    }
`

export const SUBSCRIPTION_GET_MESSAGES = gql`
    subscription onNetworkMessage($user: ObjectID!) {
        onNetworkMessage(user: $user) {
            user {
                name
            }
            message
            isMe
        }
    }
`
export const ON_CHAT_MESSAGE = gql`
    subscription onNetworkMessage($id: ObjectID!) {
        onNetworkMessage(user: $id) {
            user {
                name
            }
            message
        }
    }
`

export const ON_CHAT_NOTIFICATION = gql`
    subscription OnNetworkNotification {
        onNetworkNotification {
            message
        }
    }
`
