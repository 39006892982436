import styled from 'styled-components'
import { StyledToolTip } from 'commons/components/Tooltip/styles'
import { darken, rgba } from 'polished'

interface Props {
    $borderRadius?: number | null | undefined
    $light?: boolean | undefined
    $orientation?: 'horizontal' | 'vertical'
}

export const StyledSpeakerCard = styled.div<Props>`
    width: 170px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;

    &.speaker-card-horizontal {
        flex-direction: row;
        width: 210px !important;
    }

    @media (max-width: 767px) {
        padding: 20px 10px;
        width: ${({ $orientation }) => ($orientation === 'vertical' ? '114px' : '170px')};
    }
`

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.main.primaryBlur};
    z-index: 2;
`

export const StyledSubtitlesContainer = styled.div`
    max-width: 100%;
    width: 100%;
`

export const StyledTitle = styled.div`
    font-size: 14px;
    color: white;
    text-align: center;
    margin-top: 10px;
    z-index: 3;
`

export const StyledSubtitle = styled(StyledTitle)<{ marginTop?: number; bold?: boolean }>`
    font-size: 11px;
    position: relative;
    z-index: 3;
    line-height: 120%;
    letter-spacing: 1.7px;
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}

    ${({ bold }) => bold && `font-weight: 700;`}
  &:hover > ${StyledToolTip} {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.4s;
    }
`
