import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer).attrs({
    toastClassName: 'toast'
})`
    .toast {
        border-radius: 20px;
    }
`
