import React, { useState, useEffect, useRef, useCallback } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useViewport from 'commons/hooks/useViewport'

import { StyledContainer } from './styles'
import { tuple } from '../../utils'

const JustifyTypes = tuple('center', 'end', 'space-around', 'flex-end')

export type Justify = typeof JustifyTypes[number]

interface Dimensions {
    minHeight?: number
    minWidth?: number
}

interface Resolutions {
    desktop?: Dimensions
    mobile?: Dimensions
}

interface Props {
    image?: string | null
    minWidth: number
    resolutions?: Resolutions
    minHeight: number | Array<number>
    className?: string
    onClick?: () => void | null
    justify?: Justify
    style?: React.CSSProperties
    xs?: number
    lg?: number
    clickAble?: boolean
}

const getHeightScale = (minWidth: number, minHeight: number, newWidth: number) => {
    const width = Math.min(minWidth, newWidth)
    const scale = minHeight / width

    return Math.max(minHeight, newWidth * scale)
}

const ScalingContainer: React.FC<Props> = props => {
    const {
        image,
        minHeight,
        minWidth,
        className,
        justify,
        style,
        onClick,
        children,
        resolutions,
        clickAble,
        ...rest
    } = props
    const [height, setHeight] = useState<number>()
    const [width, setWidth] = useState<number>()
    const [newHeight, setNewHeight] = useState<number>()
    const [_newWidth, setNewWidth] = useState<number>()
    const ref = useRef<HTMLDivElement>(null)
    const { vw } = useViewport()

    const checkDevice = useCallback(() => {
        if (vw < 800 && resolutions?.mobile) {
            return [resolutions.mobile.minHeight, resolutions.mobile.minWidth]
        }
        if (resolutions?.desktop) {
            return [resolutions.desktop.minHeight, resolutions.desktop.minWidth]
        }

        return [minHeight, minWidth]
    }, [resolutions])

    useEffect(() => {
        if (ref?.current) {
            const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                for (let i = 0; i < entries.length; i += 1) {
                    const rect = entries[i].contentRect
                    const [deviceMinHeight, deviceMinWidth]: any = checkDevice()
                    setNewHeight(deviceMinHeight)
                    setNewWidth(deviceMinWidth)
                    setHeight(getHeightScale(deviceMinWidth, deviceMinHeight, rect.width || 0))
                }
            })

            setWidth(ref.current.offsetWidth)

            ro.observe(ref?.current)

            return () => {
                if (ref?.current) {
                    ro.unobserve(ref?.current)
                }
            }
        }
    }, [ref, minWidth, minHeight, setHeight, setWidth])

    return (
        <StyledContainer
            ref={ref}
            className={className || ''}
            style={style || {}}
            onClick={() => onClick && onClick()}
            $image={image}
            $minWidth={(_newWidth as number) || minWidth}
            $minHeight={(newHeight as number) || minHeight}
            $height={height}
            $width={width}
            $justify={justify}
            $clickAble={clickAble}
            {...rest}
        >
            {children}
        </StyledContainer>
    )
}

export default ScalingContainer
