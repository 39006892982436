import React, { useState } from 'react'

import { Form, Input } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'
import InputMask from './inputMask'

const CpfCnjpInput: React.FC<IInputType> = ({ name, label, config }) => {
    const [mask, setMask] = useState('999.999.999-99')

    return (
        <Wrapper>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <InputMask
                    mask={mask}
                    onKeyDown={e => {
                        const digit = e.key.replace(/\D/g, '')

                        const value = (e.target as HTMLInputElement).value.replace(/\D/g, '')

                        const size = value.concat(digit).length

                        setMask(size <= 11 ? '999.999.999-99' : '99.999.999/9999-99')
                    }}
                    placeholder={label}
                />
            </Form.Item>
        </Wrapper>
    )
}

export default CpfCnjpInput
