import styled, { css } from 'styled-components'
import { Banners } from 'commons/gql/models/banners'

type StyledBannersProps = Pick<Banners, 'desktop' | 'mobile'> & {
    bordered?: boolean
}

export const BannerImage = styled.div<StyledBannersProps>`
    background-image: url(${({ desktop }) => desktop?.url});
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: ${({ bordered }) => (bordered ? '24px' : '0')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${({ mobile, theme }) =>
        mobile &&
        css`
            @media (max-width: ${theme.sizes.md}) {
                background-image: url(${mobile?.url});
                /* height: 50px; */
                background-size: cover;
            }
        `}

    @media (max-width: 767px) {
        /* height: 50px; */
        background-size: cover;
    }
`
export const Wrapper = styled.div`
    .banner-carousel .slick-prev,
    .banner-carousel .slick-next {
        opacity: 0;
        width: 30px;
        height: 30px;
        padding: 9px 10px 9px 9px;
        backdrop-filter: blur(30px);
        border-radius: 50%;
        z-index: 10;
        background-color: ${({ theme }) => theme.main.nearWhiteOpacity};
        transition: 0.3s all ease;
    }

    &:hover {
        .banner-carousel .slick-prev,
        .banner-carousel .slick-next {
            opacity: 1;
            transition: 0.3s all ease;
        }
    }

    .slick-prev {
        left: 0px;
    }
    .slick-next {
        right: 0px;
    }

    .slider-inner {
        position: relative;
        width: 100%;
        padding-bottom: 10.5%;

        ${({ theme }) =>
            css`
                @media (max-width: ${theme.sizes.sm}) {
                    padding-bottom: 14.2%;
                }
            `}
        .slider-wrapper-image {
            position: absolute;
            inset: 0px;
        }
    }

    ${({ theme }) =>
        css`
            @media (max-width: ${theme.sizes.sm}) {
                .banner-carousel {
                    width: 98%;
                    margin: 0 auto;
                }
            }
        `}
`
