import React, { useState, useRef } from 'react'
import { Banners } from 'commons/gql/models/banners'
import Slider from 'react-slick'

import { ArrowRight, ArrowLeft } from 'commons/assets/icons'

import { BannerImage, Wrapper } from './styles'

type BannerShort = Pick<Banners, 'desktop' | 'mobile' | 'link' | 'id'>

interface BannersProps {
    banners: Array<BannerShort> | null
    bordered?: boolean
}

export const BannersCarousel: React.FC<BannersProps> = props => {
    const { banners, children, bordered = true } = props
    const [play, setPlay] = useState(true)
    const ref = useRef<Slider>(null)

    if (!banners) return null

    const handleMouse = () => {
        if (ref?.current && play) {
            ref.current.slickPause()
            setPlay(!play)
        } else if (ref?.current && !play) {
            ref.current.slickPlay()
            setPlay(!play)
        }
    }

    const handleImageTouch = (id: string) => {
        window.TDA.track('bannerClick', {
            id
        })

        if (ref?.current && !play) {
            ref.current.slickPlay()
            setPlay(!play)
        }
    }

    return (
        <Wrapper onPointerDown={handleMouse}>
            <Slider
                pauseOnHover={false}
                ref={ref}
                className="banner-carousel"
                autoplay
                arrows
                nextArrow={<ArrowRight />}
                prevArrow={<ArrowLeft />}
                adaptiveHeight
                autoplaySpeed={6000}
            >
                {children && children}
                {banners.map(item => (
                    <div className="slider-inner">
                        <a
                            className="slider-wrapper-image"
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            key={item.id}
                            onClick={() => handleImageTouch(item.id)}
                        >
                            <BannerImage
                                bordered={bordered}
                                desktop={item.desktop}
                                mobile={item.mobile}
                            />
                        </a>
                    </div>
                ))}
            </Slider>
        </Wrapper>
    )
}

export default BannersCarousel
