import styled from 'styled-components'

export const Container = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: transparent;

    svg {
        fill: ${({ theme }) => theme.main.white};
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
`

export const Line = styled.div`
    width: 20px;
    height: 2px;
    border-radius: 10px;
    /* background-color: ${({ theme }) => theme.main.white}; */
    & + div {
        margin-top: 4px;
    }
`
