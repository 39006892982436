import { Tooltip, TooltipProps } from 'antd'
import React from 'react'
import { StyledButton } from './styles'

interface Props {
    icon?: React.FC
    padding?: string
    overlay: React.ReactElement
    text?: string
    className?: string
    hideInClick?: boolean
}

const ButtonDropdown: React.FC<Props & TooltipProps> = ({
    icon: Icon,
    padding,
    overlay,
    text,
    ...props
}) => {
    return (
        <Tooltip overlayClassName="button-dropdown" title={overlay} autoAdjustOverflow {...props}>
            <StyledButton padding={padding}>
                {Icon && <Icon />}
                {text}
            </StyledButton>
        </Tooltip>
    )
}

export default ButtonDropdown
