import React, { forwardRef, InputHTMLAttributes } from 'react'
import Loader from 'commons/components/Loader'
import { StyledLabel, StyledInputWrapper, StyledIcon, ErrorWrapper } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    color?: string
    icon?: string
    error?: string
    label?: string
    rounded?: boolean
    loading?: boolean
}

const ChatInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const renderLabel = () => (
        <StyledLabel
            htmlFor={props.label}
            data-testid="label"
            error={props.error}
            color={props.color}
        >
            {props.label}
        </StyledLabel>
    )

    return (
        <StyledInputWrapper className={props.className} rounded={props.rounded} error={props.error}>
            {props.icon && <StyledIcon src={props.icon} alt="" />}
            {props.children}
            {props.label && renderLabel()}
            {props.error && <ErrorWrapper>{props.error}</ErrorWrapper>}
            {props.loading && <Loader size={30} />}
        </StyledInputWrapper>
    )
})

export default ChatInput
