import styled from 'styled-components'
import { Button as AntButton } from 'antd'
import { Link } from 'react-router-dom'

import { baseStyle } from './base'

import { CustomButtonsStyledProps } from './types'

export const StyledButton = styled(AntButton)<CustomButtonsStyledProps>`
    ${props => baseStyle(props)}
    ${props => props?.preDefinedcss}
`

export const StyledLink = styled(Link)<CustomButtonsStyledProps>`
    ${props => baseStyle(props)}
    ${props => props?.preDefinedcss}
`
