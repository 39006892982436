import React, { useRef, useState } from 'react'
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber
} from 'react-phone-number-input'
import Flags from 'react-phone-number-input/flags'
import CountryNames from 'react-phone-number-input/locale/pt-BR.json'
import styled from 'styled-components'

import { Form, Input, InputProps, Select } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

interface TelInputGroupProps extends Omit<InputProps, 'value' | 'onChange'> {
  value?: string
  onChange?: (value: string) => void
}

const ContainerGroup = styled.div`
  width: 100%;
  .ant-select-selector {
    padding: 0 !important;
  }
  .ant-select-selection-item {
    padding: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    svg,
    .anticon {
      max-width: 40px;
    }
    .option-text {
      display: none;
    }
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    svg,
    .anticon {
      max-width: 30px;
      margin-right: 5px;
    }
  }
`

const InputTel = React.forwardRef<any, any>((props, ref) => {
  const _ref = (dom: HTMLInputElement | undefined) => {
    if (dom && typeof ref === 'function') {
      ref(dom)
    }
  }

  return (
    <Input
      value={props.value}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      type="tel"
      autoComplete="tel"
      ref={dom => ref && _ref(dom?.input)}
    />
  )
})

const SelectTel = (props: any) => {
  const container = useRef<HTMLDivElement>(null)
  return (
    <div className="select-country" ref={container}>
      <Select
        value={props.value || ''}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={{ width: '50px' }}
        dropdownMatchSelectWidth={100}
        showSearch={false}
        getPopupContainer={() => container.current || document.body}
      >
        <Select.Option key={'empty'} value={''}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
          </svg>
        </Select.Option>
        {getCountries().map(country => {
          const icon = Flags[country]
          return (
            <Select.Option key={country} value={country}>
              {icon && icon({ title: CountryNames[country] })}{' '}
              <span className="option-text">
                +{getCountryCallingCode(country)}
              </span>
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

const TelInputGroup: React.FC<TelInputGroupProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <ContainerGroup>
      <PhoneInput
        onChange={e => onChange && onChange('' + e)}
        value={value || '+55'}
        onBlur={props.onBlur}
        defaultCountry="BR"
        initialValueFormat="national"
        international
        countrySelectComponent={SelectTel}
        inputComponent={InputTel}
      />
    </ContainerGroup>
  )
}

const TelInput: React.FC<IInputType> = ({ name, label, config }) => {
  return (
    <Wrapper>
      <Form.Item
        name={name}
        initialValue=""
        rules={[
          {
            validator: async (_, value) => {
              if (!config.required || isValidPhoneNumber(value)) {
                return
              }

              throw new Error(`${label} inválido`)
            }
          }
        ]}
      >
        <TelInputGroup placeholder={label} />
      </Form.Item>
    </Wrapper>
  )
}

export default TelInput
