import { Col, Row } from 'antd'
import { FancyList, ChevronRight } from 'commons/assets/icons/'
import { Text } from 'commons/components/'
import { ROUTES } from 'commons/constants'
import { useMenuValidations } from 'commons/hooks/useMenuValidations'
import { usePermissions } from 'commons/hooks/usePermissions'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { MenuAction } from '../styles'

interface GuestListProps {
    handleClose: () => void
}

const GuestList = ({ handleClose }: GuestListProps) => {
    const { push } = useHistory()
    const { t } = useTranslation('commons')

    const permissions = usePermissions()
    const validations = useMenuValidations()

    const handleClick = () => {
        push(ROUTES.guestList)
        handleClose()
    }

    return (
        <>
            {permissions.includes('GUEST_LIST') && validations?.showViralCampaigns && (
                <MenuAction onClick={handleClick}>
                    <Row align="middle" gutter={16}>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <FancyList />
                        </Col>
                        <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text fontSize={16} regular marginBottom={5}>
                                {t('sidebar.guestlist')}
                            </Text>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <ChevronRight height="14" />
                        </Col>
                    </Row>
                </MenuAction>
            )}
        </>
    )
}

export default GuestList
