import { Collection } from 'commons/gql/types/collection'
import { Speaker } from 'commons/gql/models/speaker'

export default function formatListSpeakersHome(speakers: Collection<Speaker>) {
    if (!speakers) return []
    const { edges } = speakers
    const defaultProfile = 'https://tdstorage.s3-sa-east-1.amazonaws.com/avatar/default.jpg'

    return edges.map(({ node: { name, id, image, office, company } }) => ({
        name,
        id,
        office,
        company,
        image: {
            url: image?.url || defaultProfile
        }
    }))
}
