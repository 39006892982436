import React from 'react'
import { Col, Row, Modal } from 'antd'
import { Paragraph, Title } from 'commons/components'
import { useTranslation } from 'react-i18next'

import Actions from 'screens/Sponsors/Actions'
import { BrandModalProps } from 'commons/gql/models/brand'
import { Wrapper, BrandImg } from './styles'

const SponsorModal: React.FC<BrandModalProps> = props => {
    const { t } = useTranslation('sponsors')
    const {
        email,
        site,
        contact,
        logo,
        social,
        aboutUs,
        name,
        actions,
        visible,
        handleVisible
    } = props

    if (!aboutUs && actions?.length === 0) return null

    return (
        <Modal
            className="sponsor-modal"
            visible={visible}
            onCancel={handleVisible}
            footer={null}
            width={500}
        >
            <Row>
                <Col xs={24} md={10}>
                    <Col xs={24}>
                        <BrandImg src={logo?.url} alt={name || 'Company Logo'} />
                    </Col>
                </Col>
            </Row>
            <Row className="mb-20" gutter={[32, 32]}>
                <Col span={24}>
                    <Wrapper className="flex flex-column">
                        {actions && <Actions actions={actions} />}
                    </Wrapper>
                </Col>
            </Row>
            {/* <Row>
                <Col xs={24} md={24}>
                    <Row gutter={[32, 32]}>
                        {social.length > 0 && (
                            <Col xs={24} md={12}>
                                <Wrapper className="flex flex-column mt-24">
                                    <Title align="left" fontSize={18} bold>
                                        {t('social-midia')}
                                    </Title>
                                    <Wrapper className="flex flex-row">
                                        <SponsorMidiaWrapper social={social} />
                                    </Wrapper>
                                </Wrapper>
                            </Col>
                        )}
                        {(site || email || contact) && (
                            <Col xs={24} md={12}>
                                <Wrapper className="flex flex-column mt-24">
                                    <Title align="left" fontSize={18} bold>
                                        {t('contact')}
                                    </Title>
                                    <Wrapper className="flex flex-column">
                                        {site && (
                                            <Paragraph align="left" fontSize={16}>
                                                {site}
                                            </Paragraph>
                                        )}
                                        {email && (
                                            <Paragraph align="left" fontSize={16}>
                                                {email}
                                            </Paragraph>
                                        )}
                                        {contact && (
                                            <Paragraph align="left" fontSize={16}>
                                                {contact}
                                            </Paragraph>
                                        )}
                                    </Wrapper>
                                </Wrapper>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row> */}

            {aboutUs && (
                <>
                    {/* <Separator /> */}
                    <Row gutter={[32, 32]}>
                        <Col xs={24}>
                            <Wrapper className="flex flex-column">
                                <Paragraph align="left" fontSize={18} marginBottom={18} bold>
                                    {t('about')}
                                </Paragraph>
                                <Paragraph align="left" fontSize={16} marginBottom={18}>
                                    {aboutUs}
                                </Paragraph>
                            </Wrapper>
                        </Col>
                    </Row>
                </>
            )}
        </Modal>
    )
}

export default SponsorModal
