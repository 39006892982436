import React, { useState } from 'react'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import createTheme, { AppTheme } from 'commons/styles/createTheme'
import { SkeletonTheme } from 'react-loading-skeleton'

interface Props {
    children: React.ReactNode
}

const target = document.querySelector('#styled-components')

const ThemeProviderComponent = ({ children }: Props) => {
    const [theme] = useState(createTheme(window.TD_THEME))

    return (
        <StyleSheetManager target={target as HTMLElement}>
            <ThemeProvider theme={theme}>
                <SkeletonTheme color={theme.skeleton.bg} highlightColor={theme.skeleton.highlight}>
                    {children}
                </SkeletonTheme>
            </ThemeProvider>
        </StyleSheetManager>
    )
}

export default ThemeProviderComponent
