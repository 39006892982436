import styled, { css } from 'styled-components'
import RoundedContainer from 'commons/components/RoundedContainer'

const DisabledCss = css`
    filter: brightness(78%);
`

export const StyledButton = styled(RoundedContainer)<{
    disabled?: boolean
}>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    border: 0;
    &:hover {
        filter: brightness(90%);
    }
    &:active {
        filter: brightness(78%);
        transition: 0s;
    }
    ${({ disabled }) => disabled && DisabledCss}
`
