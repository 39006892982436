import React, { useState, useEffect } from 'react'
import { format, parseISO } from 'date-fns'
import { useQuery } from '@apollo/client'

import { GET_PREVIOUS_INFO, GET_RECOMMENDED_TALKS } from 'commons/gql/queries/talks'
import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'
import { EventQueryData } from '../../types'

import { LoadingState, NotFoundRow } from '../Skeleton'
import Carousel from '../Carousel'
import { useTalksText } from '../texts'
import TalksWrapper from '../TalksWrapper'
import { TalkTitle } from '../styles'

const RecommendedTalks = () => {
    const { idEvent } = useAuth()
    const { recommendedTalks } = useTalksText()
    const [talksItems, setTalksItems] = useState<any>([])

    const { data: dataInfo } = useQuery<EventQueryData, ById>(GET_PREVIOUS_INFO, {
        variables: {
            id: idEvent as string
        },
        pollInterval: 90000,
        fetchPolicy: 'network-only'
    })

    const { data, fetchMore } = useQuery<EventQueryData, ById>(GET_RECOMMENDED_TALKS, {
        variables: {
            id: idEvent as string,
            first: 10
        },
        pollInterval: 90000,
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            const talkInfos = talks?.map(
                ({
                    node: {
                        id,
                        title,
                        startDate,
                        categories: { edges: categoryEdges },
                        image
                    }
                }) => {
                    const parsedDate = parseISO(startDate)
                    const hour = startDate ? format(parsedDate, 'dd/MM HH:mm') : ''
                    const trail = categoryEdges.length ? categoryEdges[0].node.name : ''

                    return {
                        id,
                        thumbnail: image ? image.url : '',
                        hour,
                        subtitle: trail,
                        title
                    }
                }
            )

            if (talks) setTalksItems([...talkInfos])
        }
    }, [data])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {recommendedTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = dataInfo?.event?.logo?.url
    const { pageInfo } = data.talks

    const getMoreItems = async () => {
        if (pageInfo?.hasNextPage) {
            await fetchMore({
                variables: { after: pageInfo.endCursor }
            })
        }
    }

    if (talksItems && !talksItems.length) {
        return null
    }

    return (
        <TalksWrapper className="recommend-talks">
            <TalkTitle fontSize={20} level={4}>
                {recommendedTalks}
            </TalkTitle>
            {talksItems && talksItems.length ? (
                <Carousel
                    items={talksItems}
                    defaultThumbnail={defaultThumbnail}
                    hasNext={pageInfo?.hasNextPage}
                    getMoreItems={getMoreItems}
                />
            ) : (
                <NotFoundRow />
            )}
        </TalksWrapper>
    )
}

export default RecommendedTalks
