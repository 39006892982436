import { createGlobalStyle, css } from 'styled-components'
import { lighten, rgba } from 'polished'
import { BoxTheme } from './createTheme'

export const createBoxStyle = (type: string, box: BoxTheme) => css`
    ${({ theme: { v2 } }) => css`
        background: ${v2.getBgColor(`box.${type}.bgColor`)} !important;
        color: ${box.textColor} !important;
        /* border-radius: ${box.borderRadius}px; */

        .box-${type} {
            background: ${lighten(0.1, v2.getTextColor(`box.${type}.bgColor`))} !important;
        }

        &.box-hover {
            :hover {
                background: ${lighten(0.2, v2.getTextColor(`box.${type}.bgColor`))} !important;
            }
        }

        .box-bgColor {
            background: ${v2.getBgColor(`box.${type}.bgColor`)} !important;
            color: ${box.textColor} !important;
        }

        img.logo {
            filter: ${v2.getLogoColor(`box.${type}.logoColor`)};
        }

        .ant-typography {
            color: ${box.textColor} !important;
        }
        .ant-btn,
        .ant-btn-primary,
        .box-btn {
            background: ${v2.getBgColor(`box.${type}.button.bgColor`)} !important;
            border-color: transparent !important;

            border-radius: ${box.borderRadius}px;
            transform: scale(1);
            border: none;
            transition: all ${box.button.hover.transition}s ease-in-out !important;

            span,
            div,
            .ant-typography {
                color: ${box.button.textColor} !important;
            }

            svg * {
                fill: ${box.button.textColor} !important;
            }

            &:hover {
                color: ${box.button.hover.textColor} !important;
                border: none;
                transform: scale(1.05);
                background: ${v2.getBgColor(`box.${type}.button.hover.bgColor`)} !important;
                border-radius: ${box.borderRadius}px;

                span,
                div,
                .ant-typography {
                    color: ${box.button.hover.textColor} !important;
                }

                svg * {
                    fill: ${box.button.hover.textColor} !important;
                }
            }
        }

        .ant-btn-ghost,
        .ant-btn-background-ghost {
            background: transparent !important;
            color: ${v2.getTextColor(`box.${type}.button.bgColor`)} !important;
            border-color: ${v2.getTextColor(`box.${type}.button.bgColor`)} !important;
            border-width: 1px;
            border-style: solid;
            transition: all ${box.button.hover.transition}s ease-in-out !important;

            span,
            div {
                color: ${v2.getTextColor(`box.${type}.button.bgColor`)} !important;
            }
            svg * {
                fill: ${v2.getTextColor(`box.${type}.button.bgColor`)} !important;
            }

            &:hover {
                color: ${box.button.textColor} !important;
                border-color: transparent !important;
                background: ${v2.getBgColor(`box.${type}.button.bgColor`)} !important;

                span,
                div {
                    color: ${box.button.textColor} !important;
                }
                svg * {
                    fill: ${box.button.textColor};
                }
            }
        }
    `}
`

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${({ theme: { v2 } }) => v2.highlight};
            border-radius: 10px;
        }
    }

    body {
        background: ${({ theme: { v2 } }) => v2.bgColor};
        color: ${({ theme: { v2 } }) => v2.textColor};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;

        scroll-behavior: smooth;
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${({ theme: { v2 } }) => v2.highlight};
            border-radius: 10px;
        }
    }

    .ant-select-dropdown{
        color: ${({ theme: { v2 } }) => v2.menu.textColor};
        background-color: ${({ theme: { v2 } }) => v2.menu.bgColor};
        border-radius: 20px;
        backdrop-filter: blur(30px);

        .ant-select-item{
            color: ${({ theme: { v2 } }) => v2.menu.textColor};
            border-radius: 20px;
        }
        .ant-select-item-option-active{
            color: ${({ theme: { v2 } }) => v2.menu.hover.textColor};
            background-color: ${({ theme: { v2 } }) => v2.menu.hover.bgColor};
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: ${({ theme: { v2 } }) => v2.menu.active.bgColor};
        color: ${({ theme: { v2 } }) => v2.menu.active.textColor};
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item{
        line-height: 40px
    }

    body, input, button {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
    }

    .ant-typography{
        font-family: 'Roboto';
        color: ${({ theme: { v2 } }) => v2.textColor};
        line-height: 120%;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 700;
        color: ${({ theme: { v2 } }) => v2.textColor};

        &.ant-typography{
            font-weight: 700;
            color: ${({ theme: { v2 } }) => v2.textColor};
        }
    }

    .mb-20{
        margin-bottom: 20px;
    }

    .chat-textarea {
        > div {
            > div {
                border: none !important;
            }
        }
        textarea::placeholder{
            color: ${({ theme }) => theme.main.white};
            opacity: 0.7;
        }
    }

    .j-overlay-content {
        background: ${({ theme: { v2 } }) => v2.bgColor} !important;
        border-radius: 10px;
        box-shadow: none;
        opacity: 1;
        .juicer-feed{
            ${({ theme: { v2 } }) => createBoxStyle('main', v2.box.main)}
        }

        .j-meta a {
            color: ${({ theme: { v2 } }) => v2.box.main.textColor} !important;
            &:hover {
                color: ${({ theme: { v2 } }) => v2.highlight} !important;
            }
        }

        p,
        h3,
        time {
            font-family: 'Roboto', sans-serif;
        }
        .juicer-button{
            ${({ theme: { v2 } }) =>
                css`
                    color: ${v2.getTextColor(`box.main.button.bgColor`)} !important;
                    border-color: ${v2.getTextColor(`box.main.button.bgColor`)} !important;
                    border-width: 1px;
                    border-style: solid;
                    transition: all ${v2.box.main.button.hover.transition}s ease-in-out !important;

                    span,
                    div {
                        color: ${v2.getTextColor(`box.main.button.bgColor`)} !important;
                    }
                    svg * {
                        fill: ${v2.getTextColor(`box.main.button.bgColor`)} !important;
                    }

                    &:hover {
                        color: ${v2.box.main.button.textColor} !important;
                        border-color: transparent !important;
                        background: ${v2.getBgColor(`box.main.button.bgColor`)} !important;

                        span,
                        div {
                            color: ${v2.box.main.button.textColor} !important;
                        }
                        svg * {
                            fill: ${v2.box.main.button.textColor};
                        }
                    }
                `}
        }


        p,
        h3,
        time,
        .j-share a:before,
        .juicer-button,
        .j-close,
        .j-next,
        .j-previous {
            color: ${({ theme: { v2 } }) => v2.box.main.textColor} !important;
        }
    }
    .j-bottom, .j-poster {
        display: none !important;
    }
    .j-read-more {
        color: ${({ theme: { v2 } }) => v2.box.main.textColor} !important;
    }
    .j-read-more:hover {
        color: ${({ theme: { v2 } }) => v2.box.main.logoColor} !important;
    }

    @keyframes inAnimation {
        0% {
            opacity: 0;
            margin-top: 50px;
        }
        60% {
            opacity: 1;
            margin-top: 20px;
        }
        100% {
            margin-top: 0
        }
    }

    @keyframes outAnimation {
        20% {
            margin-top: 10px;
        }
        100% {
            margin-top: 50px;
            opacity: 0;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        transition: background-color 5000s ease-in-out 0s;
    }

    .chat-textarea{
        textarea::placeholder{
            color: ${({ theme }) => theme.main.white};
            opacity: 0.7;
        }
    }
    .sc-eCApGN__input{
        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-track {
            background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${({ theme: { v2 } }) => v2.highlight};
            border-radius: 10px;
        }
    }

    button {
        cursor: pointer;
    }

    /* Dots Slider */
    .slick-dots{
        li{
            button::before{
                display: none;
            }
        }
    }

    /* Col Class */
    .ant-col-center{
        text-align: center;
    }

    /* Menu */
    /* .menu-item-side-selected {
        background: transparent;
        box-shadow: inset 3px 0px 0 0px ${({ theme: { main } }) => main.highlight};

        a {
            color: ${({ theme }) => theme.main.white} !important;
        }

        svg {
            path {
                fill: ${({ theme }) => theme.main.white} !important;
                opacity: 1!important;
            }
        }
    } */
    .ant-menu-item-group-title{
        display: none;
    }

    .ant-menu-item .ant-menu-item-icon + span{
        margin-left: 0px;
    }

    .ant-menu {
        background: transparent;
        color: ${({ theme: { v2 } }) => v2.menu.textColor};
        ul.ant-menu-sub {
            background: ${({ theme: { v2 } }) => v2.menu.bgColor};
            box-shadow: 0 3px 6px -4px rgb(0 0 0 / 50%),
                0 6px 16px 0 rgb(0 0 0 / 45%),
                0 9px 28px 8px rgb(0 0 0 / 40%);
        }
    }

    .ant-select-dropdown {
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 50%),
                0 6px 16px 0 rgb(0 0 0 / 45%),
                0 9px 28px 8px rgb(0 0 0 / 40%);
    }

    .ant-menu-submenu-popup > .ant-menu, .ant-dropdown-menu{
        background: ${({ theme }) => theme.v2.primaryColor};
        backdrop-filter: blur(30px);
        border-radius: 20px;
        margin-left: 10px;
    }

    .ant-dropdown-menu, .ant-dropdown-menu-item:hover {
        background: ${({ theme }) => theme.v2.primaryColor};
        color: ${({ theme }) => theme.main.white};
        backdrop-filter: blur(30px);
        border-radius: 20px;
    }

    .ant-dropdown-menu-item {
        color: ${({ theme }) => theme.main.white};
    }

    .ant-dropdown-menu-item:hover{
        font-weight: 700;
    }

    .ant-menu-item:hover{
        color: ${({ theme }) => theme.main.white};
        font-weight: 700;
    }

    /* Usermenu */

    .ant-menu-submenu{
        .ant-menu-item{
            display: flex;
            align-items: center;
        }
    }

    .usermenu-item-group {
        .ant-menu-title-content{
            width: 100%;
            display: flex;
            align-items: center;
        }
        .ant-menu-item {
            padding: 0 36px 0 16px;
            height: 48px;
            width: 250px;
            margin-top: 0;
            transition: none;

            color: ${({ theme: { v2 } }) => v2.menu.textColor};

            svg * {
                opacity: 1;
                fill: ${({ theme: { v2 } }) => v2.menu.textColor};
            }

            svg.arrow-right-icon * {
                fill: none !important;
            }


            &.event-edition-menu {
                margin: 6px;
                padding-left: 10px;
                width: 250px;
                height: 38px;
            }

            &:hover {
                color: ${({ theme: { v2 } }) => v2.menu.hover.textColor};
                background: ${({ theme: { v2 } }) => v2.menu.hover.bgColor};
                border-radius: 10px;
                transition: none;
                font-weight: normal;
                svg * {
                    fill: ${({ theme: { v2 } }) => v2.menu.hover.textColor};
                }
                svg.arrow-right-icon * {
                    stroke: ${({ theme: { v2 } }) => v2.menu.active.highlight};
                }
            }

            :not(:last-child) {
                margin-bottom: 0;
                border-bottom: 1px solid ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};
            }

            &.ant-menu-item-selected {
                border-radius: 10px;

                background: ${({ theme: { v2 } }) => v2.menu.active.bgColor};
                color: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                svg * {
                    fill: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                }
                svg.arrow-right-icon * {
                    stroke: ${({ theme: { v2 } }) => v2.menu.active.highlight};
                }
            }

            &:active {
                background: ${({ theme: { v2 } }) => v2.menu.active.bgColor};
                color: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                svg * {
                    fill: ${({ theme: { v2 } }) => v2.menu.active.textColor};
                }
                svg.arrow-right-icon * {
                    stroke: ${({ theme: { v2 } }) => v2.menu.active.highlight};
                }
            }

            .arrow-right-icon{
                width: 11px;
                height: 11px;
                position: absolute;
                right: 13px;
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${({ theme: { main } }) =>
            main.highlightOpacity} inset !important;
    }

    .ant-slider {
        .ant-slider-handle {
            border-color: ${({ theme: { main } }) => main.highlight} !important;

            &:focus {
                box-shadow: 0 0 0 5px ${({ theme: { main } }) =>
                    rgba(main.highlight, 0.12)} !important;
            }
        }

        .ant-slider-track {
            background-color: ${({ theme: { main } }) => main.highlight} !important;
        }
    }

    .language-selector-item {
        min-height: 22px;
        padding: 5px 7px;
        .ant-select-item-option-content{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
    }
    .ant-popover-arrow{
        border-color: ${({ theme }) => theme.main.primaryBlur} !important;
    }

    .ant-avatar-group-popover{
        .ant-popover-content {
            > .ant-popover-arrow{
                border-right-color: ${({ theme }) => theme.v2.primaryColor};
                border-bottom-color: ${({ theme }) => theme.v2.primaryColor};
                .ant-tooltip-arrow-content{
                    border-right-color: ${({ theme }) => theme.v2.primaryColor};
                    border-right-color: ${({ theme }) => theme.v2.primaryColor};
                    background-color: ${({ theme }) => theme.v2.primaryColor};
                }
            }
        }

        .ant-popover-inner-content{
            background: ${({ theme }) => theme.v2.primaryColor};
            backdrop-filter: blur(30px);
        }
        .ant-popover-inner{
            background-color: transparent;
        }
    }

    .ant-tooltip-inner {
        background: ${({ theme }) => theme.v2.menu.bgColor} !important;
        background-color: ${({ theme }) => theme.v2.menu.bgColor};
        color: ${({ theme }) => theme.main.white};
        backdrop-filter: blur(30px);
        width: max-content;
    }

    .paragraph-tooltip {
        padding: 10px;
        width: max-content;
        max-width: 70vw;
        background: ${({ theme }) => theme.v2.menu.bgColor};
        backdrop-filter: blur(30px);
    }

    .ant-tooltip{
        .ant-popover-content {
            > .ant-popover-arrow{
                > .ant-tooltip-arrow-content{
                    border-right-color: ${({ theme }) => theme.v2.primaryColor};
                    border-right-color: ${({ theme }) => theme.v2.primaryColor};
                    background-color: ${({ theme }) => theme.v2.primaryColor};
                }
            }
        }
        .ant-popover-inner-content{
            background: ${({ theme }) => theme.v2.box.main.bgColor};
            backdrop-filter: blur(30px);
        }
        .ant-popover-inner{
            background-color: transparent;
        }
    }

    .ant-popover-inner-content{
        background: ${({ theme }) => theme.v2.box.main.bgColor};
        backdrop-filter: blur(30px);
    }
    .ant-popover-inner{
        background-color: transparent;
    }

    .ant-tooltip-arrow-content{
        border-right-color: ${({ theme }) => theme.v2.box.main.bgColor};
        border-right-color: ${({ theme }) => theme.v2.box.main.bgColor};
        background-color: ${({ theme }) => theme.v2.box.main.bgColor};
    }

    /* Opacity */
    .opacity-5 {
        opacity: 0.5;
    }
    .ant-select-focused:not(.ant-select-disabled) .ant-select-selector{
        border-color: ${({ theme }) => theme.main.highlight} !important;
        box-shadow: 0 0 0 2px ${({ theme }) => rgba(theme.main.highlight, 0.2)} !important;
    }

    /* .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 46px;
    } */
    /* ant-checkbox-input */
    .ant-checkbox-input,.ant-checkbox-wrapper,.ant-checkbox-inner, .ant-checkbox-checked::after {
        cursor: pointer;
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.main.highlight} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.main.highlight} !important;
        border-color: ${({ theme }) => theme.main.highlight} !important;
    }
    .ant-checkbox:hover .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.main.highlight} !important;
    }

    /* Modal */
    .action-modal, .sponsor-modal {
        top: 50px;
        .ant-modal-content {
            background: transparent;
            box-shadow: none;
            backdrop-filter: blur(20px);
        }
        .ant-modal-close{
            top: -24px;
            border-radius: 100%;
            border: 1px solid ${({ theme }) => theme.main.white};
            background: ${({ theme }) => theme.v2.primaryColor};
            color: ${({ theme }) => theme.main.white};
            backdrop-filter: blur(20px);

            .ant-modal-close-x{
                width: 46px;
                height: 46px;
                line-height: 46px;
                color: ${({ theme }) => theme.v2.textColor};
            }
        }
    }
    .sponsor-modal {
        .ant-modal-content {
            background: ${({ theme }) => theme.v2.primaryColor};
            backdrop-filter: blur(20px);
            box-shadow: none;
        }
    }
    .schedule-modal {
        width: 80% !important;
        .ant-modal-content {
            background: ${({ theme }) => theme.v2.primaryColor};
            backdrop-filter: blur(20px);
        }
        .ant-modal-close{
            top: -50px;
            border-radius: 100%;
            border: 1px solid ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};
            background: ${({ theme }) => theme.v2.primaryColor};
            color: ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};

            .ant-modal-close-x{
                width: 46px;
                height: 46px;
                line-height: 46px;
            }
        }
        img{
            max-width: 100%;
            width: 100%;
        }

        @media (max-width: 767px){
            width: 100% !important;
        }
    }

    .ant-modal-close-x{
        color: ${({ theme }) => theme.v2.textColor};
    }

    /* Input */
    input::placeholder{
        font-size: 14px;
    }

    /* Margins e Paddings */
    .mt-50{
        margin-top: 50px;
    }
    .mt-100 {
        margin-top: 100px;
    }
    .mr-40 {
        padding-right: 40px;
    }

    .mb-50 {
        margin-bottom: 50px;
    }
    .mb-60 {
        margin-bottom: 60px;
    }

    .plr-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    @media (max-width: 767px){
        .mb-mr-0 {
            padding-right: 0px;
        }
        .mb-mt-50{
            margin-top: 50px;
        }
        .mb-mt-130{
            margin-top: 130px;
        }
        .mb-mlr-10{
            padding: 0 10px !important;
        }
        .mb-mlr-20{
            padding: 0 20px !important;
        }

        .mb-pdr-20 {
            padding-right: 20px;
        }

        .mb-pdl-20 {
            padding-left: 20px;
        }

        .mb-mb-20 {
            margin-bottom: 20px;
        }

        .mobile-gutter{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .w-100 {
        width: 100%;
    }

    .header-padding-uw{
        @media (min-width: 2000px) {
        padding: 0 300px !important;
        }

    }

    .align-uw {
        @media (min-width: 2000px) {
            margin: 0 300px;

            .ant-col-lg-3 {
                max-width: 20%;
            }
        }
    }
    .adjust-spaces-w {
            @media (min-width: 2000px) {
                align-self: center;
                margin: 0 300px;
            }
    }

    .sponsor-card-XG,
    .sponsor-card-G,
    .sponsor-card-M {
        padding: 20px;
    }
    .sponsor-card-P, .sponsor-card-PP {
        ${({ theme }) => css`
            @media (max-width: ${theme.sizes.sm}) {
                min-width: 100px !important;
            }
        `}
    }
    .sponsor-card-P,
    .sponsor-card-PP,
    .sponsor-card-Carrousel,
    .sponsor-card-Sidebar {
        padding: 0;
        position: relative;

        .sponsor-card-logo {
            max-width: 120px;
            margin: auto;
        }
    }

    .sponsor-card-PP,
    .sponsor-card-Carrousel,
    .sponsor-card-Sidebar {
        padding: 0;
        position: relative;

        .sponsor-card-logo {
            max-width: 100%;
            margin: auto;
            max-height: 55px;
            padding: 8px;
        }
    }

    .sponsor-card-P {
        .sponsor-card-logo {
            max-height: 70px;
        }
    }

    .sponsor-card-M {
        .sponsor-card-logo {
            max-height: 40px;
        }
    }

    .sponsor-card-G {
        .sponsor-card-logo {
            max-height: 50px;
        }
    }


    .ant-carousel .slick-dots {
        margin-right: 0;
        margin-left: 0;
    }

    @media (max-width: 767px){
        .ant-col-center--mobile{
            text-align: center;
        }

        .row-text-center{
            justify-content: center;
            .ant-typography{
                text-align: center;
            }
        }
    }


    .notifications {
        &.ant-popover {
            padding-left: 0px;

            .ant-popover-arrow {
                display: none;
            }

            .ant-popover-inner-content{
                border-radius: 20px;
            }

            .ant-popover-inner {
                background-color: transparent;
                .ant-popover-inner-content {
                    padding: 0;
                }
            }
        }

        @media(max-width: 767px){
            left: 6px !important;
            top: 81px !important;
        }
    }

    .ant-message-notice-content{
        background-color: ${({ theme: { main } }) => main.primaryBlur};

        .ant-message-custom-content{
            color: ${({ theme: { main } }) => main.white};
        }
        .ant-message-success .anticon {
            color: ${({ theme: { main } }) => main.highlight};
        }
    }


    @media (min-width: 768px){
        .ant-notification {
            .ant-notification-topLeft, .ant-notification-bottomLeft {
                margin-left: 90px;
            }
        }

    }

    .new-notification {
        cursor: pointer;
        padding-left: 10px;
        border-radius: 20px;
        width: 380px;
        background-color: ${({ theme: { main } }) => main.primaryBlur};
        backdrop-filter: blur(30px);
        .ant-notification-notice-description, .ant-notification-notice-message {
            color: ${({ theme: { main } }) => main.white};
        }
        .anticon-close svg {
            fill: ${({ theme: { main } }) => main.white};
        }
    }

    .magic-link-error {
        position: absolute;
        right: 100px;
        top: 74vh;

        .ant-message-notice-content{
            border-radius: 16px;
        }

        @media (max-width: 768px){
            left: 50%;
            width: 80%;
            right: 0;
            transform: translate(-50%, 0%);
        }
    }

    .ant-select-item-group {
        color: rgba(255, 255, 255, 0.5) !important;
    }
    .ant-modal-content {
        border-radius: 20px;
        background-color: #000 !important;

        .ant-modal-body {
            border-radius: 20px;
            ${({ theme: { v2 } }) => createBoxStyle('main', v2.box.main)}
        }
    }


    ${({ theme: { v2 } }) =>
        Object.entries(v2.box).map(
            ([type, box]) => css`
                .box-${type}, .priority-box.box-${type} {
                    ${createBoxStyle(type, box)}
                }
            `
        )}

    .button-dropdown {
        .ant-tooltip-inner {
            background: rgb(49 55 64 / 28%) !important;

            border-radius: 20px;

            padding-left: 10px;
            padding-right: 10px;

            .emoji-icons > svg {
                padding-top: 5px;
            }
        }
    }
`
