import React from 'react'

import { ListBrandsProps } from '../types'
import Header from '../Header'
import Grid from '../Grid'
import Carousel from '../Carousel'
import { Container } from './styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Brands: React.FC<ListBrandsProps> = ({
    type,
    data,
    light,
    titleSize,
    partners,
    title,
    sponsorLink,
    sponsorPage
}) => {
    if (type !== 'Carrousel') {
        return data.length ? (
            <Container>
                <Grid
                    data={data}
                    type={type}
                    light={light}
                    sponsorLink={sponsorLink}
                    sponsorPage={sponsorPage}
                >
                    <Header level={titleSize} partners={partners} type={type} title={title} />
                </Grid>
            </Container>
        ) : null
    }

    return data.length ? (
        <Container>
            <Carousel type="Carrousel" data={data} light={light} partners={partners || false}>
                <Header level="normal" partners={partners} />
            </Carousel>
        </Container>
    ) : null
}

export default Brands
