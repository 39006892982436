import styled, { css } from 'styled-components'

interface Props {
    $image?: string | null | undefined
    className?: string | null | undefined
    $justify?: string | null | undefined
    $minWidth: number | number[] | null | undefined
    $maxWidth?: number | null | undefined
    $minHeight: number | number[] | null | undefined
    $height: number | null | undefined
    $width: number | null | undefined
    $clickAble?: boolean | null | undefined
}

export const Wrapper = styled.div``

export const StyledContainer = styled.div<Props>`
    position: relative;
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    min-width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => ($height ? `${$height}px` : '100%')};
    /* min-width: ${({ $minWidth }) => `${$minWidth}px`}; */
    min-height: ${({ $minHeight }) => `${$minHeight}px`};
    justify-content: ${({ $justify }) => $justify || 'center'};
    ${({ $image }) =>
        $image &&
        css`
            background-image: ${`url("${$image}")`};
        `};
    background-position: center;
    background-size: cover;
    cursor: ${({ $clickAble }) => ($clickAble ? 'pointer' : 'default')};
    transition: transform 0.2s ease;

    .sponsor-card-logo {
        /* max-width: ${({ $width, $minWidth }) => ($width ? `${$width * 0.9}px` : $minWidth)}; */
    }
`
