import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
    .virtualized-list {
        /* margin-top: 20px; */
        /* padding-bottom: 12px; */
        > div {
            position: relative;
        }
        ${({ theme }) => {
            return css`
                overflow-x: auto;
                /* padding-bottom: 12px; */
                ::-webkit-scrollbar {
                    height: 10px;
                }

                ::-webkit-scrollbar-track {
                    background: ${({ theme: { v2 } }) => lighten(0.5, v2.highlight)};
                    border-radius: 10px;
                }

                ::-webkit-scrollbar-thumb {
                    background: ${({ theme: { v2 } }) => v2.highlight};
                    border-radius: 10px;
                }
            `
        }}
    }
`
