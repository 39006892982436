import React, { forwardRef, SelectHTMLAttributes } from 'react'
import Loader from 'commons/components/Loader'
import { StyledSelect, StyledLabel, StyledSelectWrapper, StyledIcon, ErrorWrapper } from './styles'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    color?: string
    icon?: string
    error?: string
    label?: string
    rounded?: boolean
    loading?: boolean
    placeholder?: string
    options: Array<{ name: string; value: string }>
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
    const {
        icon,
        error,
        rounded,
        className,
        options,
        label,
        color,
        value,
        placeholder,
        ...restProps
    } = props

    const renderLabel = () => (
        <StyledLabel htmlFor={label} data-testid="label" error={error} color={color}>
            {label}
        </StyledLabel>
    )

    return (
        <StyledSelectWrapper className={className} rounded={rounded} error={error}>
            {icon && <StyledIcon src={icon} alt="" />}
            <StyledSelect ref={ref} id={props.label} {...restProps} color={props.color}>
                {!value && <option value="">{placeholder}</option>}
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </StyledSelect>
            {props.label && renderLabel()}
            {props.error && <ErrorWrapper>{error}</ErrorWrapper>}
            {props.loading && <Loader size={30} />}
        </StyledSelectWrapper>
    )
})

export default Select
