import { useQuery, useSubscription } from '@apollo/client'
import { ChatMessage } from 'commons/gql/models/network'
import { GET_UNREAD_MESSAGES } from 'commons/gql/queries/network'
import { ON_CHAT_NOTIFICATION } from 'commons/gql/subscriptions/network'
import React, { FC, useEffect } from 'react'
import { client } from 'commons/providers/ApolloProvider'

import { NotificationCounter } from './styles'

const audio = new Audio('notifcation.mp3')
let animationTitle = 0

const AlertAnimation = (enabled: boolean) => {
    window.clearInterval(animationTitle)
    document.title = document.title.replace(/^\* Nova Mensagem - /i, '')
    if (enabled) {
        audio.play()
        let t = true
        animationTitle = window.setInterval(() => {
            if (t) {
                document.title = `* Nova Mensagem - ${document.title}`
            } else {
                document.title = document.title.replace(/^\* Nova Mensagem - /i, '')
            }
            t = !t
        }, 1000)
    }
}

window.addEventListener('focus', () => AlertAnimation(false))

interface NetworkUnreadMessages {
    networkUnreadMessages: number
}

interface OnNetworkNotification {
    onNetworkNotification: ChatMessage
}

export const updateNetworkNotificationCount = (n?: number) => {
    const query = GET_UNREAD_MESSAGES

    if (n) {
        const data = client.readQuery<NetworkUnreadMessages>({ query })

        client.writeQuery({
            query,
            data: {
                networkUnreadMessages: (data?.networkUnreadMessages || 0) + n
            }
        })
    } else {
        client.refetchQueries({
            include: [query]
        })
    }
}

const NetworkNotifications: FC = () => {
    const { data: dataCount } = useQuery<NetworkUnreadMessages>(GET_UNREAD_MESSAGES)
    const { data: dataNotificaiton } = useSubscription<OnNetworkNotification>(ON_CHAT_NOTIFICATION)

    useEffect(() => {
        if (dataNotificaiton) {
            AlertAnimation(true)
            setTimeout(() => {
                AlertAnimation(false)
            }, 5000)
            updateNetworkNotificationCount(+1)
        }
    }, [dataNotificaiton])

    const count = dataCount?.networkUnreadMessages

    if (!count) return null

    return <NotificationCounter>{count > 9 ? '9+' : count}</NotificationCounter>
}

export default NetworkNotifications
