import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { MentionsInput } from 'react-mentions'

interface StyledInputProps {
    color?: string
    error?: string
    rounded?: boolean
}

const ErrorCss = css`
    border-bottom: 1px solid ${({ theme }) => theme.main.danger};
    ${({ theme }) => `color: ${transparentize(0.5, theme.main.danger)}`};
`

const InputCss = css`
    width: 100%;
    border: none;
    background: none;
    padding: 0 10px;
    z-index: 100;
    max-height: 100px;
    margin-left: 12px;
    &::placeholder {
        color: white;
        opacity: 0.7;
    }
`

export const StyledMentionsInput = styled(MentionsInput)<StyledInputProps>`
    color: ${({ theme: { main } }) => main.white};
    ${InputCss}

    textarea {
        max-height: 100px;
        padding-right: 6px;
        padding-left: 6px;
        width: 90% !important;
        max-height: 90px !important;
    }
`

export const StyledInput = styled.input<StyledInputProps>`
    color: ${({ color }) => color};
    ${InputCss}
`

const RoundedCss = css`
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
`

export const StyledInputWrapper = styled.div<StyledInputProps>`
    width: 100%;
    position: relative;
    border-bottom: 1px solid ${({ color }) => color};
    ${({ error }) => error && ErrorCss}
    ${({ rounded }) => rounded && RoundedCss};
    border: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    &:hover {
        cursor: text;
    }
    label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: 0.2s;
        margin-top: -15px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

export const StyledLabel = styled.label<StyledInputProps>`
    color: ${({ color, error, theme }) => (error ? transparentize(0.5, theme.main.danger) : color)};
    position: absolute;
    &:hover {
        cursor: text;
    }
`

export const StyledIcon = styled.img`
    width: 15px;
    left: 5px;
    bottom: 10px;
`

export const ErrorWrapper = styled.div`
    margin: 10px 0;
    font-size: 12px;
`
