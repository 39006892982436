import { gql } from '@apollo/client'

export const EVENT_MENU_VERIFY = gql`
    query EventMenuVerify($id: ObjectID!) {
        brands(filter: { event: $id }) {
            totalCount
        }
        talks(filter: { event: $id, type: ONDEMAND }) {
            totalCount
        }
        event(id: $id) {
            viralCampaign {
                id
            }
            certificate {
                enabled
            }
        }
        userPermissions(event: $id)
    }
`

export const USER_PERMISSIONS = gql`
    query UserPermissions($event: ObjectID!) {
        userPermissions(event: $event)
    }
`
