import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    

    /* .background-color-primary-blur{
      background: ${({ theme }) => theme.main.primaryBlur};
    } */
  }
`
