import { useTranslation } from 'react-i18next'

export const useTalksText = () => {
    const { t } = useTranslation('home')

    const notFoundLastTalks = t('not-found.last-talks')
    const mainLastTalks = t('main.last-talks')
    const mainTalks = t('main.all-talks')
    const nextTalks = t('main.next-talks')
    const previousTalks = t('main.last-talks')
    const sponsorTalks = t('main.sponsor-talks')
    const onDemandTalks = t('main.ondemand-talks')
    const trailsTalks = t('main.trails-talks')
    const seriesTalks = t('main.series-talks')
    const recommendedTalks = t('main.recommended-talks')
    const stages = t('main.stages')

    return {
        notFoundLastTalks,
        mainLastTalks,
        previousTalks,
        mainTalks,
        nextTalks,
        sponsorTalks,
        onDemandTalks,
        recommendedTalks,
        stages,
        trailsTalks,
        seriesTalks
    }
}
