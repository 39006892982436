import styled from 'styled-components'

export const StyledExternalLink = styled.a`
    text-decoration: none;
    cursor: pointer;
    width: 100%;

    &:hover {
        color: ${({ theme }) => theme.main.white};
    }
`
