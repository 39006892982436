import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { GET_PREVIOUS_INFO, GET_TALK_BY_CATEGORIES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { useEffect, useState } from 'react'
import { EventQueryData } from '../../../types'
import Carousel from '../../Carousel'
import { LoadingState, NotFoundRow } from '../../Skeleton'
import { TalkTitle } from '../../styles'
import { SerieProps } from '../../types'
import { Description } from '../styles'

const Serie = ({ idTalk, name, description }: SerieProps) => {
    const { idEvent } = useAuth()
    const [talksItems, setTalksItems] = useState<any>([])

    const { data: dataInfo } = useQuery<EventQueryData, any>(GET_PREVIOUS_INFO, {
        variables: {
            id: idEvent as string
        },
        fetchPolicy: 'no-cache'
    })

    const { data, fetchMore } = useQuery<any>(GET_TALK_BY_CATEGORIES, {
        variables: {
            event: idEvent as string,
            category: idTalk
        }
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            if (talks) {
                const talkInfos = talks.map(({ node: { id, title, image } }) => {
                    return {
                        id,
                        thumbnail: image ? image.url : '',
                        title
                    }
                })

                setTalksItems([...talkInfos])
            }
        }
    }, [data])

    if (!data) {
        return (
            <>
                <div>
                    <TalkTitle fontSize={20} level={4}>
                        {name}
                    </TalkTitle>
                    <Description>{description}</Description>
                </div>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = dataInfo?.event?.logo?.url
    const { pageInfo } = data.talks

    const getMoreItems = async () => {
        if (pageInfo?.hasNextPage) {
            await fetchMore({
                variables: { after: pageInfo.endCursor }
            })
        }
    }

    if (!talksItems || !talksItems.length) {
        return null
    }

    return (
        <Row key={idTalk}>
            <div>
                <TalkTitle fontSize={20} level={4}>
                    {name}
                </TalkTitle>
                <Description>{description}</Description>
            </div>
            <Col span={24} className="title-pdl-20" key={idTalk}>
                {talksItems.length > 0 ? (
                    <Carousel
                        items={talksItems}
                        defaultThumbnail={defaultThumbnail}
                        hasNext={pageInfo?.hasNextPage}
                        getMoreItems={getMoreItems}
                    />
                ) : (
                    <NotFoundRow />
                )}
            </Col>
        </Row>
    )
}

export default Serie
