import styled from 'styled-components'

export const SelectorWrapper = styled.div`
    .language-selector {
        width: 80%;
        font-size: 9px;
        .ant-select-selector {
            background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
            border-radius: 65px;
            border: none;
            height: 20px;
            padding: 0px;

            .ant-select-selection-item {
                line-height: 20px;
                padding-right: 10px;

                svg {
                    position: relative;
                    top: 2px;
                    width: 10px;
                    height: 10px;
                    margin-right: 4px;
                }
            }
        }
        .ant-select-arrow {
            color: ${({ theme: { main } }) => main.nearWhiteOpacityMedium};
            font-size: 7px;
            line-height: 7px;
            padding-top: 1px;
            right: 4px;
        }
    }
`
