import { InMemoryCache, makeVar } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { isAuthenticated } from '../services/auth'

const Args = (keyArgs: any) => (args: any) =>
    args ? keyArgs.filter((keyArg: any) => args.hasOwnProperty(keyArg)) : null

const DefaultArgs = ['filter', 'search', 'orderBy']
const cacheParams = Args(DefaultArgs)

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isAuthenticated: {
                    read() {
                        return isAuthenticated()
                    }
                },
                users: relayStylePagination(cacheParams),
                categories: relayStylePagination(cacheParams),
                talks: relayStylePagination(cacheParams),
                speakers: relayStylePagination(cacheParams),
                brands: relayStylePagination(cacheParams),
                banners: relayStylePagination(cacheParams),
                notifications: relayStylePagination(cacheParams),
                chatMessages: relayStylePagination(Args([...DefaultArgs, 'channel'])),
                eventRegistrations: relayStylePagination(cacheParams),
                formDatas: relayStylePagination(cacheParams)
            }
        }
    }
})
