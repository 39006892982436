import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BackgroundImage from 'commons/assets/images/background_form.png'
import LastBackgroundImage from 'commons/assets/images/final_background_form.png'
import useWindowDimensions from 'commons/hooks/useWindowDimensions'
import { EventForm, EventFormField } from 'commons/gql/models/eventForm'
import { SUBMIT_PROGRESSIVE_FORM } from 'commons/gql/mutations/progressiveForm'

import { useHistory } from 'react-router-dom'
import { Form } from 'antd'
import { useMutation } from '@apollo/client'
import GenericStep from './GenericStep'
import { StyledContainer, StyledFormContainer, StyledStepContainer } from './styles'
import FormStep from './FormStep'
import ProgressStep from './ProgressStep'

interface Section {
    title: string
    fields: EventFormField[]
}

interface ProgressiveFormProps {
    progressiveForm: EventForm
}

const ProgressiveForm = ({ progressiveForm }: ProgressiveFormProps) => {
    const history = useHistory()
    const { t } = useTranslation('forms')
    const { width } = useWindowDimensions()

    const [step, setStep] = useState(0)
    const [form] = Form.useForm()

    const [submitProgressiveForm] = useMutation(SUBMIT_PROGRESSIVE_FORM)

    let i = 0
    const sections: Section[] = []

    for (const field of progressiveForm.fields) {
        if (field.type === 'section') {
            if (sections[i]) {
                i++
            }

            sections[i] = {
                title: `${field.label}`,
                fields: []
            }
            continue
        }

        sections[i]
            ? sections[i].fields.push(field)
            : (sections[i] = {
                  title: '',
                  fields: [field]
              })
    }

    const showProgress = width > 768
    const lastStep = step === sections.length + 1

    const onFinishForm = async () => {
        await form.validateFields()

        const formFields = progressiveForm.fields.map(({ name }) => name)
        const values = form.getFieldsValue(formFields)

        submitProgressiveForm({
            variables: {
                id: progressiveForm.id,
                data: {
                    ...values
                }
            }
        }).then(res => {
            if (res.data.formSubmit?.id) {
                window.location.reload()
            }
        })
    }

    useEffect(() => {
        // bloqueia a transição para qualquer rota se o form não estiver preenchido
        const unblock = history.block(tx => {
            alert(t('forms.complete-profile'))
            return false
        })

        return () => {
            unblock()
        }
    }, [])

    const getContent = () => {
        if (step === 0) {
            return (
                <GenericStep
                    title={t('first-section.title')}
                    buttonLabel={t('first-section.button')}
                    onClick={() => setStep(1)}
                />
            )
        }

        if (lastStep) {
            return (
                <StyledStepContainer>
                    <GenericStep
                        title={t('last-section.title')}
                        buttonLabel={t('last-section.button')}
                        onClick={() => form.submit()}
                    />

                    {showProgress && (
                        <ProgressStep
                            form={form}
                            step={step}
                            setStep={setStep}
                            sectionsLength={sections.length}
                            lastStep
                        />
                    )}
                </StyledStepContainer>
            )
        }

        const section = sections[step - 1]

        return (
            <>
                <StyledFormContainer
                    style={{
                        justifyContent: showProgress ? 'space-evenly' : 'flex-start'
                    }}
                >
                    <Form
                        form={form}
                        className="progressive-form"
                        layout="vertical"
                        onFinish={onFinishForm}
                        validateTrigger="onSubmit"
                    >
                        <FormStep
                            form={form}
                            section={section}
                            step={step}
                            progressiveForm={progressiveForm}
                        />
                    </Form>
                </StyledFormContainer>

                <ProgressStep
                    form={form}
                    step={step}
                    setStep={setStep}
                    sectionsLength={sections.length}
                />
            </>
        )
    }

    const backgroundImage = lastStep
        ? LastBackgroundImage
        : step === 0
        ? BackgroundImage
        : undefined

    return <StyledContainer image={backgroundImage}>{getContent()}</StyledContainer>
}

export default ProgressiveForm
export type { Section }
