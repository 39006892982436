import styled, { css } from 'styled-components'

interface UploaderContainerProps {
    showUploader: boolean
    deleteText: string
}

export const UploaderContainer = styled.div<UploaderContainerProps>`
    width: 100%;
    height: 100%;

    .ant-upload-drag {
        background: transparent !important;
        border: 2px dashed ${({ theme: { main } }) => main.nearWhiteOpacity};
        border-radius: 20px;
        &:hover {
            border-color: ${({ theme: { main } }) => main.white}!important;
        }
    }
    .ant-upload-drag-container {
        .ant-space-item {
            padding: 20px;
        }
    }

    .ant-upload-list-picture-card-container {
        width: 100%;
        height: 146px;
        border-radius: 20px;
        background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};

        .ant-upload-list-item {
            border: none;
            display: flex;
            opacity: 1;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            .ant-upload-list-item-actions {
                opacity: 1;
                position: relative;
                left: 0;
                top: 0;
                transform: unset;
            }

            .ant-upload-list-item-card-actions-btn {
                opacity: 1;
                width: 100px;
                height: 30px;
                border-radius: 58px;
                background-color: ${({ theme: { main } }) => main.highlight};

                svg {
                    fill: ${({ theme: { main } }) => main.white};
                }

                ${({ deleteText, theme: { main } }) =>
                    deleteText &&
                    css`
                        &::after {
                            content: '${deleteText}';
                            color: ${main.white};
                        }
                    `}
            }
        }
    }

    ${({ showUploader }) =>
        showUploader
            ? css`
                  .ant-upload-drag {
                      display: block;
                  }
                  .ant-upload-list {
                      display: none;
                  }
              `
            : css`
                  .ant-upload-drag {
                      display: none;
                  }
                  .ant-upload-list {
                      display: block;
                  }
              `}
`

export const IconWrapper = styled.div`
    height: 83px;
    width: 83px;
    border-radius: 20px;
    background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
    display: flex;
    align-items: center;
    justify-content: center;
`
