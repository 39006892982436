import React, { useEffect, useState } from 'react'
import { Modal as AntModal } from 'antd'

import { Iframe, ButtonV2 } from 'commons/components'
import { ActionsType } from 'commons/gql/models/brand'

import { CustomButtonsProps } from '../ButtonV2/types'
import { WrapperModal, WrapperContent } from './styles'

interface ActionsTypeCustom extends ActionsType {
    sponsorId?: string
}

const sizes = {
    large: 900,
    normal: 700,
    small: 500
}

const Modal: React.FC<ActionsTypeCustom> = props => {
    const { name, url, size, sponsorId } = props
    const [visible, setVisible] = useState(false)

    const handleVisible = () => {
        setVisible(!visible)
    }

    const trackAction = () => {
        window.TDA.track('brandAction', {
            id: sponsorId,
            name,
            url
        })
    }

    const options: CustomButtonsProps = {
        ghost: true,
        size: 'large'
    }

    if (size === 'external') {
        options.href = url
        options.target = '_blank'
        options.onClick = trackAction
    } else {
        options.onClick = handleVisible
    }

    const modalWidth = sizes[size as keyof typeof sizes]

    const iframe = {
        name,
        url
    }

    useEffect(() => {
        if (visible) {
            trackAction()
        }
    }, [visible])

    return (
        <WrapperModal>
            <ButtonV2 {...options}>{name}</ButtonV2>
            {size !== 'external' && (
                <AntModal
                    className="action-modal"
                    visible={visible}
                    onCancel={handleVisible}
                    footer={null}
                    width={modalWidth}
                >
                    <WrapperContent $size={modalWidth}>
                        <Iframe iframe={iframe} />
                    </WrapperContent>
                </AntModal>
            )}
        </WrapperModal>
    )
}

export default Modal
