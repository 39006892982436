import React, { FC, useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { useHistory } from 'react-router'

import { INC_BRAND_VIEWS } from 'commons/gql/mutations/brand'

import { SponsorCard } from 'commons/components'
import { BrandModal, BrandDetail, SponsorsCardProps } from 'commons/gql/models/brand'
import { useModal } from 'commons/components/modal/modalContext'
import { useMutation } from '@apollo/client'
import { ListBrandsProps } from '../types'
import { useGrid } from '../utils'
import Modal from '../Modal'

export interface CardProps extends SponsorsCardProps, BrandDetail {}

const Card: FC<CardProps> = props => {
    const history = useHistory()
    const [visible, setVisible] = useState<boolean>(false)
    const {
        aboutUs,
        actions,
        contact,
        description,
        email,
        logo,
        name,
        social,
        site,
        type,
        id,
        url,
        sponsorLink,
        sponsorPage
    } = props

    const [incBrandView] = useMutation(INC_BRAND_VIEWS, {
        variables: {
            id
        }
    })

    function navigateTo() {
        const { location } = history

        if (url) {
            if (location.pathname.includes('home/live/')) {
                window.open(url, '_blank')
            } else {
                history.push(url)
            }
        }
    }

    const handleClick = () => {
        if (sponsorPage) {
            navigateTo()
        } else if (sponsorLink) {
            window.open(site, '_blank')
            window.TDA.track('brandView', {
                id
            })
            incBrandView()
        } else return null
    }

    const info = useGrid(type)

    return (
        <Col lg={info.desktop.columns} sm={info.mobile.columns} xs={info.mobile.columns}>
            <SponsorCard onClick={handleClick} {...props} overlay />
            {type === 'PP' && (
                <Modal
                    visible={visible}
                    handleVisible={handleClick}
                    aboutUs={aboutUs}
                    actions={actions}
                    contact={contact}
                    description={description}
                    email={email}
                    logo={logo}
                    name={name}
                    social={social}
                    site={site}
                />
            )}
        </Col>
    )
}

export default Card
