import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { ROUTES } from 'commons/constants'
import TodayTalksCard from 'commons/components/TodayTalksCard'
import { VirtualizedCarousel } from 'commons/components'

import { TalksCarrouselProps } from '../types'

const TalksCarrousel = ({
    items,
    defaultThumbnail,
    getMoreItems,
    hasNext,
    type
}: TalksCarrouselProps) => {
    const { push } = useHistory()

    if (!items) return null

    const isStage = type === 'stage'

    const renderTodayTalksCard = ({ style, index }: { index: number; style: any }) => {
        const { hour, subtitle, thumbnail, title, id, url, isExternal } = items[index]

        const urlGenerated = url || generatePath(ROUTES.live, { talkId: id })
        const onClick = () => (isExternal ? window.open(urlGenerated) : push(urlGenerated))

        return (
            <TodayTalksCard
                style={style}
                onClick={onClick}
                thumbnail={thumbnail}
                hour={hour}
                subTitle={subtitle}
                title={title}
                key={title}
                defaultThumbnail={defaultThumbnail}
            />
        )
    }

    return (
        <VirtualizedCarousel
            height={isStage ? 415 : 256}
            width={isStage ? 242 : 320}
            gutter={32}
            items={items}
            Component={renderTodayTalksCard}
            borderRadius={20}
            getMoreItems={getMoreItems}
            hasNext={hasNext}
        />
    )
}

export default TalksCarrousel
