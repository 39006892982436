import { HeartOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Menu } from 'antd'
import { ChevronRight, FancyList, Leave, QuestionOutline } from 'commons/assets/icons'
import { ReactComponent as Arrow } from 'commons/assets/icons/chevron-right.svg'
import Avatar from 'commons/components/Avatar'
import { ROUTES } from 'commons/constants'
import { MenuData } from 'commons/gql/models/menuData'
import { UserData } from 'commons/gql/models/userData'
import { GET_EVENT_INTEGRATIONS } from 'commons/gql/queries/event'
import { useMenuValidations } from 'commons/hooks/useMenuValidations'
import { usePermissions } from 'commons/hooks/usePermissions'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { Fragment, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { EVENT_MENU_VERIFY } from 'routing/gql/queries'
import { IntegrationData } from 'screens/Feed'

import LanguageSelector from '../LanguageSelector'
import LogoutModal from '../LogoutModal'
import { UserContainer } from './styles'

const { SubMenu } = Menu

interface QueryMenuVerify {
    event: {
        viralCampaign?: {
            id: string
        }
    }
}
interface UserMenuProps {
    userMenuData?: MenuData['user']
    data?: UserData
    logo?: string
}

const UserMenu = ({ data, logo, userMenuData }: UserMenuProps) => {
    const { idEvent } = useAuth()

    const { push, location } = useHistory()
    const { t } = useTranslation('commons')

    const validations = useMenuValidations()
    const permissions = usePermissions()

    const [showLogoutModal, setShowLogoutModal] = useState(false)

    const { data: integrationsData } = useQuery<IntegrationData>(GET_EVENT_INTEGRATIONS, {
        variables: {
            event: idEvent
        }
    })

    const { data: viralData } = useQuery<QueryMenuVerify>(EVENT_MENU_VERIFY, {
        variables: { id: idEvent }
    })

    if (!data || !integrationsData) return null

    const defaultProfile = 'https://tdstorage.s3-sa-east-1.amazonaws.com/avatar/default.jpg'
    const {
        avatar,
        config: { language }
    } = data.me

    const logout = () => {
        setShowLogoutModal(true)
    }

    const createMenuItemsDynamically = () => {
        const menus: Record<string, ReactNode> = {
            guestlist: (
                <>
                    {permissions.includes('GUEST_LIST') &&
                        validations?.showViralCampaigns &&
                        !!viralData?.event?.viralCampaign?.id && (
                            <Menu.Item
                                key={ROUTES.guestList}
                                onClick={() => push(ROUTES.guestList)}
                                id="btn-guest-list"
                            >
                                <FancyList style={{ marginRight: '15px' }} />
                                {t('sidebar.guestlist')}
                                <ChevronRight className="arrow-right-icon" />
                            </Menu.Item>
                        )}
                </>
            ),
            favorites: (
                <>
                    {validations?.showTalkTags && (
                        <Menu.Item
                            key={ROUTES.favoriteTopics}
                            onClick={() => push(ROUTES.favoriteTopics)}
                            id="btn-favorite-topics"
                        >
                            <HeartOutlined style={{ marginRight: '15px' }} />{' '}
                            {t('sidebar.favorites')} <Arrow className="arrow-right-icon" />
                        </Menu.Item>
                    )}
                </>
            ),
            faq: (
                <>
                    {validations?.showFaq && (
                        <Menu.Item key={ROUTES.faq} onClick={() => push(ROUTES.faq)} id="btn-faq">
                            <QuestionOutline style={{ marginRight: '15px' }} />
                            {t('sidebar.faq')}
                            <ChevronRight className="arrow-right-icon" />
                        </Menu.Item>
                    )}
                </>
            )
        }

        return userMenuData
            ?.filter(({ visible }) => visible)
            ?.map(({ name }) => <Fragment key={name}>{menus[name]}</Fragment>)
    }

    return (
        <UserContainer>
            <LogoutModal
                isVisible={showLogoutModal}
                setIsVisible={setShowLogoutModal}
                logo={logo}
            />
            {data && (
                <>
                    <Menu
                        mode="vertical"
                        className="avatar-menu"
                        defaultSelectedKeys={['/']}
                        selectedKeys={[location.pathname]}
                    >
                        <SubMenu
                            key="sub1"
                            className="avatar-submenu"
                            icon={<Avatar size={37} src={avatar?.url || defaultProfile} />}
                        >
                            <Menu.ItemGroup className="usermenu-item-group">
                                <Menu.Item
                                    key={ROUTES.homeProfile}
                                    onClick={() => push(ROUTES.homeProfile)}
                                    style={{ height: '65px' }}
                                    id="btn-profile"
                                >
                                    <Avatar
                                        size={30}
                                        src={avatar?.url || defaultProfile}
                                        style={{ margin: '0 15px 0 0', borderRadius: '50%' }}
                                    />
                                    {t('sidebar.user')}
                                    <ChevronRight className="arrow-right-icon" />
                                </Menu.Item>

                                {createMenuItemsDynamically()}

                                <Menu.Item key="5" onClick={logout} id="btn-logout">
                                    <Leave style={{ marginRight: '15px' }} /> {t('sidebar.leave')}{' '}
                                    <ChevronRight className="arrow-right-icon" />
                                </Menu.Item>
                            </Menu.ItemGroup>
                        </SubMenu>
                    </Menu>
                </>
            )}
            <LanguageSelector lang={language} />
        </UserContainer>
    )
}

export default UserMenu
