import React, { useState } from 'react'

import { Form, Checkbox, Modal } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'
import { IS_DEV, IS_PREVIEW } from 'commons/utils'

const BASE_URL = IS_DEV
    ? IS_PREVIEW
        ? 'https://site-dev.app.td'
        : 'http://localhost:' + (process.env.PORT || '5001')
    : 'https://ste.app.td'

const TermInput: React.FC<IInputType> = ({
    name,
    label,
    config,
    formData,
    customer
}) => {
    const [visible, setVisible] = useState(false)

    const domainName = formData?.event?.domain?.name
    const eventId = formData?.event?.id

    const termsUrl = domainName
        ? `https://${domainName}/terms`
        : `${BASE_URL}/${customer}/${eventId}/terms`

    const Label = () => {
        return (
            <a href={config.url || termsUrl} target="_blank" rel="noreferrer">
                {config.label}
            </a>
        )
    }

    return (
        <Wrapper>
            <Form.Item
                name={name}
                valuePropName="checked"
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <Checkbox>
                    {label} <Label />
                </Checkbox>
            </Form.Item>
            <Modal
                title={config.label}
                onCancel={() => setVisible(false)}
                visible={visible}
                footer={null}
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.01)' }}
            >
                <div dangerouslySetInnerHTML={{ __html: config.text }} />
            </Modal>
        </Wrapper>
    )
}

export default TermInput
