import styled from 'styled-components'

export const NotificationCounter = styled.div`
    position: absolute;
    width: 20px;
    height: 12px;
    background: #ff003d;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    right: 17px;
    top: 3px;
    color: #fff;
`
