import React, { FC, useCallback, useEffect, useState } from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { ROUTES } from 'commons/constants'
import { EventForm } from 'commons/gql/models/eventForm'
import { GET_PROGRESSIVE_FORM } from 'commons/gql/queries/progressiveForm'
import { GET_BRAND_TITLE } from 'commons/gql/queries/sponsors'
import { GET_TALK_TITLE } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'

import ProgressiveForm from 'screens/ProgressiveForm'

interface IRoute extends RouteProps {
    title: string
    dynamic?: string
}

type TFunction = () => void

interface IParams {
    sponsorId?: string
    talkId?: string
}

interface ITrackerInfo {
    sponsorId?: string
    talkId?: string
    title: string
}

const ROUTES_WITH_FORMS: { [index: string]: string } = {
    [ROUTES.home]: 'Home',
    [ROUTES.certificate]: 'Certificado',
    [ROUTES.networking]: 'Networking'
}

const CustomWrapper: FC<IRoute> = ({ children, ...rest }) => {
    const { idEvent } = useAuth()

    const [formData, setFormData] = useState<EventForm | null>(null)

    const [getBrand, { data: brand }] = useLazyQuery(GET_BRAND_TITLE)
    const [getTalk, { data: talk }] = useLazyQuery(GET_TALK_TITLE)
    const [getForm, { data: forms, loading: formLoading }] = useLazyQuery(GET_PROGRESSIVE_FORM, {
        fetchPolicy: 'network-only'
    })

    const { sponsorId, talkId } = useParams<IParams>()

    const dynamicTitle = {
        sponsorId: {
            query: getBrand,
            data: brand,
            node: 'brand',
            prefix: 'Visitando o Stand: ',
            id: sponsorId as string | undefined
        },
        talkId: {
            query: getTalk,
            data: talk,
            node: 'talk',
            prefix: 'Assistindo: ',
            id: talkId as string | undefined
        }
    }

    const handleSendTitle = useCallback(
        (title?: string) => {
            let cancel: TFunction
            const { syncUser } = window?.TDA

            if (syncUser) {
                const trackInfo: ITrackerInfo = {
                    title: title || rest.title
                }
                if (talkId) trackInfo.talkId = talkId
                if (sponsorId) trackInfo.sponsorId = sponsorId

                syncUser(trackInfo, (_cancel: TFunction) => {
                    cancel = _cancel
                })

                return () => {
                    cancel()
                }
            }

            return false
        },
        [rest.title, sponsorId, talkId]
    )

    const handleDynamicFormRender = useCallback((pathname: string) => {
        if (pathname && !formLoading) {
            getForm({
                variables: {
                    eventId: idEvent as string,
                    page: ROUTES_WITH_FORMS[pathname]
                }
            })
        }
    }, [])

    const queryInfo = dynamicTitle[rest.dynamic as keyof typeof dynamicTitle]

    const handleDynamicTitle = useCallback(() => {
        const { query, id } = queryInfo

        if (id) {
            query({
                variables: {
                    id
                }
            })
        }
    }, [])

    useEffect(() => {
        if (
            rest.location?.pathname &&
            [ROUTES.certificate, ROUTES.home, ROUTES.networking].includes(rest.location?.pathname)
        ) {
            handleDynamicFormRender(rest.location?.pathname)
        }
    }, [rest.location?.pathname])

    useEffect(() => {
        setFormData(forms?.eventFormProgressive)
    }, [forms])

    useEffect(() => {
        if (document.title !== rest.title) {
            if (rest.dynamic) {
                handleDynamicTitle()
            } else {
                document.title = rest.title
                handleSendTitle()
            }
        }
    }, [rest.dynamic])

    useEffect(() => {
        const data = brand || talk

        if (data) {
            const title =
                queryInfo.prefix + (data[queryInfo.node]?.name || data[queryInfo.node]?.title)
            document.title = title
            handleSendTitle(title)
        }
    }, [brand, talk])

    if (formData) {
        return <ProgressiveForm progressiveForm={formData} />
    }

    return <>{children}</>
}

export default CustomWrapper
