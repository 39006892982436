import React from 'react'
import truncateString from 'commons/helpers/truncateString'
import { Tooltip } from 'antd'
import { useTheme } from 'styled-components'
import _uniqueId from 'lodash/uniqueId'
import { parseProps } from '../utils'

import { StyledParagraphWrapper, StyledParagraph } from './styles'
import { CustomParagraph } from '../types'

const Paragraph: React.FC<CustomParagraph> = ({ children, ...props }) => {
    const { truncate, rows, linebreak = false } = props
    const size = children?.toString().length
    const content = children?.toString()
    const parsedProps = parseProps(props)

    const {
        v2: { menu }
    } = useTheme()

    if (rows) {
        parsedProps.ellipsis = {
            rows,
            tooltip: (
                <div className="paragraph-tooltip" style={{ maxWidth: '70vh' }}>
                    {content}
                </div>
            )
        }
    }

    const id = _uniqueId('tooltip-')

    return (
        <StyledParagraphWrapper {...parsedProps} id={id}>
            {content && truncate && size && size > truncate ? (
                <Tooltip
                    overlayClassName="paragraph-tooltip"
                    placement="top"
                    title={children}
                    getPopupContainer={() => document.getElementById(id) as HTMLElement}
                    color={menu.bgColor}
                >
                    <StyledParagraph {...parsedProps}>
                        {truncateString(content, truncate)}
                    </StyledParagraph>
                </Tooltip>
            ) : (
                <>
                    {!linebreak ? (
                        <StyledParagraph {...parsedProps}>{children}</StyledParagraph>
                    ) : (
                        <StyledParagraph {...parsedProps}>
                            {content?.split('\n').map((item, idx) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <React.Fragment key={idx}>
                                        {item}
                                        <br />
                                    </React.Fragment>
                                )
                            })}
                        </StyledParagraph>
                    )}
                </>
            )}
        </StyledParagraphWrapper>
    )
}

export default Paragraph
