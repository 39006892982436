import styled from 'styled-components'

export const WrapperModal = styled.div``

export const WrapperContent = styled.div<{ $size: number }>`
    width: 100%;
    height: ${({ $size }) => `${$size / 1.5}px`};
    min-height: ${({ $size }) => `${$size / 1.5}px`};
    overflow: hidden;

    @media (max-width: 767px) {
        height: 400px;
        min-height: 400px;
    }
`
