import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'

import { ArrowRight } from 'commons/assets/icons'

import { StyledContainer, ArrowStep, LastItemOverlay } from './styles'

const virtualizedContent = (
    Component: any,
    items: any,
    layout: 'horizontal' | 'vertical' | 'card' | undefined,
    gutter: number
) => {
    return (args: any) => {
        const { style, ...props } = args || {}

        const styles = { ...style }

        if (layout === 'vertical') {
            styles.top += gutter
            styles.height -= gutter
            styles.transform = `translateY(-${gutter}px)`
        } else if (layout === 'card') {
            styles.top += gutter
            styles.height -= gutter
            styles.transform = `translateY(-${gutter}px)`
            styles.left += gutter
            styles.width -= gutter
            styles.transform = `translateX(-${gutter}px)`
        } else {
            styles.left += gutter
            styles.width -= gutter
            styles.transform = `translateX(-${gutter}px)`
        }

        return <Component style={styles} {...props} />
    }
}

interface Props {
    items: any
    Component: any
    height: number
    width: number
    gutter: number
    columns: number
    count: number
    layout?: 'horizontal' | 'vertical' | undefined
    style?: React.CSSProperties
    borderRadius?: number
    hasNext?: boolean
    getMoreItems?: () => void
}

const Virtualized = (props: Props) => {
    const {
        items,
        height,
        width,
        style,
        layout,
        gutter,
        Component,
        getMoreItems,
        hasNext,
        columns,
        count
    } = props
    const list = useRef<HTMLDivElement>(null)
    // const [containerWidth, setContainerWidth] = useState(0)

    const isItemLoaded = (index: number) => items[index]

    const loadMoreItems = async (startIndex: number, stopIndex: number) => {
        if (hasNext && getMoreItems) {
            getMoreItems()
        }
    }

    const callItemsRendered = (gridProps: any, onItemsRendered: any) => {
        onItemsRendered({
            overscanStartIndex: gridProps.overscanRowStartIndex * columns,
            overscanStopIndex: gridProps.overscanRowStopIndex * columns,
            visibleStartIndex: gridProps.visibleRowStartIndex * columns,
            visibleStopIndex: gridProps.visibleRowStopIndex * columns
        })
    }

    return (
        <div style={{ width: '100%' }} ref={list}>
            <StyledContainer style={style} className="virtualized">
                <AutoSizer
                    className="virtualized-autosize"
                    disableHeight
                    defaultWidth={items.length * (width + gutter)}
                >
                    {({ width: ContainerWidth }) => {
                        return (
                            <InfiniteLoader
                                minimumBatchSize={count < 30 ? count : 30}
                                threshold={count < 30 ? count : 30}
                                isItemLoaded={isItemLoaded}
                                itemCount={count}
                                loadMoreItems={loadMoreItems}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <Grid
                                        ref={ref}
                                        height={640}
                                        width={ContainerWidth}
                                        onItemsRendered={gridProps =>
                                            callItemsRendered(gridProps, onItemsRendered)
                                        }
                                        className="virtualized-list"
                                        rowCount={Math.ceil(items.length / columns)}
                                        rowHeight={height}
                                        columnCount={columns}
                                        columnWidth={ContainerWidth / columns - 3}
                                    >
                                        {virtualizedContent(Component, items, 'card', gutter)}
                                    </Grid>
                                )}
                            </InfiniteLoader>
                        )
                    }}
                </AutoSizer>
            </StyledContainer>
        </div>
    )
}

export default React.memo(Virtualized)
