import React from 'react'

import { StyledButton, StyledLink } from './styles'
import { CustomButtonsProps } from './types'
import { parseProps } from './utils'

const Button: React.FC<CustomButtonsProps> = ({ children, ...props }) => {
    const { href, isLink } = props
    const parsedProps = parseProps(props)
    if (isLink) {
        return (
            <StyledLink to={href} {...parsedProps}>
                {children}
            </StyledLink>
        )
    }

    return (
        <StyledButton className={href && 'ant-btn-link'} href={href} {...parsedProps}>
            {children}
        </StyledButton>
    )
}

export default Button
