import { lazy } from 'react'

const Home = lazy(() => import('screens/Home'))
// const Schedule = lazy(() => import('screens/Schedule'))
const Schedule = lazy(() => import('screens/Schedule'))
const Lecturers = lazy(() => import('screens/Speakers'))
const Sponsors = lazy(() => import('screens/Sponsors'))
const SponsorsDetails = lazy(() => import('screens/Sponsors/SponsorDetail'))
const Certificate = lazy(() => import('screens/Certificate'))
const Networking = lazy(() => import('screens/Networking'))
const ReferAndWin = lazy(() => import('screens/ReferAndWin'))
const Profile = lazy(() => import('screens/UserProfile'))
const FavoriteTopics = lazy(() => import('screens/FavoriteTopics'))
const ProfileEdit = lazy(() => import('screens/UserProfile/edit'))
const RecoverPassword = lazy(() => import('screens/RecoverPassword'))
const Live = lazy(() => import('screens/Streaming'))
const OnDemand = lazy(() => import('screens/OnDemand'))
const GuestList = lazy(() => import('screens/GuestList'))
const Faq = lazy(() => import('screens/Faq'))
const EventInfo = lazy(() => import('screens/EventInfo'))
const Feed = lazy(() => import('screens/Feed'))

export const ROUTES = {
    home: '/home',
    schedule: '/home/schedule',
    scheduleTalkId: '/home/schedule/:talkId',
    scheduleShare: '/home/schedule/share/:userId',
    networking: '/home/networking',
    referral: '/home/referral',
    sponsors: '/home/sponsors',
    sponsorsDetails: '/home/sponsors/:sponsorId/detail/',
    lecturers: '/home/lecturers',
    certificate: '/home/certificate',
    live: '/home/live/:talkId',
    homeProfile: '/home/profile/',
    profile: '/home/profile/:userId?',
    favoriteTopics: '/home/favorite-topics',
    profileEdit: '/home/profile/:userId?/edit',
    recoverPassword: '/home/recover-password',
    networkingProfile: '/home/networking/:chatId?',
    networkingHome: '/home/networking/users',
    onDemand: '/home/ondemand',
    guestList: '/home/guest-list',
    faq: '/home/faq',
    eventInfo: '/home/event-info',
    feed: '/home/feed'
}

/*
{
    title: '',
    path: '',
    component: () => {},
    exact: true,
    permission: ''
}
*/
export const ROUTES_WITH_PARAMS = [
    {
        title: 'Home',
        path: ROUTES.home,
        component: Home,
        exact: true,
        permission: null
    },
    {
        title: 'Agenda',
        path: ROUTES.schedule,
        component: Schedule,
        exact: true,
        permission: null
    },
    {
        title: 'Palestras',
        path: ROUTES.scheduleTalkId,
        component: Schedule,
        exact: true,
        permission: null
    },
    {
        title: 'Palestras',
        path: ROUTES.scheduleShare,
        component: Schedule,
        exact: true,
        permission: null
    },
    {
        title: 'Networking',
        path: ROUTES.networking,
        component: Networking,
        exact: true,
        permission: 'NETWORKING'
    },
    {
        title: 'Indique e ganhe',
        path: ROUTES.referral,
        component: ReferAndWin,
        exact: true,
        permission: null
    },
    {
        title: 'Patrocinadores',
        path: ROUTES.sponsors,
        component: Sponsors,
        exact: true,
        permission: null
    },
    {
        title: 'Patrocinadores',
        path: ROUTES.sponsorsDetails,
        component: SponsorsDetails,
        exact: true,
        permission: null,
        dynamic: 'sponsorId'
    },
    {
        title: 'Palestrantes',
        path: ROUTES.lecturers,
        component: Lecturers,
        exact: true,
        permission: null
    },
    {
        title: 'Certificado',
        path: ROUTES.certificate,
        component: Certificate,
        exact: true,
        permission: 'CERTIFICATE'
    },
    {
        title: 'Live',
        path: ROUTES.live,
        component: Live,
        exact: true,
        permission: null,
        dynamic: 'talkId'
    },
    {
        title: 'Perfil',
        path: ROUTES.homeProfile,
        component: Profile,
        exact: true,
        permission: null
    },
    {
        title: 'Perfil',
        path: ROUTES.profile,
        component: Profile,
        exact: true,
        permission: null
    },
    {
        title: 'Favoritos',
        path: ROUTES.favoriteTopics,
        component: FavoriteTopics,
        exact: true,
        permission: null
    },
    {
        title: 'Perfil',
        path: ROUTES.profileEdit,
        component: ProfileEdit,
        exact: true,
        permission: null
    },
    {
        title: 'Recuperar Senha',
        path: ROUTES.recoverPassword,
        component: RecoverPassword,
        exact: true,
        permission: null
    },
    {
        title: 'Networking',
        path: ROUTES.networkingProfile,
        component: Networking,
        exact: true,
        permission: 'NETWORKING'
    },
    {
        title: 'On Demand',
        path: ROUTES.onDemand,
        component: OnDemand,
        exact: true,
        permission: 'ONDEMAND'
    },
    {
        title: 'Guest List',
        path: ROUTES.guestList,
        component: GuestList,
        exact: true,
        permission: 'GUEST_LIST'
    },
    {
        title: 'Faq',
        path: ROUTES.faq,
        component: Faq,
        exact: true,
        permission: null
    },
    {
        title: 'Sobre',
        path: ROUTES.eventInfo,
        component: EventInfo,
        exact: true,
        permission: null
    },
    {
        title: 'Feed do evento',
        path: ROUTES.feed,
        component: Feed,
        exact: true,
        permission: null
    }
]

export const AUTH = {
    magic: '/auth',
    login: '/auth/login',
    forgot: '/auth/forgot-password',
    recover: '/auth/recoverpassword'
}
