import { useCallback, useEffect, useState } from 'react';

const INITIAL_VW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const INITIAL_VH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

const useViewport = () => {
  const [vw, setVw] = useState(INITIAL_VW);
  const [vh, setVh] = useState(INITIAL_VH);

  const onResize = useCallback(() => {
    const newVw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const newVh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    setVw(newVw);
    setVh(newVh);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  });
  return { vw, vh };
};

export default useViewport;
