/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CloseOutlined, FullscreenOutlined } from '@ant-design/icons'
import { ReactComponent as MutedIcon } from './icons/muted.svg'
import Plyr from './plyr'

import { PlyrProvider, HTMLPlyrVideoElement, PlyrSourceInfo } from './plyr/types'

import {
    PlayerContainer,
    PlayerMuted,
    PiPButton,
    ClosePiPButton,
    BackTalkPiPButton,
    LiveStatus
} from './styles'
import { usePlayerPiP } from './context'
import { VideoPlayerProps } from '../types'

const getProvider = (url: string): PlyrProvider => {
    if (url.indexOf('.mp4') > -1) return 'html5'
    if (url.indexOf('youtube') > -1) return 'youtube'
    if (url.indexOf('vimeo') > -1) return 'vimeo'

    return 'html5'
}

const getSource = (source: string, url: string): string => {
    if (source === 'vimeo') {
        if (url.indexOf('event') > -1) {
            return url.replace(/^(.*vimeo.com\/event\/)(\d+)\/(\w+).*/, '$1/$2/embed/$3')
        }
        const regex = /^.*(vimeo.com\/|video\/)(\d+).*/
        const parts = url.match(regex)
        return parts ? parts[2] : url
    }

    return url
}

const canAccessYoutube = async () => {
    try {
        await fetch('https://www.youtube.com/', {
            mode: 'no-cors'
        })

        return true
    } catch {
        return false
    }
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    id,
    url = '',
    fallbackUrl,
    poster,
    status,
    children,
    autoplay = true,
    isSponsor = false,
    isPiP = false,
    currentTime = 0,
    isLive
}) => {
    const [instance, setInstance] = useState<HTMLPlyrVideoElement>()
    const [source, setSource] = useState<PlyrSourceInfo>()
    const [muted, setMuted] = useState(false)
    const [premiere, setPremiere] = useState(false)

    const { pathname } = useLocation()
    const history = useHistory()

    const { miniPlayer, setMiniPlayer, videoInfo, setVideoInfo } = usePlayerPiP()

    useEffect(() => {
        if (miniPlayer && !isPiP) {
            setMiniPlayer(false)

            return
        }

        let previousUrl: string

        const unlisten = history.listen((listener: any) => {
            previousUrl = !previousUrl ? pathname : listener.pathname

            if (previousUrl === pathname) {
                if (!miniPlayer && !isPiP) {
                    setMiniPlayer(true)
                }
            }
        })

        // eslint-disable-next-line consistent-return
        return unlisten
    }, [])

    const setAllVideoInfo = (currentTimeParam: number) => {
        setVideoInfo({
            status,
            url,
            autoplay,
            currentTime: currentTimeParam,
            fallbackUrl,
            id,
            isSponsor,
            poster,
            talkPath: pathname,
            isLive
        })
    }

    useEffect(() => {
        const init = async (src: string) => {
            const provider = getProvider(src)
            const _url = getSource(provider, src)

            setSource({
                type: 'video',
                sources: [
                    {
                        src: _url,
                        provider
                    }
                ]
            })

            if (fallbackUrl && provider === 'youtube' && !(await canAccessYoutube())) {
                init(fallbackUrl)
            }
        }

        init(url) // aq eu faço a diferença
    }, [url, fallbackUrl])

    useEffect(() => {
        if (!instance) return
        const { plyr } = instance
        if (!plyr) return

        try {
            const _save = JSON.parse(localStorage.getItem('plyr') || '')
            _save.speed = 1
            localStorage.setItem('plyr', JSON.stringify(_save))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
        }

        const { container } = plyr.elements

        plyr.on('loadstart', () => {
            if (container && !container.classList.contains('plyr-start')) {
                container.classList.add('plyr-loading')
            }
        })

        plyr.on('ready', () => {
            if (!container) return
            container.classList.add('plyr-start')
            container.classList.remove('plyr-loading')
            plyr.muted = false

            window.plyr = plyr
            if (autoplay) {
                plyr.play()
            }

            if (container) {
                if (container.querySelector('.plyr__volume input')) {
                    const btnVolume: HTMLElement | null = container.querySelector(
                        '.plyr__volume input'
                    )
                    if (btnVolume) {
                        btnVolume.addEventListener('mousedown', (e: MouseEvent) => {
                            e.stopPropagation()
                        })
                    }
                }
            }
        })

        // Modo Estreia do youtube
        let cancelTimer = 0
        let playTimer = 0
        let _safePlay = true
        plyr.on('progress', () => {
            setVideoInfo(prevState => {
                return { ...prevState, currentTime: plyr.currentTime }
            })
            if (
                plyr.isYouTube &&
                container &&
                !container.classList.contains('plyr-youtube-premiere') &&
                !plyr.duration &&
                !plyr.currentTime
            ) {
                clearTimeout(playTimer)
                clearTimeout(cancelTimer)
                cancelTimer = window.setTimeout(() => {
                    setPremiere(true)
                    container.classList.add('plyr-youtube-premiere')
                }, 3000)
            }

            if (
                plyr.isYouTube &&
                container &&
                container.classList.contains('plyr-youtube-premiere') &&
                plyr.currentTime
            ) {
                setPremiere(false)
                setMuted(plyr.muted)
                window.clearTimeout(cancelTimer)
                container.classList.remove('plyr-youtube-premiere')
            }

            if (_safePlay && !plyr.currentTime) {
                window.clearTimeout(playTimer)
                if (plyr) {
                    playTimer = window.setTimeout(() => {
                        setMuted(true)
                        plyr.muted = true
                        if (autoplay) {
                            plyr.play()
                        }
                    }, 1000)
                }
            }
        })

        plyr.on('play', () => {
            if (plyr.currentTime === 0) {
                plyr.currentTime = currentTime

                if (!isPiP) {
                    plyr.currentTime = videoInfo?.currentTime || 0
                    setAllVideoInfo(plyr.currentTime)
                }

                if (isSponsor) {
                    window.TDA.track('brandVideo', {
                        id,
                        url
                    })
                }
            }
        })

        plyr.on('ended', () => {
            plyr.stop()
        })

        plyr.on('enterfullscreen', () => {
            if (container) {
                container.classList.add('plyr-fullscreen')
            }
        })

        plyr.on('exitfullscreen', () => {
            if (container) {
                container.classList.remove('plyr-fullscreen')
            }
        })

        plyr.on('error', () => {
            setMuted(true)
            plyr.muted = true
            if (autoplay) {
                plyr.play()
            }
        })

        plyr.on('statechange', e => {
            if (e.detail.code === 1) {
                clearTimeout(playTimer)
                clearTimeout(cancelTimer)
                _safePlay = false
                setPremiere(false)
            }
        })
        // plyr.on('statechange', e => {
        //   if (e.detail.code === -1) {
        //     // if (ready && !_premiere) {
        //     //   setMuted(true);
        //     //   plyr.muted = true;
        //     plyr.play();
        //     // }
        //   }
        // });

        plyr.on('volumechange', () => {
            if (plyr.muted || !plyr.volume) {
                setMuted(true)
            } else {
                setMuted(false)
            }
        })
    }, [instance /* , miniPlayer, videoInfo */])

    useEffect(() => {
        if (!instance) return
        const { plyr } = instance
        if (!plyr) return

        if (poster) {
            plyr.on('ready', () => {
                plyr.poster = poster
            })
        }
    }, [instance, poster])

    useEffect(() => {
        if (!instance) return () => null
        const { plyr } = instance
        if (!plyr) return () => null

        if (!fallbackUrl) return () => null

        const fallback = () => {
            if (!premiere) {
                const provider = getProvider(fallbackUrl)

                setSource({
                    type: 'video',
                    sources: [
                        {
                            src: fallbackUrl,
                            provider
                        }
                    ]
                })
            }
        }

        plyr.on('error', fallback)

        return () => {
            // plyr.off('error', fallback)
        }
    }, [instance, fallbackUrl, premiere])

    useEffect(() => {
        if (status === 'STARTED') {
            console.log('started')
        }
    }, [status])

    const onRefChange = useCallback(
        (node: HTMLPlyrVideoElement) => {
            setInstance(node)
        },
        [setInstance]
    )

    const handleUnMutedClick = useCallback(() => {
        if (!instance) return
        const { plyr } = instance
        if (!plyr) return

        plyr.muted = false
    }, [instance])

    const handlePiPClick = useCallback(() => {
        if (!instance) return
        const { plyr } = instance
        if (!plyr) return

        if (!miniPlayer) {
            setVideoInfo({
                status,
                url,
                autoplay,
                currentTime: plyr.currentTime,
                fallbackUrl,
                id,
                isSponsor,
                poster,
                talkPath: pathname,
                isLive
            })
        }

        setMiniPlayer(!miniPlayer)
    }, [instance])

    return (
        <>
            <Plyr
                ref={onRefChange}
                source={source}
                playsInline
                fullscreen={!isPiP}
                autoplay={autoplay}
            />

            {isLive && <LiveStatus>AO VIVO</LiveStatus>}

            {!premiere && muted && !isPiP && (
                <PlayerMuted className="disable-audio" onClick={handleUnMutedClick}>
                    <MutedIcon /> Habilitar Áudio
                </PlayerMuted>
            )}

            {isPiP && (
                <BackTalkPiPButton
                    className="pip-back-talk"
                    onClick={() => {
                        if (videoInfo.talkPath) {
                            if (!miniPlayer) {
                                setVideoInfo({
                                    status,
                                    url,
                                    autoplay,
                                    currentTime,
                                    fallbackUrl,
                                    id,
                                    isSponsor,
                                    poster,
                                    talkPath: pathname,
                                    isLive
                                })
                            }

                            setMiniPlayer(!miniPlayer)
                            history.push(videoInfo.talkPath)
                        }
                    }}
                >
                    <FullscreenOutlined />
                </BackTalkPiPButton>
            )}

            {isPiP && (
                <ClosePiPButton className="pip-close" onClick={handlePiPClick}>
                    <CloseOutlined />
                </ClosePiPButton>
            )}

            {/* {children} */}
        </>
    )
}

export default VideoPlayer
