import styled, { css } from 'styled-components'
import { transparentize } from 'polished'
import { MentionsInput } from 'react-mentions'

interface StyledInputProps {
    color?: string
    error?: string
    rounded?: boolean
}

const ErrorCss = css`
    border-bottom: 1px solid ${({ theme }) => theme.main.danger};
    ${({ theme }) => `color: ${transparentize(0.5, theme.main.danger)}`};
`

const InputCss = css`
    width: 100%;
    border: none;
    background: none;
    padding: 0 10px;
    z-index: 1;
    overflow: auto;
    &::placeholder {
        color: white;
        opacity: 0.7;
    }
`

export const StyledMentionsInput = styled(MentionsInput)<StyledInputProps>`
    color: ${({ color }) => color};
    ${InputCss}
`

export const StyledInput = styled.input<StyledInputProps>`
    color: ${({ color }) => color};
    ${InputCss}
    div:first-child {
        max-height: 100px;
    }
`

const RoundedCss = css`
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    background-color: ${({
        theme: {
            main: { whiteOpacity }
        }
    }) => whiteOpacity};
`

export const StyledInputWrapper = styled.div<StyledInputProps>`
    flex: 1;
    width: 314px;
    max-width: 314px;
    position: relative;
    box-sizing: border-box ${({ error }) => error && ErrorCss};
    ${({ rounded }) => rounded && RoundedCss};
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
    min-height: 36px;
    font-size: 14px;
    min-height: 36px;
    background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};

    transition: 0.5s;
    &:hover {
        filter: brightness(90%);
        background-color: ${({ theme: { main } }) => main.darkOpacity};
        box-shadow: 0px 0px 0px 2px ${({ theme: { main } }) => main.highlight} inset;
    }
    &:active {
        filter: brightness(78%);
        transition: 0s;
    }

    label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: 0.2s;
        margin-top: -15px;
    }
    .mention {
        color: transparent;
    }
    div {
        div[class*='__suggestions'] {
            background-color: #000 !important;
            background: #000 !important;
        }
        > ul[role='listbox'] {
            backdrop-filter: blur(128px);
            background-color: ${({ theme: { main } }) => main.primaryBlur} !important;
            border-radius: 0 !important;
            > li {
                background-color: ${({ theme: { main } }) => main.primaryBlur} !important;
            }
            > li[aria-selected='true'] {
                background-color: ${({ theme: { main } }) => main.highlight} !important;
            }
        }
    }

    .anticon-smile {
        font-size: 20px;
    }

    @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
`

export const StyledLabel = styled.label<StyledInputProps>`
    color: ${({ color, error, theme }) => (error ? transparentize(0.5, theme.main.danger) : color)};
    position: absolute;
    &:hover {
        cursor: text;
    }
`

export const StyledIcon = styled.img`
    width: 15px;
    left: 5px;
    bottom: 10px;
`

export const ErrorWrapper = styled.div`
    margin: 10px 0;
    font-size: 12px;
`
