import React from 'react'
import { Form, Radio, Row, Col } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

const RadioInput: React.FC<IInputType> = ({ name, label, options, config }) => {
    return (
        <Wrapper>
            <Form.Item
                label={label}
                name={name}
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <Radio.Group>
                    <Row>
                        {options.map(option => (
                            <Col key={option.value}>
                                <Radio value={option.value}>{option.label}</Radio>
                            </Col>
                        ))}
                    </Row>
                </Radio.Group>
            </Form.Item>
        </Wrapper>
    )
}

export default RadioInput
