import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
    query GetNotifications($event: ObjectID!) {
        notifications(filter: { event: $event }) {
            edges {
                node {
                    id
                    title
                    message
                    icon {
                        url
                    }
                    button
                    link
                    isRead
                    isVisualized
                    createdAt
                }
            }
        }
    }
`
