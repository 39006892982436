import { Select as AntSelect } from 'antd'
import { rgba } from 'polished'
import styled from 'styled-components'

export const TitleContainer = styled.div`
    display: flex;
    margin: 15px 0;

    h4 {
        margin-right: 10px;
        padding-top: 5px;
    }
`

export const Select = styled(AntSelect)`
    color: ${({ theme: { main } }) => main.white};

    .ant-select-arrow {
        color: ${({ theme: { main } }) => main.white};
        padding-right: 16px;
        top: 42%;
    }

    &:not(.ant-select-customize-input) {
        > .ant-select-selector {
            background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
            border-color: transparent;
            height: 40px;
            border-radius: 20px;
            width: 300px;

            .ant-select-selection-placeholder {
                line-height: 40px;
                padding: 0 20px 0 0;
            }

            &:focus {
                background-color: ${({ theme: { v2 } }) => rgba(v2.highlight, 0.1)};
                border-color: ${({ theme: { v2 } }) => v2.highlight};
            }

            @media (max-width: ${({ theme }) => theme.sizes.xxxl}) {
                width: 150px;
            }
        }
    }
`
