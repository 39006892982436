import React from 'react'
import { SimpleModal } from 'commons/components'
import { useTranslation } from 'react-i18next'
import { Paragraph, Title } from '../Typography'

interface LogoutModalProps {
    isVisible: boolean
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
    logo?: string
}

const LogoutModal: React.FC<LogoutModalProps> = ({ isVisible, setIsVisible, logo }) => {
    const { t } = useTranslation('auth')

    const handleLogout = () => {
        localStorage.setItem('@react-web-example:id-event', '')
        localStorage.setItem('@react-web-example:id-user', '')
        localStorage.setItem('@react-web-example:token', '')
        window.location.href = '/'
        setIsVisible(false)
    }

    const handleCancel = () => {
        setIsVisible(false)
    }

    return (
        <SimpleModal
            isVisible={isVisible}
            okText={t('logout')}
            okAction={handleLogout}
            cancelText={t('logout.cancel')}
            cancelAction={handleCancel}
        >
            {logo && <img alt="customer logo" src={logo} className="customer-logo" />}
            <br />
            <Title fontSize={24}>{t('logout.message')}</Title>
            <Paragraph fontSize={14}>{t('logout.login-any-moment')}</Paragraph>
            <br />
        </SimpleModal>
    )
}

export default LogoutModal
