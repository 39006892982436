import React, { useState } from 'react'
import { Notification } from 'commons/gql/models/notification'
import { Avatar, Empty } from 'antd'
import { Title, Paragraph, ButtonV2 } from 'commons/components'
import ptBR from 'date-fns/locale/pt-BR'

import { useTranslation } from 'react-i18next'
import { formatDistance } from 'date-fns'
import { NotificationContainerScroll, NotificationItem, NotificationItemContent } from '../styles'

interface Props {
    notifications: Notification[]
    onClick: (id: string) => void
}

const NotificationList: React.FC<Props> = ({ notifications, onClick }) => {
    const { t } = useTranslation('commons')

    const EmptyDescription = (
        <Title align="center" fontSize={12}>
            {t('no.notifications')}
        </Title>
    )

    return (
        <NotificationContainerScroll>
            {!notifications.length ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={EmptyDescription} />
            ) : null}
            {notifications.map(item => (
                <NotificationItem key={item.id} active={!item.isRead}>
                    <Avatar size={36} src={item.icon?.url} />
                    <NotificationItemContent>
                        <Title fontSize={12} bold>
                            {item.title}
                        </Title>
                        {item.createdAt && (
                            <Paragraph fontSize={10}>
                                {formatDistance(new Date(item.createdAt), new Date(), {
                                    addSuffix: true,
                                    locale: ptBR
                                })}
                            </Paragraph>
                        )}

                        {item.message && <Paragraph fontSize={12}>{item.message}</Paragraph>}

                        <ButtonV2
                            onClick={() => onClick(item.id)}
                            type={item.isRead ? 'ghost' : 'primary'}
                            target="_blank"
                            href={item.link}
                            borderRadius={60}
                        >
                            {item.button || t('know.more')}
                        </ButtonV2>
                    </NotificationItemContent>
                </NotificationItem>
            ))}
        </NotificationContainerScroll>
    )
}

export default NotificationList
