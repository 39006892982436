import styled from 'styled-components'

import { Badge as AntdBadge } from 'antd'

export const Wrapper = styled.div`
    display: contents;

    & .ant-badge {
        width: 115px;

        padding: 13px;
        font-size: 12px;

        border-style: solid;
        border-width: 2px;
        border-color: ${({ theme: { main } }) => main.white};
        border-radius: 20px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`
export const StyledBadge = styled(AntdBadge)``
