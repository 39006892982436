import AppProvider from 'commons/providers/AppProvider'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Router from './routing'
import 'antd/dist/antd.css'

function App() {
    return (
        <AppProvider>
            <Suspense fallback="">
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </Suspense>
        </AppProvider>
    )
}

export default App
