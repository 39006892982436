import React from 'react'

import { Paragraph } from 'commons/components'
import Calendar from 'commons/assets/icons/calendar.svg'

import {
    BottomContainerLoading,
    LastTalksCardSkeleton,
    NotFoundContainer,
    TopContainerLoading,
    NotFound
} from './styles'
import { TopContainer, BottomContainer } from '../styles'

import { useTalksText } from '../texts'

export const LoadingState = () => {
    const SkeletonItem = () => (
        <LastTalksCardSkeleton>
            <TopContainerLoading />
            <BottomContainerLoading />
        </LastTalksCardSkeleton>
    )

    return (
        <>
            <NotFoundContainer>
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
                <SkeletonItem />
            </NotFoundContainer>
        </>
    )
}

export const Skeleton = () => (
    <LastTalksCardSkeleton>
        <TopContainer>
            <div />
        </TopContainer>
        <BottomContainer />
    </LastTalksCardSkeleton>
)

export const NotFoundRow = () => {
    const { notFoundLastTalks } = useTalksText()
    return (
        <NotFoundContainer>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <NotFound>
                <img src={Calendar} alt="" />
                <Paragraph>{notFoundLastTalks}</Paragraph>
            </NotFound>
        </NotFoundContainer>
    )
}
