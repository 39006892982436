import React from 'react'
import { Col, FormInstance, Row } from 'antd'

import { Title } from 'commons/components'
import * as Inputs from 'commons/components/Forms/inputs'

import { EventForm } from 'commons/gql/models/eventForm'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useTheme } from 'styled-components'
import { Section } from '..'

interface FormStepProps {
    section: Section
    step: number
    form: FormInstance<any>
    progressiveForm: EventForm
}

const FormStep = ({ section, step, form, progressiveForm }: FormStepProps) => {
    const { main } = useTheme()
    const arrowStyle = {
        color: main.highlight,
        margin: '0px 12px 12px 12px',
        fontSize: '20px'
    }
    return (
        <>
            {section?.title ? (
                <Row align="middle">
                    <Title level={2} color="highlight">
                        {step}
                    </Title>
                    <ArrowRightOutlined style={arrowStyle} />
                    <Title level={2}>{section.title}</Title>
                </Row>
            ) : null}
            {section.fields.map(f => {
                const Input = Inputs[f.type as keyof typeof Inputs] || Inputs.text
                return (
                    <Col key={f.name} span={f.config.size || 24}>
                        <Input {...f} form={form} formData={progressiveForm} />
                    </Col>
                )
            })}
        </>
    )
}

export default FormStep
