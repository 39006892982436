import {
    InfoCircleFilled,
    InfoCircleOutlined,
    InstagramFilled,
    InstagramOutlined
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import {
    CertificateFill,
    CertificateOutline,
    HomeFill,
    HomeOutline,
    NetworkingFill,
    NetworkingOutline,
    PlayFill,
    PlayOutline,
    RewardFill,
    RewardOutline,
    ScheduleFill,
    ScheduleOutline,
    SpeakersFill,
    SpeakersOutline,
    SponsorsFill,
    SponsorsOutline,
    Map
} from 'commons/assets/icons'
import NetworkNotification from 'commons/components/NetworkNotification'
import { ROUTES } from 'commons/constants'
import { GET_EVENT_INTEGRATIONS } from 'commons/gql/queries/event'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { ReactNode, useMemo } from 'react'
import { EVENT_MENU_VERIFY } from 'routing/gql/queries'
import { IntegrationData } from 'screens/Feed'

import { usePermissions } from './usePermissions'

interface QueryMenuVerify {
    brands: {
        totalCount: number
    }
    talks: {
        totalCount: number
    }
    event: {
        viralCampaign?: {
            id: string
        }
        certificate?: {
            enabled: boolean
        }
    }
}

interface MenuItem {
    path: string
    name: string
    Selected: ReactNode
    UnSelect: ReactNode
    show?: any
    Extra?: any
}

export const useMenu = () => {
    const { idEvent } = useAuth()

    const { data, loading } = useQuery<QueryMenuVerify>(EVENT_MENU_VERIFY, {
        variables: { id: idEvent }
    })

    const permissions = usePermissions()

    const { data: integrationsData } = useQuery<IntegrationData>(GET_EVENT_INTEGRATIONS, {
        variables: {
            event: idEvent
        }
    })

    const instagram = useMemo(() => {
        if (integrationsData) {
            const instagramIntegration = integrationsData?.event?.integrations.find(
                ({ name }) => name === 'Juicer'
            )
            return instagramIntegration
        }
        return null
    }, [integrationsData])

    const routes: Record<string, MenuItem> = useMemo(
        () => ({
            home: {
                path: ROUTES.home,
                name: 'sidebar.home',
                Selected: <HomeFill />,
                UnSelect: <HomeOutline />,
                show: true
            },
            networking: {
                path: ROUTES.networking,
                name: 'sidebar.network',
                Selected: <NetworkingFill />,
                UnSelect: <NetworkingOutline />,
                Extra: <NetworkNotification />,
                show: permissions.includes('NETWORKING')
            },
            ondemand: {
                path: '/home/ondemand',
                name: 'sidebar.ondemand',
                Selected: <PlayFill />,
                UnSelect: <PlayOutline />,
                show: permissions.includes('ONDEMAND') && data && data?.talks.totalCount > 0
            },
            sponsors: {
                path: '/home/sponsors',
                name: 'sidebar.sponsors',
                Selected: <SponsorsFill />,
                UnSelect: <SponsorsOutline />,
                show: (data?.brands?.totalCount || 0) > 0
            },
            referral: {
                path: ROUTES.referral,
                name: 'sidebar.referral',
                Selected: <RewardFill />,
                UnSelect: <RewardOutline />,
                show: !!data?.event?.viralCampaign?.id
            },
            certificate: {
                path: '/home/certificate',
                name: 'sidebar.certificate',
                Selected: <CertificateFill />,
                UnSelect: <CertificateOutline />,
                show: permissions.includes('CERTIFICATE') && data?.event?.certificate?.enabled
            },
            schedule: {
                path: ROUTES.schedule,
                name: 'sidebar.schedule',
                Selected: <ScheduleFill />,
                UnSelect: <ScheduleOutline />,
                show: !!idEvent
            },
            lecturers: {
                path: ROUTES.lecturers,
                name: 'sidebar.lecturers',
                Selected: <SpeakersFill />,
                UnSelect: <SpeakersOutline />,
                show: !!idEvent
            },
            feed: {
                path: ROUTES.feed,
                name: 'sidebar.feed',
                Selected: <InstagramFilled />,
                UnSelect: <InstagramOutlined />,
                show: idEvent && instagram?.active
            },
            'event-info': {
                path: ROUTES.eventInfo,
                name: 'sidebar.event-info',
                // Selected: <InfoCircleOutlined />,
                // UnSelect: <InfoCircleFilled />,
                Selected: <Map />,
                UnSelect: <Map />,
                show: !!idEvent
            }
        }),
        [data, permissions, idEvent, instagram]
    )

    if (loading) return null

    return {
        routes
    }
}
