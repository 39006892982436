import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import { ReactComponent as UserIcon } from 'commons/assets/icons/user-v2.svg'

import formatListSpeakersHome from 'commons/helpers/formatListSpeakersHome'
import useViewport from 'commons/hooks/useViewport'
import { VirtualizedCarousel, Title, Loader, SpeakerCardV3 } from 'commons/components'
import { GET_SPEAKERS } from 'commons/gql/queries/speakers'
import { useAuth } from 'commons/providers/AuthProvider'
import { ChevronRight } from 'commons/assets/icons'
import { ROUTES } from 'commons/constants'

import { SpeakersData } from '../types'
import { SpeakersProps, TalksIds } from './types'
import { StyledLink, TitleContainer, Wrapper } from './styles'

const Speakers = (props: SpeakersProps) => {
    const {
        orientation = 'vertical',
        talk,
        notitle = false,
        showAllButton = false,
        color,
        speakersData,
        isHome,
        titleSize,
        boxType
    } = props
    const { vw } = useViewport()
    const { idEvent } = useAuth()

    const { t } = useTranslation('home')
    const [formattedValues, setFormattedValues] = useState<any>([])

    const isHorizontal = orientation === 'horizontal'
    const [getSpeakers, { data, loading, refetch }] = useLazyQuery<SpeakersData, TalksIds>(
        GET_SPEAKERS,
        {
            fetchPolicy: 'no-cache'
        }
    )

    useEffect(() => {
        if (!speakersData) {
            getSpeakers({
                variables: {
                    event: idEvent as string,
                    talk,
                    first: 10,
                    orderBy: {
                        priority: 'DESC',
                        firstName: 'ASC'
                    }
                }
            })
        } else {
            const _formattedValues = formatListSpeakersHome(speakersData) || []
            setFormattedValues([..._formattedValues])
        }
    }, [speakersData])

    useEffect(() => {
        if (data) {
            const _formattedValues = formatListSpeakersHome(data.speakers) || []
            setFormattedValues([...formattedValues, ..._formattedValues])
        }
    }, [data])

    if (loading) {
        return <Loader />
    }

    if (formattedValues?.length === 0) return null

    const renderSpeakersCard = ({ style, index }: { index: number; style: any }) => {
        return (
            <SpeakerCardV3
                styles={style}
                speaker={formattedValues[index]}
                showSocial={false}
                isHorizontal={isHorizontal}
                boxType={boxType}
            />
        )
    }

    const height = vw > 767 ? 220 : 250

    const { pageInfo } = data?.speakers || {}

    const getMoreItems = () => {
        if (pageInfo?.hasNextPage && refetch) {
            refetch({
                after: pageInfo?.endCursor,
                first: 10
            })
        }
    }

    return (
        <Row
            style={{ width: '100%' }}
            className={isHome ? 'mb-50 mr-40 mb-mr-0 mb-pdr-20 mb-pdl-20 speakers' : 'speakers'}
        >
            <Col span={24} className="title-pdl-20">
                <Wrapper orientation={orientation}>
                    <TitleContainer>
                        {!notitle && (
                            <Title level={3} color={color} fontSize={titleSize}>
                                {t('trails.speaker.list-title')}
                            </Title>
                        )}

                        {showAllButton && (
                            <StyledLink to={ROUTES.lecturers}>
                                <UserIcon style={{ marginRight: '10px' }} />
                                {t('trails.speaker.all')}
                                <ChevronRight className="arrow-right-icon" />
                            </StyledLink>
                        )}
                    </TitleContainer>

                    {formattedValues.length ? (
                        <Col span={24} className="plr-0">
                            <VirtualizedCarousel
                                getMoreItems={getMoreItems}
                                hasNext={pageInfo?.hasNextPage}
                                height={isHorizontal ? 100 : height}
                                gutter={isHome ? 32 : 16}
                                width={isHorizontal ? 210 : 180}
                                items={formattedValues}
                                Component={renderSpeakersCard}
                                borderRadius={20}
                            />
                        </Col>
                    ) : null}
                </Wrapper>
            </Col>
        </Row>
    )
}

export default Speakers
