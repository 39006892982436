import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { GET_SPONSOR_TALKS } from 'commons/gql/queries/talks'
import { GET_EVENT_LOGO } from 'commons/gql/queries/event'
import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'

import { TalksData, EventQueryData } from '../../types'
import { LoadingState, NotFoundRow } from '../Skeleton'
import { useTalks } from '../useTalks'
import Carousel from '../Carousel'
import { useTalksText } from '../texts'
import { TalkTitle } from '../styles'

interface Brands extends ById {
    brand: string
}

const TalksCarrousel = () => {
    const { idEvent } = useAuth()
    const { sponsorId } = useParams<{ sponsorId?: string }>()
    const { sponsorTalks } = useTalksText()

    const { data } = useQuery<TalksData, Brands>(GET_SPONSOR_TALKS, {
        variables: {
            event: idEvent as string,
            brand: sponsorId as string
        },
        pollInterval: 90000,
        fetchPolicy: 'no-cache'
    })

    const { data: event } = useQuery<EventQueryData, ById>(GET_EVENT_LOGO, {
        variables: {
            event: idEvent as string
        }
    })

    const talks = data ? [...data.talks.edges] : []
    const { talkInfos } = useTalks(talks)

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {sponsorTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = event?.event?.logo?.url

    if (talkInfos && !talkInfos.length) {
        return null
    }

    return (
        <div className="sponsors">
            <TalkTitle fontSize={20} level={4}>
                {sponsorTalks}
            </TalkTitle>
            {talkInfos && talkInfos.length ? (
                <Carousel items={talkInfos} defaultThumbnail={defaultThumbnail} />
            ) : (
                <NotFoundRow />
            )}
        </div>
    )
}

export default TalksCarrousel
