import React, { ButtonHTMLAttributes } from 'react';

import { StyledButton } from './styles';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  icon: string;
  width?: number;
}

const FiltersButton = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  const { icon, children, width, ...rest } = props;

  return (
    <StyledButton as="button" type="button" width={width} ref={ref} {...rest}>
      <img src={icon} alt="" width="20" />
      {children}
    </StyledButton>
  );
});

export default FiltersButton;
