import React from 'react'

import { StyledToolTip } from './styles'

const Tooltip = ({
    children,
    bottom,
    maxWidth
}: {
    children: React.ReactNode
    bottom?: boolean
    maxWidth?: string
}) => (
    <StyledToolTip bottom={bottom} maxWidth={maxWidth}>
        {children}
    </StyledToolTip>
)

export default Tooltip
