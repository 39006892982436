import React from 'react'
import InputMaskComponent from 'react-input-mask'

import { Input, InputProps } from 'antd'

interface Props extends InputProps {
  mask: string | ((value: string | undefined) => string)
}

const InputMask: React.FC<Props> = ({
  disabled,
  onChange,
  value,
  mask,
  ...rest
}) => {
  return (
    <InputMaskComponent
      mask={
        typeof mask === 'function'
          ? mask(value ? value.toString() : undefined)
          : mask
      }
      disabled={disabled}
      value={value}
      onChange={onChange}
    >
      {(inputProps: any) => (
        <Input {...inputProps} {...rest} disabled={disabled} />
      )}
    </InputMaskComponent>
  )
}

export default InputMask
