import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useViewport from 'commons/hooks/useViewport'

import { SponsorCard, VirtualizedCarousel } from 'commons/components'
// import { Brand } from 'commons/gql/models/brand'
import { BrandsProps } from '../types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface Props extends BrandsProps {
    data: any
}

const Carousel: FC<Props> = props => {
    const { data, children, light } = props
    const { push } = useHistory()

    const { vw } = useViewport()

    const handleClick = (id: string) => {
        push(`/home/sponsors/${id}/detail`)
    }

    const renderSponsorCard = ({ style, index }: { index: number; style: any }) => {
        const { id, logo, type, description, standUrl } = data[index].node
        return (
            <SponsorCard
                style={style}
                key={id}
                description={description}
                logo={logo?.url}
                type="Carrousel"
                light={light}
                overlay
                onClick={() => {
                    handleClick(id)
                }}
            />
        )
    }

    if (!data || data.length === 0) return null

    return (
        <div style={{ width: '100%' }}>
            {children}
            <VirtualizedCarousel
                height={50}
                width={100}
                gutter={20}
                items={data}
                Component={renderSponsorCard}
            />
        </div>
    )
}

export default Carousel
