import { gql } from '@apollo/client'

export const EVENT_SPONSOR_LOGO = gql`
    fragment EventSponsorsLogo on Event {
        brands {
            edges {
                node {
                    id
                    featured
                    logo {
                        url
                    }
                }
            }
        }
    }
`

export const EVENT_SPONSOR_LINK = gql`
    fragment EventSponsorsLink on Event {
        brands {
            edges {
                node {
                    talkStand {
                        url
                        type
                    }
                }
            }
        }
    }
`

export const EVENT_SPONSOR_DETAIL = gql`
    fragment EventSponsorsDetail on Event {
        brands {
            edges {
                node {
                    id
                    name
                    description
                    talkStand {
                        url
                        type
                    }
                    logo {
                        url
                    }
                }
            }
        }
    }
`
