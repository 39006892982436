import { useState, useEffect, useCallback, useRef } from 'react'

export default function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
    const ref = useRef<HTMLDivElement | null>(null)

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false)
        }
    }

    const toggle = useCallback(() => {
        setIsComponentVisible(!isComponentVisible)
    }, [isComponentVisible])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    return { ref, isComponentVisible, setIsComponentVisible, toggle }
}
