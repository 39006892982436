import styled, { css } from 'styled-components'
import { StyledToolTip } from 'commons/components/Tooltip/styles'

export const Overlay = styled.div`
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    > * {
        z-index: 2;
    }
`

export const StyledPlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    opacity: 0;
    transition: 0.5s ease;
    z-index: 3;
`
interface StyledContainerProps {
    image?: string
    defaultThumbnail?: string
    onClick?: () => void
    $full?: boolean
}
export const StyledContainer = styled.div<StyledContainerProps>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 420px;
    height: 230px;
    color: #fff;
    border-radius: 20px;
    justify-content: space-between;
    background: transparent;
    cursor: pointer;
    position: relative;
    ${({ $full }) =>
        $full &&
        css`
            width: 100%;
            height: 100%;
        `}

    &:hover ${StyledPlay} {
        opacity: 0.9;
    }
    &:hover ${Overlay} {
        opacity: 1;
    }
`

export const TopWrapper = styled.div<StyledContainerProps>`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    height: 76%;
    color: #fff;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    ${({ image, defaultThumbnail }) =>
        image
            ? `
          background: url('${image}') no-repeat;
          background-position: center;
          background-size: cover;
        `
            : `
          background: url('${defaultThumbnail}') no-repeat;
          background-position: center;
          background-size: 90%;
          background-color: rgba(255, 255, 255, 0.05);
        `}
`
export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    padding: 0;
    position: absolute;
    top: 6px;
    z-index: 10;
    width: 100%;
    padding: 10px;
    padding-top: 5px;
`

export const TopContainerInner = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

    span {
        padding: 4px 8px;
        border-radius: 30px;
        background-color: ${({ theme }) => theme.main.darkOpacity};
    }

    button {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: bold;
        padding: 8px;
        border-radius: 30px;
        outline: 0;
        border: 0;
        background: transparent;

        transition: background-color 0.3s;

        img {
            margin-right: 16px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
`

export const BottomContainer = styled.div`
    display: flex;
    height: 24%;
    padding: 2% 4%;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    ${({ theme }) => {
        return css`
            background-color: ${theme.main.primaryBlur};
            position: relative;
        `
    }}

    h1 {
        font-size: 16px;
        &:hover > ${StyledToolTip} {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s;
        }
    }
    h2 {
        font-size: 16px;
        font-weight: 300;
    }

    /* .ant-typography {
        padding: 5px 10px;
    } */
`

export const InnerContent = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 20px;
`
export const Wrapper = styled.div`
    position: relative;
    height: 100%;
`
