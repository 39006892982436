export const parseProps = (props: any) => {
    const _props: any = {}
    const typeProps = [
        'marginTop',
        'marginBottom',
        'marginLeft',
        'marginRight',
        'bold',
        'fontSize',
        'align',
        'color',
        'regular',
        'linebreak',
        'transform'
    ]
    Object.keys(props).forEach(key => {
        if (typeProps.indexOf(key) >= 0) {
            const _key = `$${key}`
            _props[_key] = props[key]
        } else {
            _props[key] = props[key]
        }
    })

    return _props
}
