import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

export const StyledListTitle = styled.h1`
    font-size: 20px;
`

export const NotFoundContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
`

export const NotFound = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > p {
        margin-top: 16px;
        font-size: 25px;
    }
    @media screen and (max-width: 767px) {
        width: 90%;
        & > p {
            font-size: 22px;
        }
    }
`

export const TodayTalksCardSkeleton = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 420px;
    height: 230px;
    color: #fff;
    border-radius: 20px;
    justify-content: space-between;
    background: transparent;
    background-position: center;
    background-size: 100%;
    background: rgba(255, 255, 255, 0.05);

    & + div {
        margin-left: 16px;
    }
`

const TopContainerCss = css`
    display: flex;
    padding: 8px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);

    div {
        width: 55px;
        height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
    }
`

export const TopContainerLoading = styled(Skeleton)`
    ${TopContainerCss};
`

export const TopContainer = styled.div`
    ${TopContainerCss};
`

const BottomContainerCss = css`
    display: flex;
    padding: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
`

export const BottomContainerLoading = styled(Skeleton)`
    ${BottomContainerCss};
`

export const BottomContainer = styled.div`
    ${BottomContainerCss};
`

export const UpcomingTalksContainer = styled.div<{ marginTop?: number }>`
    ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px`}
`
