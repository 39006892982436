import React from 'react'
import truncateString from 'commons/helpers/truncateString'
import { Tooltip } from 'commons/components'
import { parseProps } from '../utils'

import { StyledText } from './styles'
import { CustomTypography } from '../types'

const Text: React.FC<CustomTypography> = ({ children, ...props }) => {
    const { truncate } = props
    const size = children?.toString().length
    const content = children?.toString()
    const parsedProps = parseProps(props)

    return (
        <StyledText {...parsedProps}>
            {content && truncate && size && size > truncate ? (
                <>
                    {truncateString(content, truncate)}
                    <Tooltip bottom>{children}</Tooltip>
                </>
            ) : (
                <>{children}</>
            )}
        </StyledText>
    )
}

export default Text
