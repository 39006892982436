import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ScallingContainer, Overlay, ButtonV2, Paragraph } from 'commons/components'
import { useGrid } from 'commons/components/Brands/utils'
import { ListType } from 'commons/components/Brands/types'
import { Media } from 'commons/gql/models/media'
import { LogoGoogle } from '@styled-icons/ionicons-solid'
import { Logo, StyledSponsorsCard, SponsorWrapper } from './styles'

export interface SponsorsCardProps {
    logo: Media | string
    className?: string
    name?: string
    onClick?: () => void
    chat?: boolean
    description?: string
    image?: Media | string
    overlay?: boolean
    light?: boolean | null | undefined
    type: ListType
    minHeight?: number | null | undefined
    style?: React.CSSProperties
    openModal?: () => void
    sponsorLink?: boolean
    sponsorPage?: boolean
}

const SponsorsCard: React.FC<SponsorsCardProps> = ({ ...props }) => {
    const {
        image,
        name,
        onClick,
        description,
        overlay,
        type,
        light,
        logo,
        className,
        chat,
        style,
        sponsorLink,
        sponsorPage
    } = props

    const { t } = useTranslation('buttons')
    const infos = useGrid(type)
    const { card } = infos
    const logoUrl = typeof logo === 'string' ? logo : logo?.url
    const imageUrl = typeof image === 'string' ? image : image?.url

    const classString = `sponsor-card sponsor-card-${card}`

    const clickAble = useMemo(() => {
        if ((sponsorLink || sponsorPage) && onClick) {
            return true
        }
        return false
    }, [sponsorLink, sponsorPage, onClick])

    if (type !== 'Carrousel') {
        return (
            <SponsorWrapper>
                <ScallingContainer
                    className={`${classString} box-sponsor`}
                    resolutions={infos}
                    minWidth={330}
                    minHeight={170}
                    justify="space-around"
                    onClick={onClick}
                    data-sponsor-name={name}
                    clickAble={clickAble}
                >
                    {card === 'XG' && (
                        <ScallingContainer
                            image={
                                imageUrl ||
                                'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/defaultBackground-1623880576165.png'
                            }
                            minHeight={101} /* 0,365625 */
                            minWidth={275}
                        />
                    )}
                    <Logo className="sponsor-card-logo logo" src={logoUrl} />
                    {(card === 'XG' || card === 'G' || card === 'M') && (
                        <>
                            <Paragraph rows={4} fontSize={16}>
                                {description}
                            </Paragraph>
                            {clickAble && (
                                <ButtonV2
                                    full
                                    className="btn-sponsorcard"
                                    type="primary"
                                    shape="round"
                                >
                                    {t('btn.know-more')}
                                </ButtonV2>
                            )}
                        </>
                    )}
                </ScallingContainer>
            </SponsorWrapper>
        )
    }

    return (
        <StyledSponsorsCard
            style={style}
            className={className || ''}
            small
            url={logoUrl}
            chat={chat}
            onClick={() => onClick && onClick()}
            overlay={overlay}
            light={light}
        />
    )
}

export default SponsorsCard
