import { gql } from '@apollo/client'
import EVENT_DATE from './fragments/eventDate'

export const GET_EVENT_VIRAL_CAMPAIGN = gql`
    query EventViralCampaign($id: ObjectID!) {
        event(id: $id) {
            id
            viralCampaign {
                id
                name
            }
        }
    }
`

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        me {
            name
            avatar {
                url
            }
        }
    }
`

export const GET_EVENT_DATE = gql`
    query GetEventDate($id: ObjectID) {
        event(id: $id) {
            id
            ...EventDate
        }
    }
    ${EVENT_DATE}
`

export const GET_EVENT = gql`
    query GetEvent($id: ObjectID) {
        event(id: $id) {
            id
            menus {
                main {
                    name
                    url
                    icon
                    visible
                }
                user {
                    name
                    url
                    icon
                    visible
                }
            }
            categories {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`

export const GET_EVENT_CONFIG = gql`
    query GetEventConfig($id: ObjectID) {
        event(id: $id) {
            id
            showNumberOfViewers
            showReactions
            showNumberOfRegistrations
            showNumberOfSpeakers
            showDates
            showLiveSponsor
            showLiveChat
            showSpeaperkSocialInfo
            chatConfig {
                active
                slowMode {
                    active
                    duration
                }
                spanMode {
                    active
                    duration
                }
            }
            showSocialAuth
            translation {
                languages
            }
        }
    }
`
