import { gql } from '@apollo/client'

export const GET_CATEGORIES_SHORT = gql`
    query Categories($id: ObjectID) {
        categories(filter: { event: $id }, orderBy: { name: ASC }) {
            totalCount
            edges {
                node {
                    id
                    name
                    type
                    talksTotalCount
                }
            }
        }
    }
`
