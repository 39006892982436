import React from 'react'
import { Route as ReactDOMRoute, Redirect, RouteProps } from 'react-router-dom'
import { PlayerPiPProvider } from 'screens/Streaming/Players/VideoPlayerV2/context'
import VideoPlayerPiP from '../ResizablePiP'

interface Props extends RouteProps {
    component: React.ComponentType
    path: string
    exact?: boolean
    redirectTo?: string
    shouldRedirect?: boolean
}

const Route = (props: Props) => {
    const { component: Component, path, exact = false, shouldRedirect = false, redirectTo } = props

    return (
        <ReactDOMRoute
            path={path}
            exact={exact}
            render={({ location }) => {
                if (!shouldRedirect) {
                    return (
                        <PlayerPiPProvider>
                            <Component />
                            <VideoPlayerPiP />
                        </PlayerPiPProvider>
                    )
                }

                return (
                    <Redirect
                        to={{
                            pathname: redirectTo,
                            state: { from: location }
                        }}
                    />
                )
            }}
        />
    )
}

export default Route
