import React from 'react'

import { IframeProps } from './types'

const IFrame: React.FC<IframeProps> = ({ iframe }) => {
    if (!iframe) return null

    return (
        <iframe
            title="Player"
            allowFullScreen
            frameBorder="0"
            scrolling="yes"
            src={iframe.url}
            width="100%"
            height="100%"
        />
    )
}

export default IFrame
