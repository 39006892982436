import { gql } from '@apollo/client';

export const EVENT_SPEAKERS = gql`
  fragment EventSpeakers on Speaker {
    id
    name
    image {
      url
    }
    office
    company {
      name
    }
  }
`;

export const LIVE_SPEAKERS = gql`
  fragment LiveSpeakers on Speaker {
    ...EventSpeakers
    links {
      url
      type
    }
  }

  ${EVENT_SPEAKERS}
`;
