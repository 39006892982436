import React, { createContext, useContext } from 'react';

export type ModalContextType = {
    modal?: any;
    setModal: (modal: any) => void;
}

export const ModalContext = createContext<ModalContextType>({ 
    modal: {}, 
    setModal: modal => {}
})
export const useModal = () => useContext(ModalContext)