import React from 'react'
import { useHistory } from 'react-router-dom'

import { ReactComponent as Arrow } from 'commons/assets/icons/chevron-left.svg'

import { IconWrapper } from './styles'

interface Props {
    route: string
}

const BackButton = (props: Props) => {
    const { push } = useHistory()
    const { route } = props

    return (
        <IconWrapper onClick={() => push(route)}>
            <Arrow />
        </IconWrapper>
    )
}

export default BackButton
