import styled, { css } from 'styled-components'
import { HTMLAttributes } from 'react'

interface StyledSponsorsCardProps extends HTMLAttributes<HTMLDivElement> {
    url: string | null | undefined
    small?: boolean | null | undefined
    chat?: boolean | null | undefined
    overlay?: boolean | null | undefined
    light?: boolean | null | undefined
    clickAble?: boolean | null | undefined
}

export const SponsorWrapper = styled.div`
    display: contents;
`

export const StyledSponsorsCard = styled.div<StyledSponsorsCardProps>`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(${({ url }) => url});
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: 70% 70%;
        background-position: center;
        height: 100%;
        width: 100%;

        ${({ theme: { main } }) =>
            main.invertLogoColors &&
            css`
                filter: brightness(0) invert(1);
            `}
        z-index: 3;
        border-radius: 10px;
    }
    border-radius: 10px;
    height: ${({ small }) => (small ? '50px' : '80px')};
    width: ${({ small }) => (small ? '100px' : '100%')};

    ${({ onClick }) => onClick && 'cursor: pointer'};

    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.md}) {
            text-align: center;
        }
    `}
`

interface CardFullProps {
    mainImage?: string
    url?: string
    color?: string
    background?: string
}

export const TopImage = styled.div<CardFullProps>`
    border-radius: 20px;
    width: 100%;
    height: 200px;
    background: ${({ mainImage }) => (mainImage ? `url(${mainImage})` : '#000')};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`

export const Logo = styled.img<CardFullProps>`
    margin: 20px 0;
    max-height: 60px;
    width: 100%;
    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.sm}) {
            min-width: 80px;
        }
    `}
`

export const CardFull = styled.div<CardFullProps>`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 20px;
    padding: 25px;
    flex: 1;
`
