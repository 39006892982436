import React, { forwardRef, InputHTMLAttributes } from 'react'
import Loader from 'commons/components/Loader'
import { StyledInput, StyledLabel, StyledInputWrapper, StyledIcon, ErrorWrapper } from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    color?: string
    icon?: string
    error?: string
    label?: string
    rounded?: boolean
    loading?: boolean
    darker?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        onChange,
        placeholder,
        label,
        color,
        icon,
        name,
        type,
        error,
        value,
        loading,
        onKeyDown,
        rounded,
        disabled,
        className
    } = props

    const renderLabel = () => (
        <StyledLabel htmlFor={label} data-testid="label" error={error} color={color}>
            {label}
        </StyledLabel>
    )

    return (
        <StyledInputWrapper className={className} rounded={rounded} error={error}>
            {icon && <StyledIcon src={icon} alt="" />}
            <StyledInput
                id={label}
                role="textbox"
                onChange={onChange}
                value={value}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                color={color}
                name={name}
                type={type}
                disabled={disabled}
                ref={ref}
            />
            {label && renderLabel()}
            {error && <ErrorWrapper>{error}</ErrorWrapper>}
            {loading && <Loader size={30} />}
        </StyledInputWrapper>
    )
})

export default Input
