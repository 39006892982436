import React, { useState } from 'react'
import PIP from '../PiP'
import 'react-resizable/css/styles.css'
import { StyledResizableBox } from './styles'

type Props = {
    widthInPx: number
    heightInPx: number
    minConstraints: any
    maxConstraints: any
    children?: React.ReactChild | React.ReactChildren
}

const ResizablePiP: React.FC<Props> = ({
    children,
    widthInPx = 100,
    heightInPx = 100,
    minConstraints = [300, 300],
    maxConstraints = [800, 800]
}: Props) => {
    const [widthLenght, setSize] = useState([widthInPx, heightInPx])

    return (
        <PIP>
            <StyledResizableBox
                onResizeStart={(event, { size }) => {
                    setSize([size.width, size.height])
                    event.stopPropagation()
                }}
                width={widthLenght[0]}
                height={widthLenght[1]}
                minConstraints={minConstraints}
                maxConstraints={maxConstraints}
                lockAspectRatio
                axis="none"
            >
                {children}
            </StyledResizableBox>
        </PIP>
    )
}

export default ResizablePiP
