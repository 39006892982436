import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Container } from './styles'

interface HamburgerMenuProps {
    handleOpenMenu: () => void
}

const HamburguerMenu = (props: HamburgerMenuProps) => {
    const { handleOpenMenu } = props
    return (
        <Container onClick={handleOpenMenu}>
            <MenuOutlined />
        </Container>
    )
}

export default HamburguerMenu
