import React from 'react'

import Play from 'commons/assets/icons/play.svg'
import { Paragraph, Text } from 'commons/components'
import {
    StyledContainer,
    TopContainer,
    TopWrapper,
    BottomContainer,
    StyledPlay,
    InnerContent,
    Overlay,
    TopContainerInner
} from './styles'

interface Props {
    onClick?: () => void
    title: string
    id?: string
    subTitle?: string
    hour?: string
    thumbnail: string
    defaultThumbnail?: string
    style?: any
    full?: boolean
    titleSize?: number
}

const TodayTalksCard = (props: Props) => {
    const { hour, title, thumbnail, defaultThumbnail, onClick, style = {}, full, titleSize } = props
    const [day, time] = hour ? hour.split(' ') : []

    return (
        <StyledContainer style={style} className="virtualized-card box-main" $full={full}>
            <TopContainer>
                <TopContainerInner>
                    {hour && <Text>{time}</Text>}
                    {hour && <Text>{day}</Text>}
                </TopContainerInner>
            </TopContainer>
            <InnerContent onClick={onClick}>
                <TopWrapper image={thumbnail} defaultThumbnail={defaultThumbnail}>
                    {onClick && (
                        <StyledPlay>
                            <img src={Play} alt="" />
                        </StyledPlay>
                    )}
                    <Overlay />
                </TopWrapper>
                <BottomContainer className="box-bgColor">
                    <Paragraph fontSize={titleSize || 14} rows={2}>
                        {title}
                    </Paragraph>
                </BottomContainer>
            </InnerContent>
        </StyledContainer>
    )
}

export default TodayTalksCard
