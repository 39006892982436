import styled from 'styled-components'
import { Layout } from 'antd'

const { Content } = Layout

export const StyledContainer = styled(Content)`
    height: 100%;
    display: flex;
    position: fixed;
    width: 100%;
    max-width: 100%;
`

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const StyledPageContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-y: auto;
    padding: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    scroll-behavior: smooth;
    @media (min-width: 1024px) and (max-width: 1366px) {
        /* width: calc(100% - 125px); */
    }
    @media (max-width: 1023px) {
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        padding-right: 0;
        // margin: 0;
        // width: 100%;
    }
`
