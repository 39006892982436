import styled, { css } from 'styled-components'
import { rgba } from 'polished'

interface LoaderProps {
    size?: number
    isPage?: boolean
}

export const Loader = styled.div<LoaderProps>`
    --size: ${({ size = 50 }) => size}px;
    --border-size: calc(0.15 * var(--size));
    border-radius: 50%;
    border: var(--border-size) solid ${({ theme: { v2 } }) => rgba(v2.primaryColor, 0.2)};
    border-top: var(--border-size) solid ${({ theme: { v2 } }) => v2.highlight};
    flex: 0 0 auto;
    width: var(--size);
    height: var(--size);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    ${({ isPage }) =>
        isPage &&
        css`
            position: absolute;
            top: 50%;
            left: 50%;
        `}

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
