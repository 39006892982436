import styled from 'styled-components'

interface StyledContainerProps {
    image?: string
}

export const StyledContainer = styled.div<StyledContainerProps>`
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    max-height: calc(100vh);
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    ${({ image }) => `
        background: url('${image}') no-repeat;
        background-position: right center;
    `}

    @media (max-width: ${({ theme }) => theme.sizes.md}) {
        padding: 85px 20px 20px 20px;
        width: 100%;
        justify-content: flex-start;
        background-position: center bottom;
        background-size: clamp(100%, 100%, calc(100vh - 200px));

        h1 {
            font-size: 24px;
        }
    }

    @media (min-width: ${({ theme }) => theme.sizes.md}) and (max-width: ${({ theme }) =>
            theme.sizes.xl}) {
        padding: 50px 20px 20px 20px;
    }

    @media (min-width: ${({ theme }) => theme.sizes.xl}) and (max-width: ${({ theme }) =>
            theme.sizes.xxl}) {
        padding: 30px 75px;
    }

    button {
        padding: 10px 24px;
        height: 46px;
        font-size: 16px;
        max-width: 200px;

        color: ${({ theme }) => theme.main.white};
        background-color: ${({ theme }) => theme.v2.highlight};

        svg {
            padding-top: 2px;
            fill: #fff;
        }
    }

    .progressive-form {
        .ant-select-single .ant-select-selector .ant-select-selection-item {
            line-height: 46px;
        }
    }

    .ant-form {
        height: 100%;
        overflow-y: auto;
        width: 50%;

        .ant-col {
            max-width: calc(100% - 8px);
        }

        @media (max-width: ${({ theme }) => theme.sizes.md}) {
            width: 100%;
        }

        @media (min-width: ${({ theme }) => theme.sizes.md}) and (max-width: ${({ theme }) =>
                theme.sizes.xl}) {
            width: 80%;
        }

        @media (min-width: ${({ theme }) => theme.sizes.xl}) and (max-width: ${({ theme }) =>
                theme.sizes.xxl}) {
            width: 80%;
        }

        .PhoneInput {
            display: flex;
            column-gap: 8px;
        }
    }
`

export const StyledFormContainer = styled.div`
    width: 100%;
    height: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: ${({ theme }) => theme.sizes.md}) {
        height: calc(100% - 60px);
    }

    .ant-form-item-has-error .ant-input {
        background-color: ${({ theme }) => theme.main.whiteOpacity};
    }
`

export const StyledStepContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: ${({ theme }) => theme.sizes.md}) {
        justify-content: flex-start;
    }
`

export const StyledProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    h1 {
        position: absolute;
        top: -6px;
    }

    .ant-progress-line {
        width: 300px;
        display: flex;
        flex-direction: column-reverse;

        .ant-progress-text {
            color: white;
            position: absolute;
            bottom: 20px;

            .anticon {
                display: none;
            }
        }
    }

    .ant-progress-status-success .ant-progress-bg {
        background-color: ${({ theme }) => theme.v2.highlight};
    }

    button:disabled,
    button[disabled] {
        svg {
            fill: ${({ theme }) => theme.main.nearWhite};
        }
    }
    .btn-next-step {
        color: ${({ theme }) => theme.main.dark};
        background-color: ${({ theme }) => theme.main.white};

        svg {
            fill: ${({ theme }) => theme.main.dark};
        }

        &:hover {
            opacity: 0.7;
        }
    }
    .btn-step {
        padding: 0;
        height: 30px;
        background-color: ${({ theme }) => theme.main.white};

        &:first-of-type {
            margin-right: 3px;
        }

        svg {
            padding-top: 0;
            fill: ${({ theme }) => theme.v2.highlight};
        }
    }
`
