import styled from 'styled-components';
import { StyledToolTip } from 'commons/components/Tooltip/styles';

export const StyledSpeakerCard = styled.div`
  width: 146px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSubtitlesContainer = styled.div``;

export const StyledTitle = styled.div`
  font-size: 19px;
  color: white;
  text-align: center;
  margin-top: 15px;
`;

export const StyledSubtitle = styled(StyledTitle)<{ marginTop?: number }>`
  font-size: 16px;
  position: relative;
  ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
  &:hover > ${StyledToolTip} {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s;
  }
`;
