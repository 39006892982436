import React, { useState } from 'react'
import cep from 'cep-promise'

import { Form, Input, Select, Row, Col } from 'antd'

import states from '../constants/states'
import { Wrapper } from '../styles'
import { IInputType } from '../types'
import InputMask from './inputMask'

const AddressInput: React.FC<IInputType> = ({ name, form, config }) => {
    const [state, setState] = useState<string>('')

    const cities = states.find(s => s.code === state)?.cities || []

    const handleStateChange = (value: string) => {
        setState(value)
        form.resetFields([[name, 'city']])
    }

    const handleCep = async (_value: string) => {
        const value = _value.replace(/-/g, '').replace(/_/g, '')
        if (value.length >= 8) {
            try {
                const data = await cep(value)
                form.setFieldsValue({
                    [name]: {
                        state: data.state,
                        city: data.city,
                        zipcode: value
                    }
                })
            } catch (error) {}
        }
    }

    return (
        <Wrapper>
            <Row gutter={[10, 0]}>
                <Col span={8}>
                    <Form.Item
                        name={[name, 'zipcode']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <InputMask
                            mask="99999-999"
                            placeholder="Cep"
                            onChange={e => handleCep(e.target.value)}
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        name={[name, 'street']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Input placeholder="Rua/Avenida" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={[name, 'number']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Input placeholder="Nº" />
                    </Form.Item>
                </Col>

                <Col span={18}>
                    <Form.Item
                        name={[name, 'neighborhood']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Input placeholder="Bairro" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'state']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Select
                            dropdownMatchSelectWidth={100}
                            placeholder="Estado"
                            onChange={handleStateChange}
                        >
                            {states.map(state => (
                                <Select.Option key={state.code} value={state.code}>
                                    {state.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'city']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Select dropdownMatchSelectWidth={100} placeholder="Cidade">
                            {cities.map(city => (
                                <Select.Option key={city} value={city}>
                                    {city}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name={[name, 'complement']}>
                        <Input placeholder="Complemento" />
                    </Form.Item>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default AddressInput
