import { gql } from '@apollo/client'

export const GET_EVENT_EDITIONS = gql`
    query GetEventEditions($eventId: ObjectID) {
        event(id: $eventId) {
            id
            name
            authUrl
            menus {
                main {
                    name
                    url
                    icon
                    visible
                }
                user {
                    name
                    url
                    icon
                    visible
                }
            }
            editions {
                id
                name
                authUrl
            }
        }
    }
`
