/* eslint-disable no-param-reassign */
import styled, { css } from 'styled-components'
import { StatusType } from '../../types'

interface Props {
    $status: StatusType
    $maxHeight: number
}

const StandardVideoButton = css`
    position: absolute;
    background: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;

    cursor: pointer;

    svg {
        margin-right: 10px;
    }

    &:hover {
        opacity: 1;
    }
`

export const PlayerMuted = styled.div`
    ${StandardVideoButton}
    top: 10px;
    right: 10px;

    opacity: 0.5;
    transition: opacity 0.2s;
`

export const LiveStatus = styled.div`
    ${StandardVideoButton}

    background: #ff0000;

    top: 10px;
    left: 15px;

    opacity: 0.7;
    transition: opacity 0.2s;
`

export const PiPButton = styled.div`
    ${StandardVideoButton}

    top: 10px;
    right: 20px;

    opacity: 0;
    transition: opacity 0.2s;
`

export const ClosePiPButton = styled(PiPButton)`
    padding: 5px 5px;

    svg {
        margin-right: 0;
    }
`

export const BackTalkPiPButton = styled(PiPButton)`
    padding: 5px 5px;

    top: 10px;
    right: 60px;

    svg {
        margin-right: 0;
    }
`

export const PlayerContainer = styled.div<Props>`
    --plyr-color-main: ${({ theme }) => theme.main.white};
    --plyr-video-control-color-hover: ${({ theme }) => theme.v2.highlight};

    position: relative;

    /* z-index: -1; */

    .plyr-react {
        overflow: hidden;
        //border-radius: 20px;
    }

    .plyr__video-wrapper {
        max-height: ${({ $maxHeight }) => $maxHeight}px;
        /* max-height: 90%; */
    }

    .plyr-fullscreen .plyr__video-wrapper {
        max-height: 100%;
    }

    .plyr-loading .plyr__poster {
        display: block;
        opacity: 1;
    }

    .plyr--paused .plyr__poster {
        display: block !important;
    }

    .plyr__controls {
        .plyr__controls__item:first-child {
            margin-right: var(--plyr-control-spacing, 10px);
        }
        .plyr__volume {
            margin-right: auto;
        }
    }

    .plyr--youtube {
        .plyr__video-embed {
            iframe {
                height: calc(100% + 340px);
                top: -170px;
            }
        }
    }

    .plyr-youtube-premiere.plyr--youtube {
        .plyr__video-embed {
            iframe {
                height: 100%;
                top: 0;
            }
        }
    }

    .plyr__menu__container {
        [role='menu'] {
            display: flex;
            flex-flow: row wrap;
            width: 200px;
        }

        .plyr__control[role='menuitemradio'] {
            max-width: 50%;
        }
    }

    .plyr__video-embed iframe,
    .plyr__tooltip {
        pointer-events: none;
    }

    ${({ $status }) =>
        $status === 'STARTED' &&
        css`
            .plyr__controls {
                .plyr__progress__container,
                .plyr__menu,
                .plyr__time {
                    display: none;
                }
            }
        `}

    &:hover {
        ${PiPButton} {
            opacity: 1;
        }
    }
`
