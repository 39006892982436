import styled from 'styled-components'

export const StyledToolTip = styled.span<{ bottom?: boolean; maxWidth?: string }>`
    visibility: hidden;
    background-color: rgba(255, 255, 255, 0.95);
    display: block;
    color: black;
    border-radius: 6px;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    padding: 16px;
    font-size: 16px;
    line-height: 120%;
    position: absolute;
    opacity: 0;
    z-index: 1;
    box-shadow: 0px 3px 6px #00000029;

    ${({ bottom }) => bottom && `bottom: 0px;`}
`
