import { HeartOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { ChevronRight } from 'commons/assets/icons/'
import { Text } from 'commons/components/'
import { ROUTES } from 'commons/constants'
import { useMenuValidations } from 'commons/hooks/useMenuValidations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { MenuAction } from '../styles'

interface EventInfoProps {
    handleClose: () => void
}

const Favorites = ({ handleClose }: EventInfoProps) => {
    const { push } = useHistory()
    const { t } = useTranslation('commons')

    const validations = useMenuValidations()

    const handleClick = () => {
        push(ROUTES.favoriteTopics)
        handleClose()
    }

    return (
        <>
            {validations?.showTalkTags && (
                <MenuAction onClick={handleClick}>
                    <Row align="middle" gutter={16}>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <HeartOutlined style={{ fontSize: 20 }} />
                        </Col>
                        <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Text fontSize={16} regular marginBottom={5}>
                                {t('sidebar.favorites')}
                            </Text>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <ChevronRight height="14" />
                        </Col>
                    </Row>
                </MenuAction>
            )}
        </>
    )
}

export default Favorites
