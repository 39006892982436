import React, { lazy, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { CustomRoute } from 'commons/components'
import Main from 'routing/Main'
import { useAuth } from 'commons/providers/AuthProvider'
import { getUrlParameter } from 'commons/utils'
import { message } from 'antd'
import Redirector from './Redirector'

const Routes = () => {
    const { setIdEvent, idEvent: prevIdEvent, token, setToken } = useAuth()

    const isLoggedIn = !!token
    const LoginRouteNew = lazy(() => import('screens/_Login'))
    const magicLinkRoute = lazy(() => import('screens/_MagicLink'))

    useEffect(() => {
        const checkEvent = () => {
            setIdEvent(window.TD_EVENT_ID)
        }

        checkEvent()
    }, [])

    useEffect(() => {
        const data = getUrlParameter('code')
        if (data) {
            const tokenDecoded = atob(data)
            setToken(tokenDecoded)
            window.location.href = '/'
        } else {
            const errorData = getUrlParameter('authError')
            if (errorData) {
                message.error(errorData)
            }
        }
    }, [])

    return (
        <Redirector>
            <Switch>
                <CustomRoute
                    key="1"
                    redirectTo="/home"
                    shouldRedirect={isLoggedIn}
                    component={LoginRouteNew}
                    path="/"
                    exact
                />
                <Route component={LoginRouteNew} path="/auth" />
                <Route component={magicLinkRoute} path="/magiclink/:magicLinkToken" />
                {prevIdEvent && (
                    <CustomRoute
                        key="2"
                        redirectTo="/"
                        shouldRedirect={!isLoggedIn}
                        component={Main}
                        path="/home"
                    />
                )}
            </Switch>
        </Redirector>
    )
}
export default Routes
