import React from 'react'
import { ApolloClient, ApolloProvider, GraphQLRequest, split } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'

import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { getToken, clear } from 'commons/services/auth'
import { GraphQLError } from 'graphql'
import hash from 'hash.js'
import { cache } from '../gql/cache'

const CDNL1_CACHE_OPERATIONS = [
    'GetEvent',
    'GetEventConfig',
    'GetCurrentCustomer',
    'GetTalkCategories',
    'GetTalkTitle',
    'GetCollectionItems',
    'GetEventLanguages',
    'EventViralCampaign',
    'GetCollectionCategories',
    'GetMenuValidations',
    'GetEventDetails',
    'GetBrandPlans',
    'GetEventIntegrations',
    'GetSponsorsByCategories'
]

const CDNL2_CACHE_OPERATIONS = [
    'getLastChatMessagesquery',
    'GetTalkByCategories',
    'GetBanners', // por enquanto precisa do usuario, pois o id do ticket nao esta no JWT, dá erro em PermissionsService.checkBannerTicketRelation
    'EventMenuVerify',
    'UserPermissions',
    'GetSpeakers',
    'NetworkUnreadMessages',
    'GetNotifications',
    'GetNextInfo',
    'GetTalk',
    'GetViewersInTalk',
    'GetEventEditions',
    '',
    'GetRecommendedTalks',
    'GetLiveTalks',
    'GetNextTalks',
    'GetPreviousTalks',
    'GetUserViralReferrals',
    'GetCurrentUser',
    'GetEventRewards',
    'GetViralUrls',
    'GetCurrentUserProfile',
    'GetStages'
]

interface ApolloProviderProps {
    children: React.ReactNode
}

interface ApolloPrevContext {
    headers: {
        Authorization: string
    }
}

type CustomGraphQLError = GraphQLError & { type: string }

// eslint-disable-next-line import/no-mutable-exports
let { REACT_APP_GRAPHQL_API, REACT_APP_GRAPHQL_WS_URI, REACT_APP_CUSTOMER_DOMAIN } = process.env

if (REACT_APP_CUSTOMER_DOMAIN && window.TD_CUSTOMER_DB && window.TD_CUSTOMER_DB !== '<%= db %>') {
    REACT_APP_GRAPHQL_API = `https://${window.TD_CUSTOMER_DB}.${REACT_APP_CUSTOMER_DOMAIN}/graphql`
    REACT_APP_GRAPHQL_WS_URI = `wss://${window.TD_CUSTOMER_DB}.${REACT_APP_CUSTOMER_DOMAIN}/ws`
}

const REACT_APP_GRAPHQL_API_L1 = REACT_APP_GRAPHQL_API?.replace('/graphql', '/cl1/graphql')
const REACT_APP_GRAPHQL_API_L2 = REACT_APP_GRAPHQL_API?.replace('/graphql', '/cl2/graphql')

export { REACT_APP_GRAPHQL_API, REACT_APP_GRAPHQL_WS_URI }

const httpLink = createUploadLink({
    uri: REACT_APP_GRAPHQL_API
})

const httpCachedL1Link = createUploadLink({
    uri: REACT_APP_GRAPHQL_API_L1
})

const httpCachedL2Link = createUploadLink({
    uri: REACT_APP_GRAPHQL_API_L2
})

const wsLink = new WebSocketLink({
    uri: REACT_APP_GRAPHQL_WS_URI || '',
    options: {
        lazy: true,
        reconnect: true,
        connectionParams: () => ({
            authorization: getToken() ? `Bearer ${getToken()}` : ''
        })
    }
})

const authContext = (_: GraphQLRequest, prevContext: ApolloPrevContext) => {
    const token = getToken()
    const { headers } = prevContext
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    const token = getToken()
    if (token && graphQLErrors) {
        graphQLErrors.forEach(error => {
            const { type, message } = error as CustomGraphQLError
            if (type === 'Unauthorized') {
                clear()
                window.location.reload()
            }
            if (/Sua conta foi banida devido/i.test(message)) {
                clear()
                alert(message)
                window.location.reload()
            }
        })
    }
})

async function digestMessage(message: string) {
    return hash.sha256().update(message).digest('hex')
}

const linkChain = createPersistedQueryLink({
    sha256: digestMessage as any,
    useGETForHashedQueries: true
}).concat(httpLink as any)

const linkCachedL1Chain = createPersistedQueryLink({
    sha256: digestMessage as any,
    useGETForHashedQueries: true
}).concat(httpCachedL1Link as any)

const linkCachedL2Chain = createPersistedQueryLink({
    sha256: digestMessage as any,
    useGETForHashedQueries: true
}).concat(httpCachedL2Link as any)

const splitLink = split(
    ({ query, operationName }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    setContext(authContext)
        .concat(errorLink as any)
        .concat(linkChain as any)
)

const level2SplitLink = split(
    operation => {
        return CDNL2_CACHE_OPERATIONS.includes(operation.operationName)
    },
    linkCachedL2Chain,
    setContext(authContext)
        .concat(errorLink as any)
        .concat(splitLink as any)
)

const level1SplitLink = split(
    operation => {
        return CDNL1_CACHE_OPERATIONS.includes(operation.operationName)
    },
    linkCachedL1Chain,
    setContext(authContext)
        .concat(errorLink as any)
        .concat(level2SplitLink as any)
)

export const client = new ApolloClient({
    link: level1SplitLink,
    cache
})

const ApolloProviderAbstraction = (props: ApolloProviderProps) => {
    const { children } = props

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderAbstraction
