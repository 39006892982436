import { gql } from '@apollo/client'

export const UPDATE_ME = gql`
    mutation updateMe(
        $name: String
        $email: String
        $bio: String
        $phone: String
        $company: String
        $jobTitle: String
        $department: String
        $social: UserSocialInputs
        $config: UserConfigurationInput
        $avatar: MediaInput
        $cover: MediaInput
    ) {
        updateMe(
            input: {
                name: $name
                email: $email
                bio: $bio
                phone: $phone
                company: $company
                jobTitle: $jobTitle
                department: $department
                social: $social
                avatar: $avatar
                cover: $cover
                config: $config
            }
        ) {
            name
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($input: ChangePasswordInputs!) {
        changePassword(input: $input)
    }
`
