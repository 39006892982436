import { gql } from '@apollo/client'

export const GET_ITEMS_VALIDATIONS = gql`
    query GetMenuValidations($event: ObjectID!) {
        hasQuestions(filter: { event: $event })
        event(id: $event) {
            viralCampaign {
                id
            }
        }
        talkTags(event: $event)
    }
`
