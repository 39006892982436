import { ptBR, enUS, es, it, de, pt, fr } from 'date-fns/locale';
import { Locale } from 'date-fns';

const locales: Record<string, Locale> = {
  'pt-BR': ptBR,
  'en-US': enUS,
  es,
  it,
  de,
  fr,
  pt,
};

export default function getUserLocale() {
  return ptBR;
  // const navigatorLocale = navigator.language;

  // return locales[navigatorLocale] || enUS;
}
