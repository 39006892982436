import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef } from 'react';
import scrollHorizontally from 'commons/helpers/scrollHorizontally';

import { StyledContainer } from './styles';

const HorizontalScrollingContainer = ({
  children,
  className,
  hiddenScroll = false,
  gap,
}: {
  children: React.ReactNode;
  hiddenScroll?: boolean;
  className?: string;
  gap?: number;
}) => {
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const mousewheelevt = /Firefox/i.test(navigator.userAgent) ? 'DOMMouseScroll' : 'wheel';

  const scrollHorizontallyCallback = useCallback(scrollHorizontally(divRef), [divRef]);

  const handleMouseEnter = useCallback(
    debounce(() => {
      if (divRef.current) {
        divRef.current.addEventListener(mousewheelevt, scrollHorizontallyCallback, false);
      }
    }, 400),
    [divRef, scrollHorizontallyCallback, mousewheelevt],
  );

  const handleMouseLeave = useCallback(() => {
    divRef.current.removeEventListener(mousewheelevt, scrollHorizontallyCallback, false);
  }, [divRef, scrollHorizontallyCallback, mousewheelevt]);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('mouseenter', handleMouseEnter, false);
      div.addEventListener('mouseleave', handleMouseLeave, false);
    }
    return () => {
      div.removeEventListener('mouseenter', handleMouseEnter, false);
      div.removeEventListener('mouseleave', handleMouseLeave, false);
    };
  }, [divRef, handleMouseEnter, handleMouseLeave]);

  return (
    <StyledContainer className={className} hiddenScroll={hiddenScroll} ref={divRef} gap={gap}>
      {children}
    </StyledContainer>
  );
};

export default HorizontalScrollingContainer;
