import styled from 'styled-components'
import { rgba } from 'polished'

export const StyledFooter = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 47px 0;
    border-top: 1px solid ${({ theme: { main } }) => main.nearWhiteOpacity};
    border-bottom: 1px solid ${({ theme: { main } }) => main.nearWhiteOpacity};
`

export const Spacer = styled.div`
    height: 30px;
    width: 100%;
`
