import React, { useEffect, useCallback, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import i18next from 'i18next'
import { Select } from 'antd'

import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'
import { Brazil, Portugal, Eua, Spain } from 'commons/assets/icons'
import { Text } from 'commons/components'
import { UPDATE_ME } from 'commons/gql/mutations/me'
import { GET_EVENT_LANGUAGES } from 'commons/gql/queries/event'

import { SelectorWrapper } from './styles'

interface LanguageProps {
    lang: string
}

const { Option } = Select

const languagesDefaults = [
    {
        value: 'pt-BR',
        label: 'PT/BR',
        Icon: Brazil
    },
    {
        value: 'pt-PT',
        label: 'PT/PT',
        Icon: Portugal
    },
    {
        value: 'en-US',
        label: 'EN/US',
        Icon: Eua
    },
    {
        value: 'es-ES',
        label: 'ES/ES',
        Icon: Spain
    }
]

interface TranslateData {
    event: {
        translation: {
            languages: string[]
        }
    }
}

const LanguageSelector: React.FC<LanguageProps> = props => {
    const { lang } = props

    const [saveMe] = useMutation(UPDATE_ME)
    const [optionsLanguage, setOptionsLanguage] = useState<typeof languagesDefaults>()
    const [defaultLang, setDefaultLang] = useState<string>(lang || 'pt-BR')
    const { idEvent } = useAuth()

    const { data } = useQuery<TranslateData, ById>(GET_EVENT_LANGUAGES, {
        variables: {
            event: idEvent as string
        }
    })

    const changeLang = useCallback(async (lg: string) => {
        i18next.changeLanguage(lg, (err, z): void => {
            if (err) return console.log('something went wrong loading', err)
            z('key')
        })

        setDefaultLang(lg)
        // Esse saveMe não faz sentido durante o evento. 
        // Acho que vale verificar algum setup inicial do evento
        // TODO Acho que vale verificar algum setup inicial do evento
        // await saveMe({
        //     variables: {
        //         config: {
        //             language: lg
        //         }
        //     }
        // })
    }, [])

    useEffect(() => {
        if (lang) {
            changeLang(lang)
        } else {
            changeLang('pt-BR')
        }
    }, [lang])

    useEffect(() => {
        if (data) {
            const { languages } = data?.event?.translation || { languages: [] }

            if (languages.length > 0) {
                if (languages.length > 1) {
                    const _optionsLanguage = languagesDefaults.filter(
                        (item: any) => languages.indexOf(item.value) >= 0
                    )
                    setOptionsLanguage(_optionsLanguage)
                }
                if (lang && languages.indexOf(lang) < 0) {
                    changeLang(languages[0])
                }
            } else {
                changeLang('pt-BR')
            }
        }
    }, [data])

    if (!optionsLanguage) return null

    return (
        <SelectorWrapper className="language-selector-wrapper">
            <Select onChange={changeLang} className="language-selector" defaultValue={defaultLang}>
                {optionsLanguage.map(({ value, label, Icon }) => (
                    <Option value={value} key={value} className="language-selector-item">
                        <Icon height={10} width={10} />
                        <Text fontSize={9} color="nearWhiteOpacityMedium">
                            {label}
                        </Text>
                    </Option>
                ))}
            </Select>
        </SelectorWrapper>
    )
}

export default LanguageSelector
