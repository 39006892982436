import React from 'react'

import { useQuery } from '@apollo/client'
import { useAuth } from 'commons/providers/AuthProvider'
import { GET_EVENT_TALKS } from 'commons/gql/queries/talks'
import { EventQueryData } from '../../types'
import { LoadingState, NotFoundRow } from '../Skeleton'
import { useTalks } from '../useTalks'
import Carousel from '../Carousel'
import { useTalksText } from '../texts'
import { TalksProps, TalksQueryProps } from '../types'
import { TalkTitle } from '../styles'

const TalksCarrousel: React.FC<TalksProps> = ({ filter }) => {
    const { idEvent } = useAuth()
    const { mainTalks } = useTalksText()

    const { data } = useQuery<EventQueryData, TalksQueryProps>(GET_EVENT_TALKS, {
        variables: {
            id: idEvent as string,
            hostType: filter && filter !== 'ALL' ? (filter as string) : undefined
        },
        pollInterval: 90000,
        fetchPolicy: 'no-cache'
    })

    const talks = data ? [...data.event?.nextTalks.edges, ...data.event?.previousTalks.edges] : []
    const { talkInfos } = useTalks(talks)

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {mainTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = data.event?.logo?.url

    if (talkInfos && !talkInfos.length) {
        return null
    }

    return (
        <div className="other-talks">
            <TalkTitle fontSize={20} level={4}>
                {mainTalks}
            </TalkTitle>
            {talkInfos && talkInfos.length ? (
                <Carousel items={talkInfos} defaultThumbnail={defaultThumbnail} />
            ) : (
                <NotFoundRow />
            )}
        </div>
    )
}

export default TalksCarrousel
