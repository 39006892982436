import { gql } from '@apollo/client'

export const TALKS = gql`
    fragment Talks on Event {
        talks(filter: { hiddenInHome: false, startDate: { ne: null } }) {
            edges {
                node {
                    id
                    title
                    description
                    duration
                    isLive
                    viewers
                    startDate
                    endDate
                    liveStartDate
                    liveEndDate
                    currentLiveTime
                    status
                    image {
                        url
                    }
                    imageLive {
                        url
                    }
                    video {
                        url
                        source
                    }
                    categories {
                        edges {
                            node {
                                name
                                id
                            }
                        }
                    }
                    # @todo separar
                    # rating {
                    #     average
                    #     one
                    #     two
                    #     three
                    #     four
                    #     five
                    # }
                    # userRating
                }
            }
        }
    }
`

export const QuizData = gql`
    fragment QuizData on Quiz {
        id
        question
        answers
        talk
        duration
        start
        status
        startAt
        endAt
        stats {
            answer
            quantity
        }
    }
`
