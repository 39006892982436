import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { CategoriesBase } from 'commons/components/types'
import { GET_CATEGORIES_SHORT } from 'commons/gql/queries/categories'
import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'
import { CategoriesTypes, SelectProps } from './types'

import { SelectWrapper } from './styles'

const { Option, OptGroup } = Select

const SelectCategorie: React.FC<SelectProps> = props => {
    const { idEvent } = useAuth()
    const {
        callback,
        type: selectType = 'all',
        optionCategoryValue: optionCategorieValue,
        setOptionCategoryValue: setOptionCategorieValue
    } = props
    const { t } = useTranslation('commons')
    const [optionsCategorie, setOptionsCategorie] = useState<CategoriesTypes[]>([])
    const [optionsPlaylist, setOptionsPlaylist] = useState<CategoriesTypes[]>([])

    const { data } = useQuery<CategoriesBase, ById>(GET_CATEGORIES_SHORT, {
        variables: {
            id: idEvent as string
        }
    })

    useEffect(() => {
        if (data) {
            const { edges } = data.categories
            const _categories: CategoriesTypes[] = []
            const _playlist: CategoriesTypes[] = []

            edges.map(({ node: { id, name, type, talksTotalCount } }) => {
                if (type === 'MAIN' && talksTotalCount > 0) {
                    _categories.push({ value: id, title: name })
                }
                if (type === 'PLAYLIST' && talksTotalCount > 0) {
                    _playlist.push({ value: id, title: name })
                }
            })
            setOptionsCategorie(_categories)
            setOptionsPlaylist(_playlist)
        }
    }, [data])

    const handleSelectCategorie = (e: string) => {
        setOptionCategorieValue(e)
        if (callback) {
            callback(e === 'all' ? null : e)
        }
    }

    if (data?.categories.totalCount === 0) return null

    return optionsCategorie.length > 0 || optionsPlaylist.length > 0 ? (
        <SelectWrapper
            hiddenLabel={selectType !== 'all'}
            value={optionCategorieValue}
            firstOption={optionCategorieValue === 'all'}
        >
            <Select
                className="ant-select-large"
                placeholder={t('select.filterByCategorie')}
                onChange={handleSelectCategorie}
                value={optionCategorieValue}
            >
                <Option value="all" key="category1">
                    {t('select.allCategories')}
                </Option>
                <Option value="recommended" key="recommended1">
                    {t('select.recommended')}
                </Option>
                {selectType === 'all' && (
                    <>
                        {' '}
                        {optionsCategorie.length > 0 && (
                            <OptGroup
                                className="optiongroup-dynamic"
                                label={selectType === 'all' ? t('select.categorie') : false}
                            >
                                {optionsCategorie.map(({ title, value }) => (
                                    <Option value={value} key={value}>
                                        {title}
                                    </Option>
                                ))}
                            </OptGroup>
                        )}
                        {optionsPlaylist.length > 0 && (
                            <OptGroup
                                className="optiongroup-dynamic"
                                label={selectType === 'all' ? t('select.playlist') : false}
                            >
                                {optionsPlaylist.map(({ title, value }) => (
                                    <Option value={value} key={value}>
                                        {title}
                                    </Option>
                                ))}
                            </OptGroup>
                        )}
                    </>
                )}
                {optionsCategorie.length > 0 && selectType === 'categories'
                    ? optionsCategorie.map(({ title, value }) => (
                          <Option value={value} key={value}>
                              {title}
                          </Option>
                      ))
                    : null}
                {optionsPlaylist.length > 0 && selectType === 'playlist'
                    ? optionsPlaylist.map(({ title, value }) => (
                          <Option value={value} key={value}>
                              {title}
                          </Option>
                      ))
                    : null}
            </Select>
        </SelectWrapper>
    ) : null
}

export default SelectCategorie
