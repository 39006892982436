import styled from 'styled-components'

import { ResizableBox } from 'react-resizable'

export const StyledResizableBox = styled(ResizableBox)`
    border-radius: 0.28571429rem;
    overflow: hidden;

    &:hover {
        cursor: default;
    }
`
