import { css } from 'styled-components';

const Factors = css`
  --scale-factor: 0.9;

  @media (min-height: 824px) and (max-height: 900px) {
    --scale-factor: 0.85;
  }

  @media (min-height: 600px) and (max-height: 823px) {
    --scale-factor: 0.75;
  }

  @media (max-height: 599px) {
    --scale-factor: 0.65;
  }
`;

export const ScaleCss = (origin = 'top left') => css`
  ${Factors};
  transform-origin: ${origin};
  transform: scale(var(--scale-factor));
`;

export const ScaleWidthCss = (offset?: string, origin = 'top left') => css`
  ${Factors};
  transform-origin: ${origin};
  width: calc(calc(100% - ${offset || '0px'}) / var(--scale-factor));
  transform: scale(var(--scale-factor));
`;

export const ScaleHeightCss = (offset?: string, origin = 'top left') => css`
  ${Factors};
  transform-origin: ${origin};
  min-height: calc(calc(100% - ${offset || '0px'}) / var(--scale-factor));
  transform: scale(var(--scale-factor));
`;
