import React from 'react'
import truncateString from 'commons/helpers/truncateString'
import { Tooltip } from 'commons/components'
import { parseProps } from '../utils'

import { StyledTitle } from './styles'
import { CustomTitle } from '../types'

/**
 * Componente de tituto baseado no ant
 * @returns
 */

const Title: React.FC<CustomTitle> = ({ children, className, ...props }) => {
    const { truncate } = props
    const size = children?.toString().length
    const content = children?.toString()
    const { eventKey, warnKey, ...parsedProps } = parseProps(props)

    return (
        <StyledTitle className={className} {...parsedProps}>
            {content && truncate && size && size > truncate ? (
                <>
                    {truncateString(content, truncate)}
                    <Tooltip bottom>{children}</Tooltip>
                </>
            ) : (
                <>{children}</>
            )}
        </StyledTitle>
    )
}

export default Title
