import styled, { css } from 'styled-components'
import { rgba } from 'polished'

export const SelectWrapper = styled.div<{
    hiddenLabel: boolean
    value?: string
    firstOption?: boolean
}>`
    .ant-select {
        color: ${({ theme: { main } }) => main.white};

        .ant-select-arrow {
            color: ${({ theme: { main } }) => main.white};
            padding-right: 20px;
        }
        &:not(.ant-select-customize-input) {
            > .ant-select-selector {
                background-color: ${({ theme: { main } }) => main.nearWhiteOpacity};
                border-color: transparent;
                height: 40px;
                border-radius: 20px;
                width: 300px;

                .ant-select-selection-placeholder {
                    line-height: 40px;
                    padding: 0 20px 0 0;
                }

                .ant-select-selection-item {
                    line-height: 40px;
                    opacity: ${({ firstOption }) => (firstOption ? '0.5' : '1')};
                }

                ${({ theme: { v2 }, value }) =>
                    value &&
                    value !== 'all' &&
                    css`
                        border-color: ${v2.highlight};
                    `}

                &:focus {
                    background-color: ${({ theme: { v2 } }) => rgba(v2.highlight, 0.1)};
                    border-color: ${({ theme: { v2 } }) => v2.highlight};
                }
                @media (max-width: ${({ theme }) => theme.sizes.xxxl}) {
                    width: 150px;
                }
            }
        }
        ${({ hiddenLabel }) =>
            hiddenLabel &&
            css`
                .ant-select-item-group {
                    display: none;
                }
            `}
    }
`
