import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'

import { ArrowRight } from 'commons/assets/icons'

import { StyledContainer, ArrowStep, LastItemOverlay } from './styles'

const virtualizedContent = (
    Component: any,
    items: any,
    layout: 'horizontal' | 'vertical' | undefined,
    gutter: number
) => {
    return (args: any) => {
        const { style, ...props } = args || {}

        const styles = { ...style }

        if (layout === 'vertical') {
            styles.top += gutter
            styles.height -= gutter
            styles.transform = `translateY(-${gutter}px)`
        } else {
            styles.left += gutter
            styles.width -= gutter
            styles.transform = `translateX(-${gutter}px)`
        }

        return <Component style={styles} {...props} />
    }
}

interface Props {
    items: any
    Component: any
    height: number
    width: number
    gutter: number
    layout?: 'horizontal' | 'vertical' | undefined
    style?: React.CSSProperties
    borderRadius?: number
    hasNext?: boolean
    getMoreItems?: () => void
}

const Virtualized = (props: Props) => {
    const { items, height, width, style, layout, gutter, Component, getMoreItems, hasNext } = props
    const list = useRef<HTMLDivElement>(null)
    const [showLeft, setShowLeft] = useState(false)
    const [showBtns, setShowBtns] = useState(false)
    const [lastChild, setLastChild] = useState(0)
    // const [containerWidth, setContainerWidth] = useState(0)
    const [toScroll, setToScroll] = useState(0)

    const isItemLoaded = (index: number) => items[index]

    const loadMoreItems = async (startIndex: number, stopIndex: number) => {
        if (hasNext && getMoreItems) {
            getMoreItems()
        }
    }

    const scrollingElement = (direction: string) => {
        const { current } = list
        if (current) {
            const scrolling = (width + gutter) * toScroll
            const _scrollingElement = current.querySelector('.virtualized-list')
            if (_scrollingElement) {
                const scrollPosition = scrolling * (direction === 'back' ? -1 : 1)

                if (direction === 'foward') setShowLeft(true)
                else if (_scrollingElement.scrollLeft <= width + gutter) setShowLeft(false)

                _scrollingElement.scrollBy({ left: scrollPosition, top: 0, behavior: 'smooth' })
            }
        }
    }

    const checkShowBtns = useCallback(() => {
        const itemsSize = items.length * (width + gutter)
        const scrollSize = list.current?.scrollWidth

        if (!scrollSize) return false

        return itemsSize > scrollSize
    }, [gutter, items, width])

    useEffect(() => {
        if (list.current) {
            const currentwidth = list.current?.offsetWidth

            if (currentwidth > 0) {
                setShowBtns(checkShowBtns())
                const element = width + gutter
                const elements = (currentwidth + gutter) / element
                const round = Math.ceil(elements)
                const size = element - (element * round - currentwidth)
                setToScroll(round - 1)

                if (items.length > elements) {
                    setLastChild(size)
                }
            }
        }
    }, [checkShowBtns, setLastChild, list.current])

    return (
        <div style={{ width: '100%' }} ref={list}>
            {getMoreItems && (
                <>
                    {showLeft && showBtns && (
                        <ArrowStep
                            outside
                            className="virtual-btn-step virtual-btn-step--back"
                            onClick={() => scrollingElement('back')}
                        >
                            <ArrowRight />
                        </ArrowStep>
                    )}
                    {showBtns && (
                        <ArrowStep
                            next
                            className="virtual-btn-step virtual-btn-step--next"
                            onClick={() => scrollingElement('foward')}
                        >
                            <ArrowRight />
                        </ArrowStep>
                    )}
                </>
            )}

            <StyledContainer style={style} className="virtualized">
                <AutoSizer
                    className="virtualized-autosize"
                    disableHeight
                    defaultWidth={items.length * (width + gutter)}
                >
                    {({ width: ContainerWidth }) => {
                        return (
                            <InfiniteLoader
                                minimumBatchSize={10}
                                threshold={4}
                                isItemLoaded={isItemLoaded}
                                itemCount={200}
                                loadMoreItems={loadMoreItems}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        ref={ref}
                                        height={height}
                                        itemCount={items.length}
                                        itemSize={width + gutter}
                                        layout={layout || 'horizontal'}
                                        width={ContainerWidth}
                                        onItemsRendered={onItemsRendered}
                                        className="virtualized-list"
                                    >
                                        {virtualizedContent(
                                            Component,
                                            items,
                                            layout || 'horizontal',
                                            gutter
                                        )}
                                    </List>
                                )}
                            </InfiniteLoader>
                        )
                    }}
                </AutoSizer>
                {lastChild > 0 && showBtns && <LastItemOverlay />}
            </StyledContainer>
        </div>
    )
}

export default React.memo(Virtualized)
