import { gql } from '@apollo/client'
import { MediaData } from '../fragments/media'

export const UPLOAD_MEDIA = gql`
    mutation Upload($file: Upload!, $options: UploadOptions) {
        uploadMedia(file: $file, options: $options) {
            ...MediaData
        }
    }

    ${MediaData}
`
