import { gql } from '@apollo/client'

export const GET_PROGRESSIVE_FORM = gql`
    query GetProgressiveForm ($eventId: ObjectID!, $page: String){
        eventFormProgressive(
            event: $eventId
            page: $page
        ) {
            id
            name
            config
            fields {
                name
                label
                type
                options
                config 
            }
        }
    }
`