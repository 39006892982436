import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${({ theme }) => css`
        @media (min-width: theme.sizes.xxxl) {
            justify-content: center;
            max-width: 1300px;
        }
    `}
`
