import { useQuery } from '@apollo/client'
import { useAuth } from 'commons/providers/AuthProvider'

import { USER_PERMISSIONS } from 'routing/gql/queries'

interface QueryUserPermissions {
    userPermissions: string[]
}

export const usePermissions = () => {
    const { idEvent } = useAuth()

    const { data, loading } = useQuery<QueryUserPermissions>(USER_PERMISSIONS, {
        variables: { event: idEvent }
    })

    if (loading) return []

    return data?.userPermissions || []
}
