import React from 'react'

import { Form, Checkbox, Row, Col } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

const CheckboxInput: React.FC<IInputType> = ({
    name,
    label,
    options,
    config
}) => {
    return (
        <Wrapper>
            <Form.Item
                label={label}
                name={name}
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <Checkbox.Group>
                    <Row>
                        {options.map(option => (
                            <Col key={option.value}>
                                <Checkbox value={option.value}>{option.label}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        </Wrapper>
    )
}

export default CheckboxInput
