import React from 'react'
import { StyledExternalLink } from './styles'

const ExternalLink = ({
    href,
    children,
    className,
    onClick
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <StyledExternalLink
        className={className}
        target="_blank"
        rel="noopener noreferrer nofollow external"
        href={href}
        onClick={onClick}
    >
        {children}
    </StyledExternalLink>
)

export default ExternalLink
