import { Input } from 'antd'
import styled from 'styled-components'

const { Search } = Input

export const StyledInputSearch = styled(Search)`
    .ant-input-group-addon {
        svg {
            height: 20px;
            padding-top: 5px;
        }

        &:last-child {
            display: none;
        }

        &:first-child {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            background-color: transparent;
        }
    }

    .ant-input-group .ant-input {
        color: ${({ theme }) => theme.main.white};
        background: transparent;
        border-left: none;
        border-top-right-radius: 25px !important;
        border-bottom-right-radius: 25px !important;

        &:hover,
        &:focus {
            border-color: ${({ theme }) => theme.main.white};
            box-shadow: none;
        }
    }

    .ant-input-wrapper {
        &:hover,
        &:focus {
            .ant-input,
            .ant-input-group-addon {
                border-color: ${({ theme }) => theme.v2.highlight};
                svg {
                    border-color: ${({ theme }) => theme.v2.highlight};
                }
            }
        }
    }
`
