import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import useComponentVisible from 'commons/hooks/useComponentVisible'
import { SmileFilled } from '@ant-design/icons'
import { StyledButton, StyledPickerContainer, StyledContainer } from './styles'

interface Props {
    onSelect?: (emoji: string) => void
}

const EmojiPicker = ({ onSelect }: Props) => {
    const { i18n, t } = useTranslation('emoji')
    const { ref, isComponentVisible: isOpen, toggle } = useComponentVisible(false)

    const i18nEmoji = i18n.store?.data[i18n.language]?.emoji as Record<string, unknown>

    const handleSelectEmoji = useCallback(
        ({ native }) => {
            if (onSelect) {
                onSelect(native)
            }
        },
        [onSelect]
    )

    return (
        <StyledContainer ref={ref} className="emoji-picker-wrapper">
            <StyledButton activated={isOpen} onClick={toggle}>
                <SmileFilled size={20} style={{ color: '#FEBE10' }} />
            </StyledButton>
            {isOpen && (
                <StyledPickerContainer>
                    <div style={{ position: 'relative' }}>
                        <Picker
                            showPreview={false}
                            title={t('skintextShort')}
                            i18n={i18nEmoji}
                            onSelect={handleSelectEmoji}
                        />
                    </div>
                </StyledPickerContainer>
            )}
        </StyledContainer>
    )
}

export default EmojiPicker
