import { gql } from '@apollo/client'

export const ON_NOTIFICATION = gql`
    subscription OnNotification($event: ObjectID!) {
        onNotification(event: $event) {
            id
            title
            message
            icon {
                url
            }
            button
            link
            isRead
            isVisualized
        }
    }
`

export const ON_NOTIFICATION_DELETE = gql`
    subscription OnNotificationDelete($event: ObjectID!) {
        onNotificationDelete(event: $event)
    }
`

export const ON_VIDEO_NOTIFICATION = gql`
    subscription OnSponsorMessage($id: ObjectID!, $type: String!) {
        onSponsorMessage(type: $type, channel: { type: TALK, id: $id }) {
            id
            message
            type
            link
            user {
                name
                avatar {
                    url
                }
            }
        }
    }
`
