import { MutableRefObject } from 'react';

interface Wheel extends Event {
  wheelDelta?: number;
  detail?: number;
}

const scrollHorizontally = (divRef: MutableRefObject<HTMLDivElement>) => {
  let counter = 0;
  return (scrollEvent: Wheel) => {
    const { current } = divRef;
    const { wheelDelta = 0, detail = 0 } = scrollEvent;

    const isFirefox = /Firefox/i.test(navigator.userAgent);

    const wheelValue = detail || wheelDelta;

    const multiplier = isFirefox ? -1 : 1;

    const delta = Math.max(-1, Math.min(1, wheelValue)) * multiplier;

    const prevValue = current.scrollLeft;
    current.scrollLeft -= delta * 40;
    if (prevValue === current.scrollLeft) {
      counter += 1;
      if (counter < 5) {
        scrollEvent.preventDefault();
      }
    } else {
      scrollEvent.preventDefault();
      counter = 0;
    }
  };
};

export default scrollHorizontally;
