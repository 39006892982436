import styled, { css } from 'styled-components'
import { Title } from 'commons/components/Typography'

export const StyledListTitle = styled.h1`
    margin-top: 40px;
    font-size: 20px;
`

const TopContainerCss = css`
    display: flex;
    padding: 8px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);

    div {
        width: 55px;
        height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
    }
`

const BottomContainerCss = css`
    display: flex;
    padding: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 60px;
    background: rgba(255, 255, 255, 0.1);
`

export const TopContainer = styled.div`
    ${TopContainerCss};
`

export const BottomContainer = styled.div`
    ${BottomContainerCss};
`

export const TalkTitle = styled(Title)`
    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.md}) {
            font-size: 18px !important;
        }
    `}
`