import styled, { css } from 'styled-components'

export const CustomPiP = styled.div`
    background-color: #242d36;
    z-index: 100;

    position: fixed;
    bottom: 10px;
    right: 10px;

    font-size: 14px;
    font-weight: 200;
    line-height: 1.5;
    color: rgb(248, 248, 249);
    box-sizing: content-box;
    box-shadow: 1px 3px 3px 0 rgb(0 0 0 / 20%), 1px 3px 15px 2px rgb(0 0 0 / 20%);
    border-radius: 0.28571429rem;

    &:hover {
        cursor: grab;

        .pip-back-talk,
        .pip-close {
            opacity: 1;
        }
    }

    &:active {
        cursor: grabbing;
        outline: -webkit-focus-ring-color auto 1px;
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
        outline-width: 1px;
    }

    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.md}) {
            width: 100%;
            height: 70px;
            bottom: 0;

            .react-resizable {
                width: 100% !important;

                .plyr-react {
                    width: 100%;

                    .plyr__controls {
                        opacity: 1;
                        transform: translateY(0);
                        bottom: 14px;

                        .plyr__volume {
                            margin-right: 0;
                        }
                    }

                    .plyr__video-wrapper {
                        max-width: 100%;
                        max-height: 90px;
                        margin-left: 0;
                    }
                }

                .pip-close {
                    opacity: 1;
                    top: 4px;
                    right: 10px;
                }
                .pip-back-talk {
                    opacity: 1;
                    top: 4px;
                    right: 40px;
                }

                .disable-audio {
                    display: none;
                }
            }
        }
    `}
`
