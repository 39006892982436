import { useQuery } from '@apollo/client'
import { Row } from 'antd'
import { GET_EDITIONS_TALKS, GET_STAGES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from 'commons/assets/icons'
import { ButtonV2, Title } from 'commons/components'
import { TalksData } from '../../types'
import Carousel from '../Carousel'
import { LoadingState, NotFoundRow } from '../Skeleton'
import { TalkTitle } from '../styles'
import TalksWrapper from '../TalksWrapper'
import { useTalksText } from '../texts'
import { TalksProps } from '../types'

const EditionCarrousel: React.FC<TalksProps> = ({ editionConfig }) => {
    const [editionsItems, setEditionsItems] = useState<any>([])

    const { t } = useTranslation('home')
    const { data } = useQuery<TalksData>(GET_EDITIONS_TALKS, {
        variables: {
            event: editionConfig?.id as string,
            first: 4
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            const stageInfo = talks?.map(
                ({ node: { id, title, image, video, status, description } }) => {
                    const _video = video ? video.url : ''
                    const url = editionConfig?.authUrl

                    return {
                        id,
                        thumbnail: image?.url || '',
                        video: _video,
                        title: description,
                        url,
                        isExternal: true,
                        status
                    }
                }
            )

            if (talks) setEditionsItems([...stageInfo])
        }
    }, [data])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {editionConfig?.name}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    if (!editionsItems || !editionsItems.length) {
        return null
    }

    return (
        <TalksWrapper className="edition-talks">
            {editionConfig?.editionIndex === 0 && (
                <Row align="middle" style={{ marginBottom: '20px' }}>
                    <Title level={4} fontSize={20} marginBottom={0}>
                        {editionConfig?.editionsSize > 0
                            ? t('editions.carrosel')
                            : t('edition.carrosel')}
                    </Title>
                </Row>
            )}
            <Row align="middle" style={{ marginBottom: '20px' }}>
                <TalkTitle fontSize={20} level={4} marginBottom={0} marginRight={10}>
                    {editionConfig?.name}
                </TalkTitle>
                <ButtonV2
                    type="ghost"
                    shape="round"
                    icon={<ArrowRight />}
                    href={editionConfig?.authUrl}
                >
                    {t('btn.all-contents')}
                </ButtonV2>
            </Row>
            {editionsItems && editionsItems.length > 0 ? (
                <Carousel items={editionsItems} type="edition" />
            ) : (
                <NotFoundRow />
            )}
        </TalksWrapper>
    )
}

export default EditionCarrousel
