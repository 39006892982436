import { useEffect, useState } from 'react'

import { GET_NOTIFICATIONS } from 'commons/gql/queries/notifications'
import { useAuth } from 'commons/providers/AuthProvider'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { Notification } from 'commons/gql/models/notification'
import { READ_NOTIFICATIONS, VIEW_NOTIFICATIONS } from 'commons/gql/mutations/notifications'
import { ON_NOTIFICATION, ON_NOTIFICATION_DELETE } from 'commons/gql/subscriptions/notifications'

import { Collection } from 'commons/gql/types/collection'

interface QueryData {
    notifications: Collection<Notification>
}

interface OnNotificationData {
    onNotification: Notification
}
interface OnNotificationDeleteData {
    onNotificationDelete: string
}

const useNotifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([])
    const [unreadCount, setUnreadCount] = useState(0)
    const { idEvent } = useAuth()

    const { data } = useQuery<QueryData>(GET_NOTIFICATIONS, {
        variables: {
            event: idEvent
        }
    })

    const { data: newNotification } = useSubscription<OnNotificationData>(ON_NOTIFICATION, {
        variables: {
            event: idEvent
        }
    })

    const { data: deleteNotification } = useSubscription<OnNotificationDeleteData>(
        ON_NOTIFICATION_DELETE,
        {
            variables: {
                event: idEvent
            }
        }
    )

    const [readNotifications] = useMutation(READ_NOTIFICATIONS)

    const [viewNotifications] = useMutation(VIEW_NOTIFICATIONS, {
        variables: {
            event: idEvent
        }
    })

    useEffect(() => {
        if (!data) return
        setNotifications(data.notifications.edges.map(o => o.node))
    }, [data])

    useEffect(() => {
        if (!newNotification) return
        const item = newNotification.onNotification
        setNotifications(state => [item, ...state])
    }, [newNotification])

    useEffect(() => {
        if (!deleteNotification) return
        setNotifications(state =>
            state.filter(o => o.id !== deleteNotification.onNotificationDelete)
        )
    }, [deleteNotification])

    useEffect(() => {
        if (!notifications) return
        const count = notifications.filter(o => !o.isVisualized).length
        setUnreadCount(count)
    }, [notifications])

    useEffect(() => {
        if (!deleteNotification) return
        setNotifications(state =>
            state.filter(o => o.id !== deleteNotification.onNotificationDelete)
        )
    }, [deleteNotification])

    useEffect(() => {
        if (!notifications) return
        const count = notifications.filter(o => !o.isVisualized).length
        setUnreadCount(count)
    }, [notifications])

    const handleView = () => {
        viewNotifications()
        setNotifications(state => state.map(o => ({ ...o, isVisualized: true })))
    }

    const handleRead = (id?: string) => {
        readNotifications({
            variables: { event: idEvent, id }
        })
        setNotifications(state => state.map(o => (o.id === id ? { ...o, isRead: true } : o)))
    }

    return {
        unreadCount,
        notifications,
        setNotifications,
        readNotifications: handleRead,
        viewNotifications: handleView,
        newNotification: newNotification?.onNotification
    }
}

export default useNotifications
