import styled from 'styled-components'
import RoundedContainer from 'commons/components/RoundedContainer'

export const StyledAvatar = styled(RoundedContainer)<{ src: string; size: number }>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size / 2}px;
    flex-shrink: 0;
    background-image: url('${({ src }) => src}');
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0;
    margin: 0 auto;

    &.avatar-click {
        cursor: pointer;
    }
`
