import { useQuery } from '@apollo/client'
import { ROUTES_WITH_PARAMS } from 'commons/constants'
import { Me } from 'commons/gql/models/me'
import { MenuData } from 'commons/gql/models/menuData'
import { GET_CURRENT_USER } from 'commons/gql/queries/me'
import { useAuth } from 'commons/providers/AuthProvider'
import GlobalMainTheme from 'commons/styles/globalMain'
import React, { Suspense, useEffect, useState } from 'react'
import { Switch, useHistory } from 'react-router-dom'

import CustomRoute from './CustomRoute'
import { GET_EVENT } from './gql/queries'
import MobileHeader from './MobileHeader'
import { NotificationsAlert } from './Notifications'
import ReferBtn from './ReferAndWinButton'
import Sidebar from './Sidebar'
import { MainWrapper, StyledContainer, StyledPageContainer } from './styles'

const hideOn = ['streaming', 'referral', 'networking', 'profile', 'live']

interface QueryMe {
    me: Me
}

const Main = () => {
    const { idEvent } = useAuth()

    const [isHome, setIsHome] = useState(false)
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const [menus, setMenus] = useState<MenuData>()

    const {
        location: { pathname }
    } = useHistory()

    const { data: userData, loading } = useQuery<QueryMe>(GET_CURRENT_USER, {
        fetchPolicy: 'no-cache'
    })

    const { data: eventsData } = useQuery(GET_EVENT, {
        variables: {
            id: idEvent as string
        }
    })

    useEffect(() => {
        if (eventsData && eventsData.event) {
            setMenus(eventsData.event.menus)
        }
    }, [eventsData])

    useEffect(() => {
        if (pathname) {
            if (pathname === '/home') {
                setIsHome(true)
            } else {
                setIsHome(false)
            }
            // eslint-disable-next-line array-callback-return
            if (hideOn.indexOf(pathname.split('/')[2]) > -1) {
                setIsVisible(false)
            } else setIsVisible(true)
        }
    }, [pathname])

    useEffect(() => {
        if (!loading && userData?.me) {
            const { id, email, name } = userData.me

            const oldUserId = JSON.parse(localStorage.getItem('__user_id') as string)
            window.TDA.identify(id, {
                email,
                name
            }).then(() => {
                if (oldUserId !== id) {
                    window.TDA.track('login')
                }
            })
        }
    }, [loading])

    return (
        <>
            <GlobalMainTheme />
            <NotificationsAlert />
            <StyledContainer>
                <MobileHeader isHome={isHome} menuData={menus} />
                <MainWrapper>
                    <Sidebar menuData={menus} />
                    <StyledPageContainer className="page-container">
                        <Suspense fallback="">
                            <Switch>
                                {ROUTES_WITH_PARAMS.map(({ path, exact, component, ...rest }) => (
                                    <CustomRoute
                                        key={path}
                                        path={path}
                                        exact={exact}
                                        component={component}
                                        {...rest}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </StyledPageContainer>
                </MainWrapper>
                {isVisible && <ReferBtn />}
            </StyledContainer>
        </>
    )
}

export default Main
