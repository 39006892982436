import { gql } from '@apollo/client'

export const GET_BRANDS = gql`
    query GetBrands($id: ObjectID, $featured: Filter) {
        brands(filter: { event: $id, featured: $featured }) {
            edges {
                node {
                    id
                    name
                    description
                    logo {
                        url
                    }
                    talkStand {
                        url
                        type
                    }
                }
            }
        }
    }
`
