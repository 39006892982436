import jwt from 'jsonwebtoken'
import { getToken, clear } from './services/auth'

enum USER_PERMISSIONS {
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
    SPEAKER = 'speaker',
    SPONSOR = 'sponsor',
    MODERATOR = 'moderator',
    EDITOR = 'editor',
    LEAD = 'lead'
}

interface Media {
    _id: string
    url: string
}

interface JwtPayload {
    userId: string
    origin: string
    event: string
    user: {
        email: string
        name: string
        config: {
            language: string
        }
        role: USER_PERMISSIONS
        avatar?: Media
        cover?: Media
    }
}

export const stringFormat = (text: string, ...args: string[]) => {
    return text.replace(/{(\d+)}/g, (match, number) => {
        return typeof args[number] !== 'undefined' ? args[number] : match
    })
}

export const getBgColor = (
    color: string,
    gradientTpl = 'linear-gradient(226.22deg, {1} -4.83%, {0} 105.47%)'
) => {
    if (!color) return ''
    if (!color.includes(';')) return color
    const colors = color.split(';')
    return stringFormat(gradientTpl, ...colors)
}

export const getTextColor = (color: string) => {
    if (!color) return ''
    const colors = color.split(';')
    return colors[0]
}

export const tuple = <T extends string[]>(...args: T) => args

export const hexToRGB = (hex: string, alpha?: number) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    if (hex.indexOf('#') < 0) return hex

    if (alpha && alpha >= 0) {
        return `rgba(${r},${g},${b},${alpha})`
    }
    return `rgb(${r}, ${g}, ${b})`
}

export const getUrlParameter = (name: string): string => {
    if (typeof window === 'undefined') return ''
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`)
    const results = regex.exec(window.location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const IS_DEV =
    process.env.NODE_ENV === 'development' ||
    process.env.VERCEL_ENV === 'development' ||
    process.env.VERCEL_ENV === 'preview'

export const IS_PREVIEW = process.env.VERCEL_ENV === 'preview'

export function getDecodedUser() {
    const token = getToken()

    if (!token) {
        clear()
        return null
    }

    const decodedToken = jwt.decode(token) as JwtPayload

    if (!decodedToken) {
        clear()
        return null
    }

    return decodedToken
}

export function isVerifiedUser() {
    const decodedToken = getDecodedUser()

    if (!decodedToken) {
        return false
    }

    const validRoles = ['speaker', 'sponsor', 'moderator', 'editor', 'admin', 'superadmin']
    return validRoles.includes(decodedToken.user.role)
}

export const sendTrackerUserReport = (callback: (interval: number) => void) => {
    const interval = Math.round(Math.random() * 60000) + 150000 // entre 2.5 a 3.5 minutos

    return setInterval(() => {
        callback(interval)
    }, interval)
}
