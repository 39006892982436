import React, { useState } from 'react'
import { Row, Col } from 'antd'

import { Text } from 'commons/components/'

import { ReactComponent as ChevronSvg } from 'commons/assets/icons/chevron-right.svg'
import { NotificationFill } from 'commons/assets/icons/'

import useNotifications from 'commons/hooks/useNotification'
import { MenuAction } from '../styles'
import { NotificationWrapper, NotificationCounter } from '../../Notifications/styles'
import NotificationDrawer from './Drawer'

const Notifications = () => {
    const { unreadCount, notifications, readNotifications, viewNotifications } = useNotifications()
    const [open, setOpen] = useState<boolean>(false)

    const handleClick = () => {
        setOpen(true)
        viewNotifications()
    }

    return (
        <>
            <MenuAction onClick={handleClick}>
                <Row align="middle" gutter={16}>
                    <Col span={4} style={{ textAlign: 'center' }}>
                        <NotificationWrapper style={{ display: 'inline' }}>
                            {unreadCount ? (
                                <NotificationCounter style={{ top: -10 }}>
                                    {unreadCount > 9 ? '9+' : unreadCount}
                                </NotificationCounter>
                            ) : null}
                            <NotificationFill style={{ width: '16px' }} />
                        </NotificationWrapper>
                    </Col>
                    <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text fontSize={16} regular marginBottom={5}>
                            Notificações
                        </Text>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <ChevronSvg height="14" />
                    </Col>
                </Row>
            </MenuAction>
            <NotificationDrawer
                notifications={notifications}
                readNotifications={readNotifications}
                open={open}
                handleClose={() => setOpen(false)}
            />
        </>
    )
}

export default Notifications
