import React from 'react'
import ReactDOM from 'react-dom'
// import TagManager from 'react-gtm-module';
import App from './App'
import './i18n'

// const tagManagerArgs = {
//   gtmId: 'GTM-K6272DN',
// };

// TagManager.initialize(tagManagerArgs);
console.log('Version', process.env.REACT_APP_COMMIT_HASH)
ReactDOM.render(<App />, document.getElementById('root'))
