import React from 'react'
import { Rule } from 'rc-field-form/lib/interface'

import { Form, Input } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

const TextInput: React.FC<IInputType> = ({ name, label, type, config }) => {
    const rules: Rule[] = [
        {
            required: config.required,
            message: `Campo obrigatório`
        }
    ]

    if (type === 'email') {
        rules.push({
            type: 'email',
            message: `E-mail inválido`
        })
    }

    return (
        <Wrapper>
            <Form.Item name={name} rules={rules}>
                <Input type={type} placeholder={label} />
            </Form.Item>
        </Wrapper>
    )
}

export default TextInput
