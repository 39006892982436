import { gql } from '@apollo/client'

export const GET_NETWORK_LIST = gql`
    query GetNetworkList {
        networks {
            edges {
                node {
                    id
                    user {
                        id
                        name
                        avatar {
                            url
                        }
                    }
                    unread
                    lastMessage {
                        message
                    }
                }
            }
        }
    }
`

export const GET_NETWORK_USERS = gql`
    query GetNetworkUsers($event: ObjectID!, $first: Int, $search: String) {
        networkUsers(filter: { event: $event }, first: $first, search: $search) {
            edges {
                node {
                    user {
                        id
                        name
                        company
                        jobTitle
                        avatar {
                            url
                        }
                        cover {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const GET_USER_MESSAGES = gql`
    query networkMessages($user: ObjectID!) {
        networkMessages(filter: { user: $user }) {
            edges {
                node {
                    message
                    isMe
                    owner {
                        id
                        name
                        avatar {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const GET_UNREAD_MESSAGES = gql`
    query NetworkUnreadMessages {
        networkUnreadMessages
    }
`
