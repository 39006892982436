import styled from 'styled-components'

export const Wrapper = styled.div`
    display: block;

    input {
        width: 100%;
        padding: 0 16px;
        border: 0;
        height: 46px;
        border-radius: 10px;
        background-color: ${({ theme }) => theme.main.whiteOpacity};
        color: ${({ theme }) => theme.main.white};
        cursor: text;
    }

    .ant-select-multiple {
        .ant-select-selection-item {
            color: ${({ theme }) => theme.main.white};
            background-color: ${({ theme }) => theme.v2.highlight};
            border-color: ${({ theme }) => theme.v2.highlight};

            svg {
                fill: ${({ theme }) => theme.main.white};
            }
        }
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        height: 46px;
        border-radius: 10px;
        background-color: ${({ theme }) => theme.main.whiteOpacity};
        color: ${({ theme }) => theme.main.white};

        .ant-select-selection-placeholder {
            line-height: 46px;
        }
    }

    label {
        color: ${({ theme }) => theme.main.white};
    }
`
