import React from 'react'
import { Wrapper, StyledToggleButton, ButtonText } from './styles'

type Props = {
    isSelected: boolean
    text: string
    padding?: string
    onClick?: () => void
}

const ButtonToggle = ({ isSelected, text, padding, onClick }: Props) => {
    return (
        <Wrapper>
            <StyledToggleButton isSelected={isSelected} padding={padding} onClick={onClick}>
                <ButtonText>{text}</ButtonText>
            </StyledToggleButton>
        </Wrapper>
    )
}

export default ButtonToggle
