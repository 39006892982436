/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Avatar as AntAvatar, Paragraph } from 'commons/components'
import { Link as GenericLink } from '@styled-icons/boxicons-regular'
import { SpeakerDetail } from 'commons/gql/models/speaker'
import { ReactComponent as ChatIcon } from 'commons/assets/icons/fill/chat-white.svg'

import { Avatar, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import {
    FacebookOutline,
    InstagramOutline,
    LinkedinOutline,
    TwitterOutline,
    ChatFill
} from 'commons/assets/icons'
import { Content, Wrapper, SpeakerInfos, Bio, Company, WrapperIcon, LinkedinButton } from './styles'

const Icons = {
    Linkedin: <LinkedinOutline />,
    Instagram: <InstagramOutline />,
    Facebook: <FacebookOutline />,
    Twitter: <TwitterOutline />
}

export type SpeakerCardProps = {
    speaker: SpeakerDetail
    layoutId?: string
    isHorizontal?: boolean
    withLinkedin?: boolean
    showSocial?: boolean
    styles?: React.CSSProperties
    fullContent?: boolean | 'withBio'
    onClick?: () => void
    clickAble?: boolean
    boxType?: 'box-main' | 'box-highlight'
}

export function SpeakerCard({
    speaker,
    isHorizontal = false,
    fullContent = false,
    withLinkedin = false,
    showSocial = true,
    boxType,
    onClick,
    clickAble,
    styles
}: SpeakerCardProps) {
    const {
        name,
        bio,
        office,
        image,
        company: { name: companyName, image: companyImage },
        links,
        user
    } = speaker

    const history = useHistory()

    const companyLogo = companyImage?.url ?? ''
    return (
        <Wrapper
            className={`speaker-card ${boxType}`}
            style={styles}
            onClick={onClick}
            clickAble={clickAble}
            isHorizontal={isHorizontal && !fullContent}
            fullContent={fullContent}
            showSocial={showSocial}
            boxType={boxType}
        >
            <Content>
                <AntAvatar
                    style={{ margin: isHorizontal && !fullContent ? '0' : `0 auto` }}
                    src={image.url}
                    size={fullContent === 'withBio' ? 240 : fullContent ? 80 : 60}
                />
                <SpeakerInfos>
                    <Paragraph
                        fontSize={isHorizontal ? 14 : 18}
                        marginBottom={5}
                        rows={2}
                        align={!isHorizontal || fullContent ? 'center' : 'left'}
                        bold
                    >
                        {name}
                    </Paragraph>
                    <Paragraph
                        fontSize={isHorizontal ? 12 : 14}
                        marginBottom={5}
                        rows={2}
                        align={isHorizontal ? 'left' : 'center'}
                        className="speaker-office"
                    >
                        {office}
                    </Paragraph>
                    {companyName || companyLogo ? (
                        <Company align="center">
                            {companyLogo ? (
                                <img
                                    className="speaker-company-logo logo"
                                    src={companyLogo}
                                    alt={companyName || 'logo'}
                                />
                            ) : (
                                companyName
                            )}
                        </Company>
                    ) : (
                        <div style={{ height: isHorizontal ? 0 : '35px' }} />
                    )}
                </SpeakerInfos>
            </Content>

            {fullContent && showSocial && (
                <Avatar.Group maxCount={2} style={{ minHeight: '40px' }}>
                    {user && (
                        <WrapperIcon
                            onClick={() => history.push(`/home/networking/${user.id}`)}
                            key="chat"
                        >
                            <Avatar
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                icon={<ChatFill />}
                                size={35}
                            />
                        </WrapperIcon>
                    )}
                    {links.length > 0 ? (
                        links.map(link => {
                            if (link.type !== 'Outros' && link.url) {
                                const _link = link.url.startsWith('http')
                                    ? link.url
                                    : `https://${link.url}`
                                return (
                                    <WrapperIcon
                                        key={link.url}
                                        href={_link}
                                        target="_blank"
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <Avatar
                                            className={`speaker-avatar-social ${link.type.toLowerCase()}`}
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none'
                                            }}
                                            icon={Icons[link.type as keyof typeof Icons]}
                                            size={35}
                                        />
                                    </WrapperIcon>
                                )
                            }
                            return null
                        })
                    ) : (
                        <div style={{ width: '40px', height: '40px' }} />
                    )}
                </Avatar.Group>
            )}
            {fullContent === 'withBio' && (
                <Bio>
                    <Paragraph fontSize={18}>{bio}</Paragraph>
                </Bio>
            )}

            {/* {withLinkedin && !fullContent && (
                <LinkedinButton target="__blank" href="https://www.linkedin.com/in/ramonmello/">
                    Linkedin
                </LinkedinButton>
            )} */}
        </Wrapper>
    )
}
