import React from 'react'
import { Form, Select } from 'antd'

import { Wrapper } from '../styles'
import { IInputType } from '../types'

const SelectInput: React.FC<IInputType> = ({ name, label, options, config }) => {
    return (
        <Wrapper>
            <Form.Item
                name={name}
                rules={[
                    {
                        required: config.required,
                        message: 'Campo obrigatório'
                    }
                ]}
            >
                <Select placeholder={label} mode={config?.multiple && 'multiple'}>
                    {options.map(option => (
                        <Select.Option key={option.value} value={option.value || option.label}>
                            {option.label || option.value}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Wrapper>
    )
}

export default SelectInput
