import styled from 'styled-components'
import { ScaleWidthCss } from 'commons/css/ScaleOnHeight'

interface Props {
    marginTop?: boolean | undefined
}

export const StyledPageContainer = styled.div<Props>`
    margin-top: ${({ marginTop }) => (marginTop ? 0 : '80px')};
    padding: 40px;
    @media (max-width: 1279px) {
        padding: 40px 30px;
    }
    @media (max-width: 767px) {
        padding: 0px 40px 30px;
        margin-top: 0px;
    }
    ${ScaleWidthCss()};
`
