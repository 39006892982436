import { css } from 'styled-components'

import { isArray } from 'lodash'
import { StyledTypographyTypes } from './types'

const checkProp = (prop: any) => {
    return Number.isInteger(prop) || isArray(prop)
}

const getValue = (prop: any, index: number) => {
    if (Number.isInteger(prop)) {
        return prop
    }

    return prop[index]
}

export const stylesBase = css<StyledTypographyTypes>`
    text-align: ${({ $align }) => ($align ? `${$align}` : 'left')};
    text-transform: ${({ $transform }) => ($transform ? `${$transform}` : 'none')};
    ${({ $bold }) => $bold && `font-weight: 700;`};
    ${({ $regular }) => $regular && `font-weight: 500;`};
    ${({ $light }) => $light && `font-weight: 300;`};
    ${({ theme: { main }, $color }) => $color && `color: ${main[$color]}`};

    ${({ $marginTop }) =>
        checkProp($marginTop) && `margin-top: ${getValue($marginTop, 0)}px !important;`}
    ${({ $marginLeft }) =>
        checkProp($marginLeft) && `margin-left: ${getValue($marginLeft, 0)}px !important;`}
	    ${({ $marginRight }) =>
        checkProp($marginRight) && `margin-right:  ${getValue($marginRight, 0)}px !important;`}
        ${({ $marginBottom }) =>
        checkProp($marginBottom) && `margin-bottom: ${getValue($marginBottom, 0)}px !important;`}

    @media (max-width: ${({ theme }) => theme.sizes.md}) {
        font-size: ${({ $fontSize }) => ($fontSize ? `${getValue($fontSize, 1)}px` : '10px')};
        text-align: ${({ $align }) =>
            $align ? `${isArray($align) ? $align[1] : $align}` : 'left'};
        ${({ $marginTop }) =>
            checkProp($marginTop) && `margin-top: ${getValue($marginTop, 1)}px !important;`}
        ${({ $marginLeft }) =>
            checkProp($marginLeft) && `margin-left: ${getValue($marginLeft, 1)}px !important;`}
	    ${({ $marginRight }) =>
            checkProp($marginRight) && `margin-right: ${getValue($marginRight, 1)}px !important;`}
        ${({ $marginBottom }) =>
            checkProp($marginBottom) && `margin-bottom:${getValue($marginBottom, 1)}px !important;`}
    }
`

export const styleFont = css<StyledTypographyTypes>`
    font-size: ${({ $fontSize }) => ($fontSize ? `${getValue($fontSize, 0)}px` : '10px')};
`
export const styleFontDynamic = css<StyledTypographyTypes>`
    font-size: ${({ $fontSize }) => $fontSize && `${getValue($fontSize, 0)}px`};
`
