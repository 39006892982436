import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        me {
            name
            email
            avatar {
                url
                _id
            }
            config {
                language
            }
        }
    }
`
