/* eslint-disable react/destructuring-assignment */
import { useQuery } from '@apollo/client'
import { GET_PREVIOUS_INFO, GET_TALK_BY_CATEGORIES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { useEffect, useState } from 'react'
import { EventQueryData } from '../../../types'
import Carousel from '../../Carousel'
import { LoadingState, NotFoundRow } from '../../Skeleton'
import { TrailProps } from '../../types'

const Trail = ({ idTalk }: TrailProps) => {
    const { idEvent } = useAuth()
    const [checkId, setCheckId] = useState(true)
    const [talksItems, setTalksItems] = useState<any>([])

    useEffect(() => {
        if (idTalk.length > 1) {
            setCheckId(false)
        } else {
            setCheckId(true)
        }
    }, [idTalk])

    const { data: dataInfo } = useQuery<EventQueryData, any>(GET_PREVIOUS_INFO, {
        variables: {
            id: idEvent as string
        },
        fetchPolicy: 'no-cache'
    })

    const { data } = useQuery<any>(GET_TALK_BY_CATEGORIES, {
        variables: {
            event: idEvent as string,
            category: idTalk.length > 1 && idTalk
        },
        skip: checkId
    })

    useEffect(() => {
        if (data) {
            const talks = data ? [...data?.talks.edges] : []

            const talkInfos = talks?.map(({ node: { id, title, image } }) => {
                return {
                    id,
                    thumbnail: image ? image.url : '',
                    title
                }
            })

            if (talks) setTalksItems([...talkInfos])
        }
    }, [data])

    if (!data) {
        return (
            <>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = dataInfo?.event?.logo?.url
    const { pageInfo } = data.talks

    if (!talksItems || !talksItems.length) {
        return null
    }

    return (
        <>
            {talksItems && talksItems.length > 0 ? (
                <Carousel
                    items={talksItems}
                    defaultThumbnail={defaultThumbnail}
                    hasNext={pageInfo?.hasNextPage}
                />
            ) : (
                <NotFoundRow />
            )}
        </>
    )
}

export default Trail
