import styled, { css } from 'styled-components';
import RoundedContainer from 'commons/components/RoundedContainer';

export const StyledButton = styled(RoundedContainer)<{
  bgColor?: string;
  textColor?: string;
  width?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};

  border: 0;
  background-color: ${({ bgColor }) => bgColor};
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;

  transition: 0.5s;

  &:hover {
    filter: brightness(90%);
    color: #fff;
  }

  &:active {
    filter: brightness(78%);
    transition: 0s;
  }

  > img {
    margin-right: 16px;
  }
`;
