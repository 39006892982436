import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import getQueryParams from 'commons/helpers/getQueryParams';
import { useAuth } from 'commons/providers/AuthProvider';
import { localSetIdEvent } from 'commons/services/auth';

const useRedirectAuthorization = () => {
  const [redirecting, setRedirecting] = useState(false);
  const { setToken, setIdEvent } = useAuth();
  const location = useLocation();
  const params = getQueryParams(location.search);

  const rawHash = params.get('hash');

  if (!redirecting && rawHash) {
    const hash = decodeURIComponent(rawHash);
    const parts = atob(hash).split('.');
    const event = parts.shift();
    const token = parts.join('.');
    setRedirecting(true);
    setToken(token);
    localSetIdEvent(event || '');
    setIdEvent(event || '');
    // localStorage.setItem('event', event || '');
    window.location.href = '/';
  }

  return redirecting;
};

const Redirector = ({ children }: { children: React.ReactNode }) => {
  const isRedirecting = useRedirectAuthorization();
  return <>{isRedirecting || children}</>;
};

export default Redirector;
