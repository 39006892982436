import styled, { css } from 'styled-components'
import { transparentize } from 'polished'

interface StyledSelectProps {
    color?: string
    error?: string
    rounded?: boolean
}

const ErrorCss = css`
    border-bottom: 1px solid ${({ theme }) => theme.main.danger};
    ${({ theme }) => `color: ${transparentize(0.5, theme.main.danger)}`};
`

export const StyledSelect = styled.select<StyledSelectProps>`
    color: ${({ color }) => color};
    font-size: 16px;
    width: 100%;
    height: 45px;
    border: none;
    background: none;
    padding: 0 10px;
    z-index: 1;
    opacity: 0.7;
    &::placeholder {
        color: white;
        opacity: 0.7;
    }

    option {
        background-color: black;
        font-size: 16px;
    }
`

const RoundedCss = css`
    border-radius: 25px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
    background-color: ${({
        theme: {
            main: { primary }
        }
    }) => primary};
`

export const StyledSelectWrapper = styled.div<StyledSelectProps>`
    width: 100%;
    position: relative;
    border-bottom: 1px solid ${({ color }) => color};
    ${({ error }) => error && ErrorCss}
    ${({ rounded }) => rounded && RoundedCss};
    display: flex;
    align-items: center;
    &:hover {
        cursor: text;
    }
    label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        transition: 0.2s;
        margin-top: -15px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

export const StyledLabel = styled.label<StyledSelectProps>`
    color: ${({ color, error, theme }) => (error ? transparentize(0.5, theme.main.danger) : color)};
    position: absolute;
    &:hover {
        cursor: text;
    }
`

export const StyledIcon = styled.img`
    width: 15px;
    left: 5px;
    bottom: 10px;
`

export const ErrorWrapper = styled.div`
    margin: 10px 0;
    font-size: 12px;
`
