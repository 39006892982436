import { gql } from '@apollo/client'

export const GET_BANNERS = gql`
    query GetBanners($event: ObjectID, $page: Filter, $talks: Filter) {
        banners(filter: { event: $event, pages: $page, active: true, talks: $talks }) {
            totalCount
            edges {
                node {
                    id
                    link
                    active
                    pages
                    desktop {
                        path
                        url
                    }
                    mobile {
                        path
                        url
                    }
                }
            }
        }
    }
`
