import styled, { css } from 'styled-components'

type ToggleButtonProps = {
    isSelected: boolean
    padding?: string
}

export const Wrapper = styled.div`
    display: contents;
`

export const StyledToggleButton = styled.div<ToggleButtonProps>`
    margin: 8px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    height: 38px;
    border-radius: ${({ theme: { v2 } }) => v2.button.borderRadius}px;
    transition: all ${({ theme: { v2 } }) => v2.button.hover.transition}s ease-in-out;
    transform: scale(1);

    &:hover {
        transform: scale(1.05);
    }

    ${({ isSelected, theme }) =>
        !isSelected &&
        css`
            background: transparent;
            color: ${theme.v2.getTextColor('button.bgColor')};
            border-color: ${theme.v2.getTextColor('button.bgColor')};
            border-width: 1px;
            border-style: solid;
            > span {
                color: ${theme.v2.getTextColor('button.bgColor')};
            }

            svg * {
                fill: ${theme.v2.getTextColor('button.bgColor')};
            }
        `}

    ${({ isSelected, theme }) =>
        isSelected &&
        css`
            background: ${theme.v2.getBgColor('button.bgColor')};
            color: ${theme.v2.button.textColor};
            border: none;

            > span {
                color: ${theme.v2.button.textColor};
            }

            svg * {
                fill: ${theme.v2.button.textColor};
            }
        `}


    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
`

export const ButtonText = styled.span``
