import React, { useState } from 'react'

import { Form, Select, Row, Col } from 'antd'

import states from '../constants/states'
import { Wrapper } from '../styles'
import { IInputType } from '../types'

const StateCityInput: React.FC<IInputType> = ({ name, form, config }) => {
    const [state, setState] = useState<string>('')

    const cities = states.find(s => s.code === state)?.cities || []

    const handleStateChange = (value: string) => {
        setState(value)
        form.resetFields([[name, 'city']])
    }

    return (
        <Wrapper>
            <Row gutter={[10, 0]}>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'state']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Select
                            dropdownMatchSelectWidth={100}
                            placeholder="Estado"
                            onChange={handleStateChange}
                        >
                            {states.map(state => (
                                <Select.Option key={state.code} value={state.code}>
                                    {state.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={[name, 'city']}
                        rules={[
                            {
                                required: config.required,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Select dropdownMatchSelectWidth={100} placeholder="Cidade">
                            {cities.map(city => (
                                <Select.Option key={city} value={city}>
                                    {city}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default StateCityInput
