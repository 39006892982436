import { format, parseISO } from 'date-fns'

import { Edge } from 'commons/gql/types/collection'
import Talk from 'commons/gql/models/talk'

export const useTalks = (data?: Edge<Talk>[] | undefined) => {
    const talkInfos = data?.map(
        ({
            node: {
                id,
                title,
                startDate,
                categories: { edges: categoryEdges },
                image
            }
        }) => {
            const parsedDate = parseISO(startDate)
            const hour = startDate ? format(parsedDate, 'dd/MM HH:mm') : ''
            const trail = categoryEdges.length ? categoryEdges[0].node.name : ''

            return {
                id,
                thumbnail: image ? image.url : '',
                hour,
                subtitle: trail,
                title
            }
        }
    )

    return {
        talkInfos
    }
}
