import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Layout, Button, Row, Drawer as AntDrawer } from 'antd'

import { ButtonProps } from 'antd/lib/button'

const { Header } = Layout

interface BtnProps extends ButtonProps {
    isSelected?: boolean
}

export const Drawer = styled(AntDrawer)`
    &.mobile-menu {
        .ant-drawer-content-wrapper {
            width: 100% !important;
            .ant-drawer-content {
                background-color: ${({ theme }) => theme.v2.bgColor};
                .ant-drawer-body {
                    padding: 0;
                }
            }
        }
        .anticon-close {
            display: none;
        }
        .mobile-menu-body {
            padding: 0 20px 20px 20px;
            > .ant-row {
                margin-left: 0 !important;
            }
        }
        .footer {
            position: absolute;
            bottom: 20px;
            width: 100%;
        }
        @media (max-height: 780px) {
            .footer {
                position: relative;
                margin-top: 25px;
                width: 100%;
            }
        }

        .referral-btn {
            bottom: 60px;
        }

        .referral-btn {
            position: initial;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
`

export const MenuAction = styled.div`
    width: 100%;
    height: 60px;
    padding: 20px 25px;

    display: flex;
    align-items: center;
    > .ant-row,
    > div {
        flex: 1;
    }
`

export const TopContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;

    ${MenuAction} {
        border-bottom: 1px solid ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};
    }
`

export const ProfileButton = styled.button`
    padding: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
    text-align: left;
    border-radius: 10px;
    background-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
`

export const MenuOption = styled(Button)<BtnProps>`
    &.ant-btn {
        background-color: ${({ theme }) => rgba(theme.main.nearWhite, 0.1)};
        color: ${({ theme }) => theme.main.nearWhite};
        border: 0;
        border-radius: 10px;
        font-size: 12px;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: left;
        align-items: center;

        span {
            color: ${({ theme }) => theme.main.nearWhite};
            opacity: 0.5;
        }

        svg {
            fill: ${({ theme }) => theme.main.nearWhite};
            margin-right: 10px;
        }
    }

    ${({ isSelected }) =>
        isSelected &&
        css`
            &.ant-btn {
                > span {
                    color: ${({ theme }) => theme.main.white};
                    opacity: 1;
                }

                > svg {
                    fill: ${({ theme }) => theme.main.white};
                    path {
                        opacity: 1;
                    }
                }
                color: ${({ theme }) => theme.main.white};
                background-color: transparent;
                background: ${({ theme: { v2 } }) => v2.getBgColor('highlight')};
            }
        `}

    &:hover {
        &.ant-btn {
            > span {
                color: ${({ theme }) => theme.main.white};
                opacity: 1;
            }

            > svg {
                fill: ${({ theme }) => theme.main.white};
                path {
                    opacity: 1;
                }
            }
            color: ${({ theme }) => theme.main.white};
            background-color: transparent;
            background: ${({ theme: { v2 } }) => v2.getBgColor('highlight')};
        }
    }
`

export const LogoutContainer = styled.div`
    margin-top: auto;
`

export const IconWrapper = styled.div`
    position: absolute;
    display: flex;
    left: 35px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
    svg {
        height: 16px;
    }
`
export const MenuHeader = styled(Header)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    background: ${({ theme }) => theme.main.whiteOpacity};
`
