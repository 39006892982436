const TOKEN_KEY = '@react-web-example:token'
const ID_EVENT = '@react-web-example:id-event'
const ID_USER = '@react-web-example:id-user'

const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

const localSetToken = (token: string | null) => localStorage.setItem(TOKEN_KEY, token || '')

const getToken = () => localStorage.getItem(TOKEN_KEY)

const localSetIdEvent = (id: string | null) => localStorage.setItem(ID_EVENT, id || '')

const getIdEvent = () => localStorage.getItem(ID_EVENT)

const localSetIdUser = (id: string | null) => localStorage.setItem(ID_USER, id || '')

const getIdUser = () => localStorage.getItem(ID_USER)

const clear = () => localStorage.clear()

export {
    TOKEN_KEY,
    isAuthenticated,
    getToken,
    clear,
    localSetToken,
    getIdEvent,
    localSetIdEvent,
    localSetIdUser,
    getIdUser
}
