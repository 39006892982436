import { Col, Menu as AntMenu, Row } from 'antd'
import { MenuData } from 'commons/gql/models/menuData'
import { useMenu } from 'commons/hooks/useMenu'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { MenuOption } from './styles'

const { Item } = AntMenu

interface MenuProps {
    isMobile?: boolean
    onClick?: () => void
    menuData?: MenuData
}

const Menu: FC<MenuProps> = ({ isMobile, onClick, menuData }) => {
    const { location } = useHistory()

    const menu = useMenu()
    const { t } = useTranslation('commons')

    const checkPath = (path: string) => {
        const _path = path.replace('home/', '')
        const pathname = location.pathname.replace('home/', '')

        return pathname.startsWith(_path)
    }

    if (!menu) return null

    const filteredMenus = [menu.routes.home].concat(
        ...(
            menuData?.main
                ?.filter(({ visible }) => visible)
                ?.map(({ name }) => menu.routes[name]) || []
        )?.filter(({ show }) => show)
    )

    if (isMobile) {
        return (
            <Row gutter={[12, 12]} style={{ width: '100%' }}>
                {filteredMenus.map(item => {
                    const { name, path, Selected, UnSelect } = item
                    const selected = checkPath(path)

                    return (
                        <Col span={12} key={item.path}>
                            <Link to={path} onClick={() => onClick?.()}>
                                <MenuOption
                                    isSelected={selected}
                                    key={path}
                                    icon={selected ? Selected : UnSelect}
                                >
                                    {t(name)}
                                </MenuOption>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    return (
        <AntMenu selectable={false}>
            {filteredMenus.map(item => {
                const { name, path, Selected, UnSelect, Extra } = item

                const selected = checkPath(path)

                return (
                    <Item
                        key={path}
                        icon={selected ? Selected : UnSelect}
                        className={selected ? 'menu-item-side-selected' : ''}
                    >
                        <Link onClick={() => onClick?.()} to={path} id={`btn-sidebar-${name}`}>
                            {t(name)}
                            {Extra}
                        </Link>
                    </Item>
                )
            })}
        </AntMenu>
    )
}

export default Menu
