import { Menu } from 'antd'
import { ChevronRight } from 'commons/assets/icons'
import { Paragraph, Title } from 'commons/components'
import { UserData } from 'commons/gql/models/userData'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledContainer, WrapperIcon } from './styles'

const { SubMenu } = Menu

interface EventEditionMenuProps {
    eventId: string
    events: {
        id: string
        name: string
        authUrl: string
    }[]
    user: UserData
    icon: React.FunctionComponent
}

const EventEditionMenu = ({ events, eventId, icon: Icon }: EventEditionMenuProps) => {
    const { t } = useTranslation('commons')

    if (!events) return null

    const getEditions = () =>
        events.map(({ name, id, authUrl }) => {
            const isActive = id === eventId

            const goToEvent = () => {
                window.open(authUrl)
            }

            const menuStyle = isActive
                ? {
                      borderRadius: '10px',
                      margin: '6px',
                      paddingLeft: '10px'
                  }
                : undefined

            return (
                <Menu.Item
                    key={id}
                    onClick={goToEvent}
                    className={`event-edition-menu${isActive ? ' ant-menu-item-selected' : ''}`}
                    style={{ ...menuStyle }}
                >
                    <Paragraph fontSize={14} ellipsis marginBottom={0}>
                        {name}
                    </Paragraph>
                    <ChevronRight className="arrow-right-icon" />
                </Menu.Item>
            )
        })
    const MenuIcon = () => {
        return (
            <WrapperIcon>
                <Icon />
                <ChevronRight className="chevron" />
            </WrapperIcon>
        )
    }

    return (
        <StyledContainer>
            <Menu mode="vertical" className="avatar-menu" selectable={false}>
                <SubMenu key="sub1" icon={<MenuIcon />}>
                    <Title
                        level={5}
                        style={{
                            textAlign: 'center',
                            paddingTop: '12px'
                        }}
                    >
                        {t('sidebar.other-editions')}
                    </Title>
                    <Menu.ItemGroup className="usermenu-item-group">{getEditions()}</Menu.ItemGroup>
                </SubMenu>
            </Menu>
        </StyledContainer>
    )
}

export default EventEditionMenu
