import React, { useEffect, useState } from 'react'

import { format, parseISO } from 'date-fns'
import { useQuery } from '@apollo/client'
import { GET_NEXT_TALKS, GET_NEXT_INFO } from 'commons/gql/queries/talks'
import { ById } from 'commons/gql/types/variables'
import { useAuth } from 'commons/providers/AuthProvider'

import { EventQueryData } from '../../types'
import { LoadingState, NotFoundRow } from '../Skeleton'
import Carousel from '../Carousel'
import { useTalksText } from '../texts'
import { TalksProps, TalksQueryProps } from '../types'
import TalksWrapper from '../TalksWrapper'
import { TalkTitle } from '../styles'

const TalksCarrousel: React.FC<TalksProps> = ({ filter }) => {
    const { idEvent } = useAuth()
    const { nextTalks } = useTalksText()
    const [talksItems, setTalksItems] = useState<any>([])

    const { data: dataInfo } = useQuery<EventQueryData, ById>(GET_NEXT_INFO, {
        variables: {
            id: idEvent as string
        },
        pollInterval: 90000,
        fetchPolicy: 'network-only'
        // fetchPolicy: 'cache-and-network'
    })

    const { data, fetchMore } = useQuery<EventQueryData, TalksQueryProps>(GET_NEXT_TALKS, {
        variables: {
            id: idEvent as string,
            hostType: filter && filter !== 'ALL' ? (filter as string) : undefined
        },
        pollInterval: 90000,
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if (data && dataInfo) {
            const talks = data ? [...data?.talks.edges] : []

            const liveCount = dataInfo?.event?.liveTalks?.totalCount || 0

            // if (liveCount < 2) {
            //     talks = talks.slice(2 - liveCount)
            // }

            const talkInfos = talks?.map(
                ({
                    node: {
                        id,
                        title,
                        startDate,
                        categories: { edges: categoryEdges },
                        image
                    }
                }) => {
                    const parsedDate = parseISO(startDate)
                    const hour = startDate ? format(parsedDate, 'dd/MM HH:mm') : ''
                    const trail = categoryEdges.length ? categoryEdges[0].node.name : ''

                    return {
                        id,
                        thumbnail: image ? image.url : '',
                        hour,
                        subtitle: trail,
                        title
                    }
                }
            )

            setTalksItems([...talkInfos])
        }
    }, [data, dataInfo])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {nextTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const defaultThumbnail = dataInfo?.event?.logo?.url
    const { pageInfo } = data.talks

    const getMoreItems = async () => {
        if (pageInfo?.hasNextPage) {
            await fetchMore({
                variables: {
                    after: pageInfo.endCursor
                }
            })
        }
    }

    if (!talksItems || !talksItems.length) {
        return null
    }

    return (
        <TalksWrapper className="next-talks">
            <TalkTitle fontSize={20} level={4}>
                {nextTalks}
            </TalkTitle>
            {talksItems && talksItems.length > 0 ? (
                <Carousel
                    items={talksItems}
                    defaultThumbnail={defaultThumbnail}
                    hasNext={pageInfo?.hasNextPage}
                    getMoreItems={getMoreItems}
                />
            ) : (
                <NotFoundRow />
            )}
        </TalksWrapper>
    )
}

export default TalksCarrousel
