import { gql } from '@apollo/client'

export const GET_EVENT_SCHEDULE = gql`
    query GetEventSchedule($event: ObjectID) {
        event(id: $event) {
            scheduleImage {
                url
                filename
            }
            scheduleImageMobile {
                url
                filename
            }
        }
    }
`

export const GET_EVENT_LOGO = gql`
    query GetEventLogo($event: ObjectID) {
        event(id: $event) {
            logo {
                url
            }
        }
    }
`

export const GET_EVENT_LANGUAGES = gql`
    query GetEventLanguages($event: ObjectID) {
        event(id: $event) {
            translation {
                languages
            }
        }
    }
`

export const GET_EVENT_HOME_BACKGROUND = gql`
    query GetEventHomeBackground($id: ObjectID) {
        event(id: $id) {
            backgroundImageHome {
                url
            }
        }
    }
`

export const GET_EVENT_DETAILS = gql`
    query GetEventDetails($id: ObjectID) {
        event(id: $id) {
            id
            name
            logo {
                url
            }
            domain {
                name
            }
            backgroundImageDesktop {
                url
            }
            showSocialAuth
        }
    }
`

export const GET_EVENT_HOSTTYPE = gql`
    query GetEventHostType($event: ObjectID) {
        event(id: $event) {
            hostType
        }
    }
`

export const GET_EVENT_ADDRESS = gql`
    query GetEventHostAddress($event: ObjectID) {
        event(id: $event) {
            address
            hostType
        }
    }
`

export const GET_EVENT_INTEGRATIONS = gql`
    query GetEventIntegrations($event: ObjectID) {
        event(id: $event) {
            integrations {
                active
                name
                config
            }
        }
    }
`

export const GET_EVENT_HASHTAGS_FEED = gql`
    query GetEventHashtagsFeed($event: ObjectID) {
        eventHashtagsFeed(event: $event, mode: top_media, max: 10) {
            hashtag
            posts {
                media_url
                permalink
                username
            }
        }
    }
`

export const GET_EVENT_HELPING = gql`
    query GetEventHelping($event: ObjectID) {
        event(id: $event) {
            helpMail
        }
    }
`
