import React from 'react'
import Avatar from 'commons/components/Avatar'
import { Paragraph } from 'commons/components'
import useViewport from 'commons/hooks/useViewport'
import { StyledSpeakerCard, StyledSubtitlesContainer } from './styles'

export interface SpeakerCardProps {
    avatar: string
    name: string
    office: string
    company: string
    orientation?: 'horizontal' | 'vertical'
    light?: boolean
    style?: React.CSSProperties
    className?: string
    onClick?: () => void
}

const SpeakerCard: React.FC<SpeakerCardProps> = ({
    avatar,
    name,
    office,
    company,
    style,
    className,
    orientation = 'vertical',
    light,
    onClick
}) => {
    const { vw } = useViewport()
    const isHorizontal = orientation === 'horizontal'

    const truncateText = vw < 768 ? (isHorizontal ? 100 : 10) : isHorizontal ? 100 : 20

    return (
        <StyledSpeakerCard
            style={style}
            className={`${className} box-main box-hover`}
            $light={light}
            $orientation={orientation}
            onClick={onClick}
        >
            <Avatar
                style={{ margin: isHorizontal ? '0 14px 0 0' : `0 auto 14px` }}
                src={avatar}
                size={60}
            />
            <StyledSubtitlesContainer>
                <Paragraph
                    align={isHorizontal ? 'left' : 'center'}
                    bold
                    fontSize={14}
                    marginBottom={4}
                    truncate={truncateText}
                >
                    {name}
                </Paragraph>
                {office && (
                    <Paragraph
                        align={isHorizontal ? 'left' : 'center'}
                        marginBottom={4}
                        truncate={truncateText}
                        fontSize={10}
                    >
                        {office}
                    </Paragraph>
                )}
                {company && (
                    <Paragraph
                        align={isHorizontal ? 'left' : 'center'}
                        truncate={truncateText}
                        marginBottom={0}
                        bold
                        fontSize={10}
                    >
                        {company}
                    </Paragraph>
                )}
            </StyledSubtitlesContainer>
        </StyledSpeakerCard>
    )
}

export default SpeakerCard
