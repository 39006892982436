import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledIsLive } from './styles'

interface Props {
    initial?: boolean
    behind?: boolean
}

const IsLive = ({ initial = false, behind = false }: Props) => {
    const { t } = useTranslation('home')

    return (
        <StyledIsLive initial={initial} behind={behind}>
            {t('live-talk.live')}
        </StyledIsLive>
    )
}

export default IsLive
