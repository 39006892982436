import { darken } from 'polished'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { SpeakersProps } from './types'

export const StyledListTitle = styled.h1`
    margin-top: 40px;
    font-size: 20px;
`

export const NotFoundContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    margin-top: 16px;
`

export const NotFound = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > p {
        margin-top: 16px;
        font-size: 25px;
    }
    @media screen and (max-width: 767px) {
        width: 80%;
    }
`
export const Wrapper = styled.div<SpeakersProps>`
    display: contents;

    .virtualized-list {
        min-height: ${({ orientation }) => (orientation === 'vertical' ? '200px' : '80px')};
    }

    ${({ theme }) => css`
        @media (max-width: ${theme.sizes.md}) {
            h3 {
                margin-left: 8px;
            }
        }
    `}
`

export const TitleContainer = styled.div`
    display: flex;
    margin: 15px 0;

    h4 {
        margin-right: 10px;
        padding-top: 5px;
    }
`

export const StyledLink = styled(Link)<{ color?: string }>`
    margin-left: 14px;
    height: 33px;
    border-radius: 20px;
    padding: 5px 12px;
    color: ${({ theme: { main } }) => main.white};
    background-color: ${({ theme: { main } }) => main.mediumGrey};

    &:hover {
        color: ${({ theme: { main } }) => main.white};
        ${({ theme }) => `background-color: ${darken(0.05, theme.main.mediumGrey)}`};
    }

    .arrow-right-icon {
        max-height: 10px;
        max-width: 9px;
        margin-left: 9px;
    }
`