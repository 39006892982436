import styled, { css } from 'styled-components'
import ButtonV2 from '../ButtonV2'

export const ModalContent = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`

export const ButtonContent = styled.div`
    display: flex;
    column-gap: 16px;
`

export const ButtonCancel = styled(ButtonV2)`
    /* background: ${({ theme }) => theme.main.highlightOpacity} !important; */
`
