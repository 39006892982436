import { InputProps } from 'antd'
import React, { ChangeEvent } from 'react'
import { StyledInputSearch } from './styles'

interface SearchInputProps {
    addonBeforeIcon?: React.ReactNode
    placeholder: string
    onSearch?: (searchTerm: string) => void
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    className?: string
    style?: React.CSSProperties
    type?: InputProps['type']
}

const SearchInput = ({
    addonBeforeIcon,
    placeholder,
    onSearch,
    onChange,
    className,
    style,
    type
}: SearchInputProps) => (
    <StyledInputSearch
        className={className}
        addonBefore={addonBeforeIcon}
        size="large"
        placeholder={placeholder}
        onChange={onChange}
        onSearch={onSearch}
        style={style}
        type={type}
    />
)

export default SearchInput
