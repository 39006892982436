import { useQuery } from '@apollo/client'
import { GET_TALK_CATEGORIES } from 'commons/gql/queries/talks'
import { useAuth } from 'commons/providers/AuthProvider'
import React, { useEffect, useState } from 'react'

import { LoadingState } from '../Skeleton'
import { TalkTitle } from '../styles'
import TalksWrapper from '../TalksWrapper'
import { useTalksText } from '../texts'
import { ICategories } from '../types'
import { Select, TitleContainer } from './styles'
import Trail from './Trail'

const { Option } = Select

const TrailsCarrousel = () => {
    const { idEvent } = useAuth()
    const { trailsTalks } = useTalksText()
    const [selectedTrail, setSelectedTrail] = useState('')
    const [trails, setTrails] = useState<ICategories[]>()

    const { data } = useQuery<any>(GET_TALK_CATEGORIES, {
        variables: {
            event: idEvent as string,
            type: 'MAIN'
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        if (data) {
            const _trails = data?.categories?.edges.filter(
                (item: any) => item.node.talksTotalCount > 0
            )
            setTrails(_trails)
            setSelectedTrail(_trails?.[0]?.node?.id)
        }
    }, [data])

    if (!data) {
        return (
            <>
                <TalkTitle fontSize={20} level={4}>
                    {trailsTalks}
                </TalkTitle>
                <LoadingState />
            </>
        )
    }

    const filterCategories = trails

    const categories =
        filterCategories &&
        filterCategories.reduce((arr: any, currentValue: any) => {
            return arr.concat(currentValue?.node)
        }, [])

    const handleSelectTrail = (e: any) => {
        setSelectedTrail(e)
    }

    if (!trails || trails.length <= 0) {
        return null
    }

    return (
        <TalksWrapper className="tail-talks">
            <TitleContainer>
                <TalkTitle fontSize={20} level={4}>
                    {trailsTalks}
                </TalkTitle>
                {categories && (
                    <Select onChange={handleSelectTrail} value={selectedTrail}>
                        {categories.map((category: ICategories) => (
                            <Option key={category.id} value={category.id}>
                                {category.name}
                            </Option>
                        ))}
                    </Select>
                )}
            </TitleContainer>
            {selectedTrail && <Trail idTalk={selectedTrail} />}
        </TalksWrapper>
    )
}

export default TrailsCarrousel
