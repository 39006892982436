import { Row } from 'antd'
import { ReactComponent as Arrow } from 'commons/assets/icons/chevron-left.svg'
import { ReactComponent as Leave } from 'commons/assets/icons/logout.svg'
import { LogoutModal, Menu, Text } from 'commons/components'
import { MenuData } from 'commons/gql/models/menuData'
import React, { Fragment, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Faq from './Faq'
import Favorites from './Favorites'
import GuestList from './GuestList'
import Notification from './Notification'
import { Drawer, IconWrapper, MenuHeader, TopContainer } from './styles'
import User from './User'

interface SideMenuProps {
    open: boolean
    handleCloseMenu: () => void
    logo?: string
    menuData?: MenuData
}

const MobileMenu = (props: SideMenuProps) => {
    const { open, handleCloseMenu, logo, menuData } = props
    const { t } = useTranslation('commons')
    const [showLogoutModal, setShowLogoutModal] = useState(false)

    const logout = () => {
        setShowLogoutModal(true)
    }

    const createMenuItemsDynamically = () => {
        const menus: Record<string, ReactNode> = {
            guestlist: <GuestList handleClose={handleCloseMenu} />,
            favorites: <Favorites handleClose={handleCloseMenu} />,
            faq: <Faq handleClose={handleCloseMenu} />
        }

        return menuData?.user
            ?.filter(({ visible }) => visible)
            ?.map(({ name }) => <Fragment key={name}>{menus[name]}</Fragment>)
    }

    return (
        <Drawer visible={open} title="" placement="left" className="mobile-menu">
            <LogoutModal
                isVisible={showLogoutModal}
                setIsVisible={setShowLogoutModal}
                logo={logo}
            />
            <MenuHeader>
                <IconWrapper onClick={handleCloseMenu}>
                    <Arrow />
                </IconWrapper>

                <Text fontSize={18} bold>
                    Menu
                </Text>
            </MenuHeader>

            <TopContainer>
                <User handleClose={handleCloseMenu} />
                <Notification />
                {createMenuItemsDynamically()}
            </TopContainer>

            <div className="mobile-menu-body">
                <Menu onClick={handleCloseMenu} isMobile menuData={menuData} />
            </div>

            <Row className="footer" align="middle" justify="center">
                <Leave onClick={logout} style={{ marginRight: '5px' }} />
                <Text fontSize={18}>{t('sidebar.leave')}</Text>
            </Row>
        </Drawer>
    )
}

export default MobileMenu
