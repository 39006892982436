import styled from 'styled-components';

export const StyledChevronContainer = styled.div`
  opacity: 0;
  transition: 0.4s;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: center center;
`;

export const StyledTopBarAvatarUserContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 10px;
  transition: 0.4s;
  position: relative;
  &:hover ${StyledChevronContainer} {
    margin-right: 24px;
    margin-top: 8px;
    opacity: 1;
    transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform-origin: center center;
  }

  .ant-menu {
    background: transparent;
  }
`;

export const StyledText = styled.div`
  color: white;
  font-size: 19px;
  text-align: right;
`;
